import * as React from 'react';
import { useState } from 'react';
import { benefitDisclaimer, SubPageProps } from '../../../datastructure/benefitData';
import BenefitSubPage, { DisclaimerDescrProps } from '../benefitSubPage/benefitSubPageComponent';
import Fake3DViewer from '../fake3DViewer/fake3DViewer';
import Hotspots, { HotspotProps } from '../hotspots/hotspots';
import style from './benefitMain.module.scss';
import "./benefitCursor.css";
import { useEffect } from 'react';

export interface BenefitMainProps {
    onChange: (open: boolean) => void;
    openDisclaimer: (open: boolean) => void;
    windowIsLoaded: boolean;
    videoIsPlaying: boolean;
    hotspotsData: HotspotProps[];
    benefitSubPages: SubPageProps[]
    language: "DE" | "EN" | "FR" | "IT" | "CH" | "fr-BE" | "nl-BE";
}


function BenefitMain({ onChange, openDisclaimer, windowIsLoaded, videoIsPlaying, hotspotsData, benefitSubPages, language }: BenefitMainProps) {
    console.log("🚀 ~ file: benefitMain.tsx ~ line 20 ~ benefitSubPages", benefitSubPages)
    const [openSubPage, setOpenSubPage] = useState<boolean>(false);
    const [selectedSubPage, setSelectedSubPage] = useState<SubPageProps>();
    const [canvasIsDragged, setCanvasIsDragged] = useState<boolean>(false);

    useEffect(() => {
        const filteredPage = benefitSubPages.filter((subPage) => subPage.id === selectedSubPage?.id)[0];


        setSelectedSubPage(filteredPage);
    }, [language])

    const getDisclaimer = (filteredPage: SubPageProps, disclaimer: DisclaimerDescrProps[]) => {
        if (filteredPage && filteredPage.disclaimer) {
            const filteredDisclaimer = filteredPage.disclaimer.map((item) => {
                return disclaimer.filter((discl) => discl.number === item)[0];
            })

            return filteredDisclaimer;
        } else {
            return null;
        }
    }

    const handleSubPages = (page: string) => {
        const filteredPage = benefitSubPages.filter((subject) => subject.title === page)[0];

        setSelectedSubPage(filteredPage);

        const filteredDisclaimer = getDisclaimer(filteredPage, benefitDisclaimer);

        if (filteredDisclaimer) {
            let position = {
                left: 63,
                top: 80
            }

            switch (filteredPage.title) {
                case "Blackoutschutz":
                    position.left = 5;
                    position.top = 82;
                    break;
            }

        }

        setOpenSubPage(true);
        onChange(true);
    }

    return (
        <div className={"benefitMain"} style={{ backgroundImage: `url(/benefitContent/0_Overview/Background.jpg)` }}>

            <div
                className={style.benefitMainContainer}
                style={{
                    opacity: openSubPage === false ? 1 : 0,
                    visibility: openSubPage === false ? 'visible' : 'hidden',
                }} >

                <div className={style.benefitMain_title} style={{ opacity: canvasIsDragged ? 0 : 1 }}>
                    {(language === "DE" || language === "CH")
                        ? (
                            <>
                                <h1>Die Vorteile</h1>
                                <h1>im Detail</h1>
                            </>
                        )
                        : language === "EN"
                            ? (
                                <>
                                    <h1>The benefits</h1>
                                    <h1>in detail</h1>
                                </>
                            )
                            : language === "IT"
                                ? (
                                    <>
                                        <h1>I vantaggi</h1>
                                        <h1>in sintesi</h1>
                                    </>
                                )
                                : (language === "FR" || language === "fr-BE")
                                    ? (
                                        <>
                                            <h1>Les avantages</h1>
                                            <h1>en détail</h1>
                                        </>
                                    )
                                    : language === "nl-BE"
                                    && (
                                        <>
                                            <h1>De voordelen</h1>
                                            <h1>in detail</h1>
                                        </>
                                    )
                    }
                </div>

                <div className={style.benefitMain_hotpsots} style={{ opacity: canvasIsDragged ? 0 : 1, pointerEvents: canvasIsDragged ? 'none' : 'all' }} >
                    <Hotspots
                        hotspots={hotspotsData}
                        onClick={(page) => {
                            handleSubPages(page);
                        }}
                    />
                </div>

                <div className={style.background} >
                    <div className={style.fadeTop} />
                </div>

            </div>


            <div className={style.fake3D} style={{ display: openSubPage === false && !videoIsPlaying ? 'block' : 'none' }} draggable={false}>
                <Fake3DViewer
                    frames={45}
                    deactivate3D={false}
                    currDragged={(active) => { setCanvasIsDragged(active); }}
                    cacheImgs={true}
                    url={"benefitContent/0_Overview/3DRotation/"}
                    windowIsLoaded={windowIsLoaded}
                />
            </div>

            {
                selectedSubPage && openSubPage &&
                <div className={style.subPage} style={{ opacity: openSubPage ? 1 : 0 }} >
                    <BenefitSubPage
                        introVideoUrl={selectedSubPage.introVideoUrl}
                        outroVideoUrl={selectedSubPage.outroVideoUrl}
                        videoLoopUrl={selectedSubPage.videoLoopUrl}
                        backgroundImage={selectedSubPage.backgroundImage}
                        title={selectedSubPage.title}
                        descriptions={selectedSubPage.descriptions}
                        textPos={selectedSubPage.textPos}
                        onClose={() => {
                            setOpenSubPage(false);
                            onChange(false);
                        }}
                        openDisclaimer={(open) => { openDisclaimer(open) }}
                        fadePosition={selectedSubPage.fadePosition}
                        language={language}
                    />
                </div>
            }
        </div >
    );
}

export default BenefitMain;