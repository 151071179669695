import * as React from 'react';
import style from './main.module.scss';
import './mainFont.css';
import BenefitMain from './ui/benefitMain/benefitMain';
import { useEffect, useRef, useState } from 'react';
import HowToMain from './ui/howToMain/howToMain';
import Loader from './ui/loader/loader';
import { AudiTab, AudiTabs } from './ui/audiComponents/audiTabs';
import Disclaimer from './ui/disclaimer/disclaimer';
import {
    disclaimerData_CH_DE,
    disclaimerData_DE,
    disclaimerData_EN,
    disclaimerData_CH_FR,
    disclaimerData_CH_IT,
    disclaimerData_BE_FR,
    disclaimerData_BE_NL
} from '../datastructure/disclaimerData';
import useWindowSize from './ui/utils/useWindowSize';
import LanguageSelect from './ui/languageSelect/languageSelect';
import {
    benefitSubPages_CH_DE,
    benefitSubPages_DE,
    benefitSubPages_EN,
    benefitSubPages_CH_FR,
    benefitSubPages_CH_IT,
    benefitSubPages_BE_FR,
    benefitSubPages_BE_NL,
    hotspotsData_CH_DE,
    hotspotsData_DE,
    hotspotsData_EN,
    hotspotsData_CH_FR,
    hotspotsData_CH_IT,
    hotspotsData_BE_FR,
    hotspotsData_BE_NL,
} from '../datastructure/benefitData';

export interface MainProps {
    windowIsLoaded: boolean;
}



function Main({ windowIsLoaded }: MainProps) {
    const [tabIndex, setTabIndex] = useState<number | null>(null);
    const [videoIsPlaying, setVideoIsPlaying] = useState<boolean>(false);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [bgImageUrl, setBgImageUrl] = useState<string>("benefitContent/0_Overview/Background.jpg")
    const [loading, setLoading] = useState<boolean>(false);
    const [howToSteps, setHowToSteps] = useState<number>(0);
    const [isSubpageOpen, setIsSubpageOpen] = useState<boolean>(false);
    const [openDisclaimer, setOpenDisclaimer] = useState<boolean>(false);
    const [language, setLanguage] = useState<"DE" | "EN" | "FR" | "IT" | "CH" | "fr-BE" | "nl-BE">("DE");
    console.log("🚀 ~ file: main.tsx ~ line 54 ~ Main ~ language", language)
    const windowSize = useWindowSize();
    const resetMain = useRef<boolean>(false);

    useEffect(() => {
        const video = videoRef.current;

        if (video && tabIndex !== null) {
            video.muted = true;
            video.playsInline = true;
            if (tabIndex === 0) {
                // Sets correct Video-FadeOut when coming from HowTo
                if (howToSteps === 1) {
                    video.src = "benefitContent/4_SoftwareUpdates/FadeOut.mp4";
                } else if (howToSteps === 3) {
                    video.src = "howToContent/4_EinstellungenFahrzeug/FadeOut.mp4";
                } else {
                    video.src = "howToContent/0_Overview/FadeOut.mp4";
                }
            } else if (tabIndex === 1) {
                video.src = "howToContent/0_Overview/FadeIn.mp4";
            }

            video.onloadstart = () => {
                setVideoIsPlaying(true);
                setLoading(true);
            }
            video.oncanplaythrough = () => {
                video.play();

            }

            video.onplay = () => {
                setLoading(false);
                setTimeout(() => {
                    setBgImageUrl(tabIndex === 0 ? "benefitContent/0_Overview/Background.jpg" : "howToContent/0_Overview/Background.jpg")
                }, 1000)
            };
            video.onended = () => {
                setVideoIsPlaying(false);
                if (howToSteps !== 0)
                    setHowToSteps(0)
            };
        }

    }, [tabIndex])

    function handleTabs(value: number) {
        if (value === 0 && tabIndex === null) {

        } else {
            setTabIndex(value);
            window.location.hash = '';
        }

        if (value === 1) {
            resetMain.current = true;
        } else {
            if (resetMain.current)
                resetMain.current = false;
        }
    }

    return (
        <div
            className={style.main}
            style={{
                backgroundImage: `url(${bgImageUrl})`,
                height: windowSize[1].height,
                width: windowSize[1].width,
            }}
            id="main"
        >

            <div className={style.loaderContainer} style={{ display: loading ? 'block' : 'none' }}>
                <Loader open={loading} />
            </div>


            <div>
                <div style={{ display: videoIsPlaying ? 'block' : 'none' }}>
                    <video autoPlay muted playsInline ref={videoRef} className={style.video} />
                </div>


                <div
                    className={style.sectionContainer}
                    style={{
                        opacity: ((tabIndex === 0 || tabIndex === null) && videoIsPlaying === false) ? 1 : 0,
                        visibility: ((tabIndex === 0 || tabIndex === null) && videoIsPlaying === false) ? 'visible' : 'hidden',
                        pointerEvents: ((tabIndex === 0 || tabIndex === null) && videoIsPlaying === false) ? 'all' : 'none'
                    }} >
                    <BenefitMain
                        onChange={(open) => {
                            setIsSubpageOpen(open)
                        }}
                        openDisclaimer={(open) => setOpenDisclaimer(open)}
                        windowIsLoaded={windowIsLoaded}
                        videoIsPlaying={videoIsPlaying}
                        hotspotsData={
                            language === "DE"
                                ? hotspotsData_DE
                                : language === "EN"
                                    ? hotspotsData_EN
                                    : language === "IT"
                                        ? hotspotsData_CH_IT
                                        : language === "FR"
                                            ? hotspotsData_CH_FR
                                            : language === "fr-BE"
                                                ? hotspotsData_BE_FR
                                                : language === "nl-BE"
                                                    ? hotspotsData_BE_NL
                                                    : hotspotsData_DE

                        }
                        benefitSubPages={
                            language === "DE"
                                ? benefitSubPages_DE
                                : language === "EN"
                                    ? benefitSubPages_EN
                                    : language === "IT"
                                        ? benefitSubPages_CH_IT
                                        : language === "FR"
                                            ? benefitSubPages_CH_FR
                                            : language === "fr-BE"
                                                ? benefitSubPages_BE_FR
                                                : language === "nl-BE"
                                                    ? benefitSubPages_BE_NL
                                                    : benefitSubPages_CH_DE
                        }
                        language={language}
                    />
                </div>

                <div
                    className={style.sectionContainer}
                    style={{
                        opacity: (tabIndex === 1 && videoIsPlaying === false) ? 1 : 0,
                        visibility: (tabIndex === 1 && videoIsPlaying === false) ? 'visible' : 'hidden',
                        pointerEvents: (tabIndex === 1 && videoIsPlaying === false) ? 'all' : 'none'
                    }} >
                    <HowToMain
                        onChangeStep={(stepIndex) => {
                            setHowToSteps(stepIndex)

                            if (stepIndex === 1) {
                                setBgImageUrl("benefitContent/4_SoftwareUpdates/Background.jpg");
                            } else if (stepIndex === 3) {
                                setBgImageUrl("howToContent/4_EinstellungenFahrzeug/Background.jpg");
                            } else {
                                setBgImageUrl("howToContent/0_Overview/Background.jpg");
                            }
                        }}
                        openDisclaimer={() => setOpenDisclaimer(true)}
                        isDisclaimerOpen={openDisclaimer}
                        reset={resetMain.current}
                        handleTabs={(value) => { handleTabs(value) }}
                        language={language}
                    />
                </div>

            </div>

            <Disclaimer
                data={
                    language === "DE"
                        ? disclaimerData_DE
                        : language === "EN"
                            ? disclaimerData_EN
                            : language === "IT"
                                ? disclaimerData_CH_IT
                                : language === "FR"
                                    ? disclaimerData_CH_FR
                                    : language === "fr-BE"
                                        ? disclaimerData_BE_FR
                                        : language === "nl-BE"
                                            ? disclaimerData_BE_NL
                                            : disclaimerData_CH_DE
                }
                open={openDisclaimer}
                onClose={(open) => setOpenDisclaimer(open)}
            />

            <div className={style.logoContainer} onClick={() => document.location.reload()} >
                <img src={"audiContent/Audi_Rings_Medium_bl-RGB.png"} alt={"audi-logo"} />
                <div className={style.logoContainer_texts}>
                    {(language === "DE" || language === "CH")
                        ? (
                            <>
                                <p>e-tron Ladesystem</p>
                                <p>connect</p>
                            </>
                        )
                        : language === "EN"
                            ? (
                                <>
                                    <p>e-tron charging</p>
                                    <p>system connect</p>
                                </>
                            )
                            : language === "IT"
                                ? (
                                    <>
                                        <p>Sistema di ricarica</p>
                                        <p>e-tron connect</p>
                                    </>
                                )
                                : language === "FR"
                                    ? (
                                        <>
                                            <p>Système de recharge</p>
                                            <p>e-tron connect</p>
                                        </>
                                    )
                                    : language === "fr-BE"
                                        ? (
                                            <>
                                                <p>Système de charge</p>
                                                <p>e-tron connect</p>
                                            </>
                                        )
                                        : (
                                            <>
                                                <p>e-tron-laadsysteem</p>
                                                <p>connect</p>
                                            </>
                                        )
                    }
                </div>
            </div>

            <div
                className={style.tabsContainer}
                style={{
                    opacity: (!videoIsPlaying && !isSubpageOpen) ? 1 : 0,
                    visibility: (!videoIsPlaying && !isSubpageOpen) ? 'visible' : 'hidden',
                    pointerEvents: (!videoIsPlaying && !isSubpageOpen) ? 'all' : 'none'
                }}>
                <AudiTabs
                    value={tabIndex ?? 0}
                    onChange={(event, value) => { handleTabs(value) }}
                    className={style.main_tabs}
                >
                    <AudiTab disableRipple label={<span className={style.audiTabLink}>{
                        (language === "DE" || language === "CH")
                            ? "Vorteile"
                            : language === "EN"
                                ? "Benefits"
                                : language === "IT"
                                    ? "Vantaggi"
                                    : language === "nl-BE"
                                        ? "Voordelen"
                                        : (language === "FR" || "fr-BE")
                                        && "Avantages"
                    }</span>} />
                    <AudiTab disableRipple label={<span className={style.audiTabLink}>{"How to"}</span>} />
                </AudiTabs>
            </div>

            <div
                id="languageContainer"
                className={style.languageActionsContainer}
                style={{
                    opacity: isSubpageOpen ? 0 : 1,
                    pointerEvents: isSubpageOpen ? 'none' : 'all',
                }}
            >
                <LanguageSelect language={language} onClick={(value) => setLanguage(value)} />
            </div>
        </div>
    );
}

export default Main;