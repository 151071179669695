import * as React from 'react';
import style from './screenblocker.module.scss';

export interface ScreenblockerProps {
    isMobile: boolean;
}

function Screenblocker({ isMobile }: ScreenblockerProps) {
    return (
        <div className={style.screenblocker} style={{display: isMobile ? 'flex' : 'none'}}>
            <div className={style.content}>
                <img src={"images/screenblocker/screenblocker.jpg"} alt="screenblocker" />

                <div className={style.info}>
                    {/*<img src={"audiContent/Audi_Rings_Medium_bl-RGB.svg"} alt="Screenblocker_Logo" />*/}
                    <h2>Bitte wechseln Sie zur Desktop-Ansicht</h2>
                </div>

            </div>
        </div >
    );
}

export default Screenblocker;