import * as React from "react";
import { StepContentProps } from "../../../datastructure/howToData";
import { BulletPoints } from "./listContentV2";
import "./howToContentViewV2.css";
import ReactFullpage from "@fullpage/react-fullpage";
import { useRef } from "react";


export interface HowToContentViewProps {
    steps: Array<StepContentProps>;
    activeStep: number;
    activeSubStep: number;
    setActiveStep: (value: number) => void;
    setActiveSubStep: (value: number) => void;
    setScrollDirection: (direction: string) => void;
    videoIsPlaying: boolean;
    videoIsLoading: boolean;
    openDisclaimer: (active: boolean) => void;
    isDisclaimerOpen: boolean;
    handleTabs: (value: number) => void;
    getFullpageApi: (fullpageApi: any) => void;
    language: "DE" | "EN" | "IT" | "FR";
}

interface SummarizedSubStepsProps {
    step: string;
    title: string;
    titleDisclaimer?: string;
    bulletPoints: string[] | undefined;
    bulletDisclaimer?: any;
    finished?: boolean;
    hints?: string[];
}

function HowToContentViewEN({
    steps,
    activeStep,
    activeSubStep,
    setActiveStep,
    setActiveSubStep,
    setScrollDirection,
    videoIsPlaying,
    videoIsLoading,
    openDisclaimer,
    isDisclaimerOpen,
    handleTabs,
    getFullpageApi,
    language,
}: HowToContentViewProps) {
    const pluginWrapper = () => {
        require("./fullpage.fadingEffect.min");
    };

    const anchors = [
        "MyAudiAccount",
        "Konfiguration",
        "Stromtarif",
        "Einstellungen",
        "Ladeübersicht",
    ];

    let summarizedSubSteps: SummarizedSubStepsProps[] = [];

    steps.map((step) => {
        step.subSteps.map((substep) => {
            summarizedSubSteps.push({
                step: step.title,
                title: substep.title,
                titleDisclaimer: substep.disclaimer,
                bulletPoints: substep.bulletPoints,
                finished: substep.finished,
                bulletDisclaimer: substep.bulletDisclaimer,
                hints: substep.hints,
            });
        });
    });


    const handleInView = (substepIndex: number) => {
        if (substepIndex <= 7) {
            activeStep !== 0 && setActiveStep(0);
            setActiveSubStep(substepIndex);
        } else if (8 <= substepIndex && substepIndex <= 14) {
            activeStep !== 1 && setActiveStep(1);
            setActiveSubStep(substepIndex - 8);
        } else if (15 <= substepIndex && substepIndex <= 17) {
            activeStep !== 2 && setActiveStep(2);
            setActiveSubStep(substepIndex - 15);
        } else if (18 <= substepIndex && substepIndex <= 22) {
            activeStep !== 3 && setActiveStep(3);
            setActiveSubStep(substepIndex - 18);
        } else if (23 <= substepIndex) {
            activeStep !== 4 && setActiveStep(4);
            setActiveSubStep(substepIndex - 23);
        }
    };

    const enterDirection = useRef<null | string>(null);
    const destinationContent = useRef<number>(0);

    return (
        <ReactFullpage //fullpage options
            licenseKey="27A87E64-FEDA44F7-B1C49D9E-FB782E0B"
            fadingEffect={'sections'}
            fadingEffectKey="QU5ZXzI4WVptRmthVzVuUldabVpXTjAyMjQ="
            pluginWrapper={pluginWrapper}
            scrollingSpeed={500}
            scrollOverflow={true}
            //autoScrolling={false}
            onLeave={(origin: object, destination: any, direction: string) => {
                handleInView(destination.index);
                setScrollDirection(direction);
                enterDirection.current = direction;
                destinationContent.current = destination.index;
            }}
            afterLoad={(origin: object, destination: any, direction: string) => {
            }}
            autoScrolling={false}
            render={({ state, fullpageApi }: any) => {
                //fullpageApi && fullpageApi.setAutoScrolling(videoIsLoading || videoIsPlaying || isDisclaimerOpen ? false : true);
                fullpageApi && fullpageApi.setAutoScrolling(false);
                fullpageApi && getFullpageApi(videoIsLoading || videoIsPlaying || isDisclaimerOpen ? null : fullpageApi)
                return (
                    <ReactFullpage.Wrapper>
                        {summarizedSubSteps.map((substep: any, substepIndex) => (
                            <div
                                className="section"
                                key={substep.step + "_" + substep.title}
                                data-anchor={
                                    substepIndex === 1
                                        ? anchors[0]
                                        : 8 === substepIndex
                                            ? anchors[1]
                                            : 15 === substepIndex
                                                ? anchors[2]
                                                : 18 === substepIndex
                                                    ? anchors[3]
                                                    : 23 === substepIndex && anchors[4]
                                }
                            >
                                <div
                                    className={
                                        destinationContent.current === substepIndex ?
                                            ["section_content", enterDirection.current === "down" ? "section_FadeInBottom" : "section_FadeInTop"].join(' ') :
                                            "section_content"
                                    }
                                >
                                    {substep.bulletPoints && (
                                        <h3 className={"contentTitle"}>
                                            {substep.title}
                                            {substep.titleDisclaimer && (
                                                <span
                                                    className="hintNumber"
                                                    onClick={() => openDisclaimer(true)}
                                                >
                                                    {substep.titleDisclaimer}
                                                </span>
                                            )}
                                        </h3>
                                    )}
                                    <BulletPoints
                                        bulletPoints={substep.bulletPoints}
                                        title={substep.title}
                                        bulletDisclaimer={substep.bulletDisclaimer}
                                        openDisclaimer={(open) => {
                                            openDisclaimer(open);
                                        }}
                                        handleTabs={handleTabs}
                                        activeStep={activeStep}
                                        activeSubStep={activeSubStep}
                                        language={language}
                                    />
                                    <div className="hints">
                                        {
                                            substep.hints && substep.hints.map((hint: string, hintIndex: number) => (
                                                <p className="hintText" key={hint + hintIndex} >
                                                    <span className="hintText_decor"> {hintIndex === 0 ? '*' : hintIndex === 1 ? '**' : '***'}</span>
                                                    <span >
                                                        {
                                                            hint === "Ohne einen aktivierten Abfahrtstimer wird Ihr Fahrzeug sofort und schnellstmöglich mit der maximal zur Verfügung stehenden Leistung aufgeladenᵃ (keine Optimierung des Ladeprozesses). Die Funktion Blackoutschutz ist unabhängig von einem aktivierten Abfahrtstimer; sie setzt eine Kopplung des optionalen e-tron Ladesystems connect mit einem kompatiblen HEMSᵈ voraus" ?
                                                                <>Ohne einen aktivierten Abfahrtstimer wird Ihr Fahrzeug sofort und schnellstmöglich mit der maximal zur Verfügung stehenden Leistung aufgeladen<span style={{ cursor: 'pointer' }} onClick={() => openDisclaimer(true)}>ᵃ</span> (keine Optimierung des Ladeprozesses). Die Funktion Blackoutschutz ist unabhängig von einem aktivierten Abfahrtstimer; sie setzt eine Kopplung des e-tron Ladesystems connect mit einem kompatiblen HEMS<span style={{ cursor: 'pointer' }} onClick={() => openDisclaimer(true)}>ᵈ</span> voraus </>
                                                                :
                                                                hint
                                                        }
                                                        {activeStep === 1 && activeSubStep === 3 &&
                                                            <span>
                                                                <a target="_blank" rel="noreferrer" href="https://www.audi.de/dam/nemo/e-mobility/pdf/sma-e-mobilitaet.pdf" className={"hintLink"} style={{pointerEvents: 'none'}}> SMA Solar Technology AG </a>
                                                                .
                                                            </span>
                                                        }

                                                    </span>
                                                </p>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </ReactFullpage.Wrapper>
                );
            }}
        ></ReactFullpage>
    );
}

export default HowToContentViewEN;
