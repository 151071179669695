import { HotspotProps } from "../components/ui/hotspots/hotspots";



export const hotspotsData_DE: HotspotProps[] = [
    {
        position: [40, 14],
        title: 'Solarstrom',
        icon: process.env.PUBLIC_URL + '/ico/SolarII.svg',
    },
    {
        position: [31.5, 38],
        title: 'Integrierte Ladeenergieerfassung',
        icon: process.env.PUBLIC_URL + '/ico/polls-large.svg',
    },
    {
        position: [30, 49],
        title: 'Kostenoptimiertes Laden',
        icon: process.env.PUBLIC_URL + '/ico/document-pricelist-euro-small.svg',
    },
    {
        position: [79.5, 56],
        title: 'Blackoutschutz',
        icon: process.env.PUBLIC_URL + '/ico/Elektro-Blitz.svg',
    },
    {
        position: [68, 67],
        title: 'Software Updates',
        icon: process.env.PUBLIC_URL + '/ico/download-small.svg',
    },
    {
        position: [75, 78],
        title: 'PIN Schutz',
        icon: process.env.PUBLIC_URL + '/ico/lock-small.svg',
    },
];

export const hotspotsData_CH_DE: HotspotProps[] = [
    {
        position: [40, 14],
        title: 'Solarstrom',
        icon: process.env.PUBLIC_URL + '/ico/SolarII.svg',
    },
    {
        position: [31.5, 38],
        title: 'Integrierte Ladeenergieerfassung',
        icon: process.env.PUBLIC_URL + '/ico/polls-large.svg',
    },
    {
        position: [30, 49],
        title: 'Kostenoptimiertes Laden',
        icon: process.env.PUBLIC_URL + '/ico/document-pricelist-euro-small.svg',
    },
    {
        position: [79.5, 56],
        title: 'Blackoutschutz',
        icon: process.env.PUBLIC_URL + '/ico/Elektro-Blitz.svg',
    },
    {
        position: [68, 67],
        title: 'Software Updates',
        icon: process.env.PUBLIC_URL + '/ico/download-small.svg',
    },
    {
        position: [75, 78],
        title: 'PIN Schutz',
        icon: process.env.PUBLIC_URL + '/ico/lock-small.svg',
    },
];

export const hotspotsData_EN: HotspotProps[] = [
    {
        position: [40, 14],
        title: 'Solar power',
        icon: process.env.PUBLIC_URL + '/ico/SolarII.svg',
    },
    {
        position: [31.5, 38],
        title: 'Integrated power metering',
        icon: process.env.PUBLIC_URL + '/ico/polls-large.svg',
    },
    {
        position: [30, 49],
        title: 'Cost-optimised charging',
        icon: process.env.PUBLIC_URL + '/ico/document-pricelist-euro-small.svg',
    },
    {
        position: [79.5, 56],
        title: 'Blackout protection',
        icon: process.env.PUBLIC_URL + '/ico/Elektro-Blitz.svg',
    },
    {
        position: [68, 67],
        title: 'Software updates',
        icon: process.env.PUBLIC_URL + '/ico/download-small.svg',
    },
    {
        position: [75, 78],
        title: 'PIN protection',
        icon: process.env.PUBLIC_URL + '/ico/lock-small.svg',
    },
];

export const hotspotsData_CH_IT: HotspotProps[] = [
    {
        position: [40, 14],
        title: 'Energia solare',
        icon: process.env.PUBLIC_URL + '/ico/SolarII.svg',
    },
    {
        position: [31.5, 38],
        title: 'Registrazione dell’energia di ricarica integrata',
        icon: process.env.PUBLIC_URL + '/ico/polls-large.svg',
    },
    {
        position: [30, 49],
        title: 'Ricarica con costi ottimizzati',
        icon: process.env.PUBLIC_URL + '/ico/document-pricelist-euro-small.svg',
    },
    {
        position: [79.5, 56],
        title: 'Protezione dai blackout',
        icon: process.env.PUBLIC_URL + '/ico/Elektro-Blitz.svg',
    },
    {
        position: [68, 67],
        title: 'Update del software',
        icon: process.env.PUBLIC_URL + '/ico/download-small.svg',
    },
    {
        position: [75, 78],
        title: 'Protezione con PIN',
        icon: process.env.PUBLIC_URL + '/ico/lock-small.svg',
    },
];

export const hotspotsData_CH_FR: HotspotProps[] = [
    {
        position: [40, 14],
        title: 'Électricité solaire',
        icon: process.env.PUBLIC_URL + '/ico/SolarII.svg',
    },
    {
        position: [31.5, 38],
        title: 'Comptabilisation intégrée de l’énergie chargée',
        icon: process.env.PUBLIC_URL + '/ico/polls-large.svg',
    },
    {
        position: [30, 49],
        title: 'Recharge économique',
        icon: process.env.PUBLIC_URL + '/ico/document-pricelist-euro-small.svg',
    },
    {
        position: [79.5, 56],
        title: 'Protection contre les coupures d’électricité',
        icon: process.env.PUBLIC_URL + '/ico/Elektro-Blitz.svg',
    },
    {
        position: [68, 67],
        title: 'Mises à jour logicielles',
        icon: process.env.PUBLIC_URL + '/ico/download-small.svg',
    },
    {
        position: [75, 78],
        title: 'Protection par code PIN',
        icon: process.env.PUBLIC_URL + '/ico/lock-small.svg',
    },
];

export const hotspotsData_BE_FR: HotspotProps[] = [
    {
        position: [40, 14],
        title: 'Électricité photovoltaïque',
        icon: process.env.PUBLIC_URL + '/ico/SolarII.svg',
    },
    {
        position: [31.5, 38],
        title: 'Enregistrement de la consommation',
        icon: process.env.PUBLIC_URL + '/ico/polls-large.svg',
    },
    {
        position: [30, 49],
        title: 'Charge économique',
        icon: process.env.PUBLIC_URL + '/ico/document-pricelist-euro-small.svg',
    },
    {
        position: [79.5, 56],
        title: 'Protection contre les surcharges',
        icon: process.env.PUBLIC_URL + '/ico/Elektro-Blitz.svg',
    },
    {
        position: [68, 67],
        title: 'Mises à jour logicielles',
        icon: process.env.PUBLIC_URL + '/ico/download-small.svg',
    },
    {
        position: [75, 78],
        title: 'Protection par code PIN',
        icon: process.env.PUBLIC_URL + '/ico/lock-small.svg',
    },
];

export const hotspotsData_BE_NL: HotspotProps[] = [
    {
        position: [40, 14],
        title: 'Zonne-energie',
        icon: process.env.PUBLIC_URL + '/ico/SolarII.svg',
    },
    {
        position: [31.5, 38],
        title: 'Geïntegreerde laadenergieregistratie',
        icon: process.env.PUBLIC_URL + '/ico/polls-large.svg',
    },
    {
        position: [30, 49],
        title: 'Kostengeoptimaliseerd laden',
        icon: process.env.PUBLIC_URL + '/ico/document-pricelist-euro-small.svg',
    },
    {
        position: [79.5, 56],
        title: 'Bescherming tegen stroomonderbreking',
        icon: process.env.PUBLIC_URL + '/ico/Elektro-Blitz.svg',
    },
    {
        position: [68, 67],
        title: 'Software-updates',
        icon: process.env.PUBLIC_URL + '/ico/download-small.svg',
    },
    {
        position: [75, 78],
        title: 'Beveiliging met pincode',
        icon: process.env.PUBLIC_URL + '/ico/lock-small.svg',
    },
];

export interface DescriptionSubPageProps {
    text: string[];
    disclaimer: string[] | null;
}

export interface SubPageProps {
    title: string;
    introVideoUrl: string;
    outroVideoUrl: string;
    backgroundImage?: string;
    videoLoopUrl?: string;
    descriptions: DescriptionSubPageProps[];
    textPos: {
        left: number;
        top: number;
    }
    disclaimer?: number[];
    fadePosition: string;
    language: "DE" | "EN" | "IT" | "FR" | "NL";
    id: string;
}

export const benefitSubPages_DE: SubPageProps[] = [
    {
        title: 'Solarstrom',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/FadeOut.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/Background.jpg",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/OverlayLoop.mp4",
        descriptions: [
            {
                text: [
                    'Besitzen Sie eine eigene Photovoltaik-Anlage, können Sie den Ladeprozess Ihres e-tron Fahrzeugs so optimieren, dass dieses mit möglichst viel eigenem Solarstrom geladen wird. Mithilfe von Sonnenscheinprognosen und der gewünschten Abfahrtszeit wird ein entsprechender Ladeplan errechnet. Die gewünschte Abfahrtszeit können Sie komfortabel im Fahrzeug oder in der myAudi App',
                    ' einstellen.'
                ],
                disclaimer: ["ᵉ"],
            },
        ],
        textPos: {
            left: 60,
            top: 40,
        },
        disclaimer: [6, 18],
        fadePosition: "right",
        language: "DE",
        id: "de1",
    },
    {
        title: 'Integrierte Ladeenergieerfassung',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/FadeOut.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/Background.jpg",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/OverlayLoop.mp4",
        descriptions: [
            {
                text: ['Durch die Integrierte Ladeenergieerfassung erhalten Sie eine Übersicht der Ladevorgänge zuhause. Die Dokumentation der geladenen Energie ermöglicht es, die Energiekosten für beruflich gefahrene Kilometer zu dokumentieren, um diese ggf. über den Arbeitgeber abzurechnen.'],
                disclaimer: null,
            }
        ],
        textPos: {
            left: 57,
            top: 51,
        },
        fadePosition: "right",
        language: "DE",
        id: "de2",
    },
    {
        title: 'Kostenoptimiertes Laden',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/OverlayIn.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/Background.jpg",
        descriptions: [
            {
                text: [
                    'Bei der Planung des Ladeprozesses berücksichtigt das System die aktuellen und zukünftigen Preise Ihres Stromanbieters. Unter Berücksichtigung der gewünschten Abfahrtszeiten, wird das Fahrzeug bevorzugt zu Zeiten geladen, in denen für Sie die geringsten Stromkosten entstehen. Dieses gilt für den Zeitraum, währenddessen das Fahrzeug an das Ladesystem angeschlossen ist. Die gewünschte Abfahrtszeit können Sie komfortabel im Fahrzeug oder in der myAudi App',
                    ' einstellen.',
                ],
                disclaimer: ['ᵉ']
            }
        ],
        textPos: {
            left: 5,
            top: 15,
        },
        disclaimer: [5, 18],
        fadePosition: "",
        language: "DE",
        id: "de3",
    },
    {
        title: 'Blackoutschutz',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz//OverlayLoop_1.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz/Background.jpg",
        descriptions: [
            {
                text: [
                    'Das optionale e-tron Ladesystem connect',
                    ' lädt Ihr Fahrzeug mit der in der Hausinfrastruktur maximal zur Verfügung stehenden Leistung, sofern ein kompatibles Home Energy Management System (HEMS) vorhanden ist. In Phasen, in denen im Haus andere elektrische Verbraucher benutzt werden, reduziert das System automatisch die Ladeleistung.'
                ],
                disclaimer: ['ᶜ']
            },
            {
                text: [
                    'Sobald die anderen Verbraucher ausgeschaltet sind, erhöht das optional erhältliche e-tron Ladesystem connect',
                    ' die Ladeleistung wieder auf die höchst mögliche Leistung. Auf diese Weise vermeidet das System Blackouts durch Überlastung der Hausanschlusssicherung – und verkürzt dennoch die Ladedauer für Ihr Elektroauto deutlich.'
                ],
                disclaimer: ['ᶜ']
            }
        ],
        textPos: {
            left: 5,
            top: 15,
        },
        disclaimer: [4, 6],
        fadePosition: "left",
        language: "DE",
        id: "de4",
    },
    {
        title: 'Software Updates',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/OverlayLoop.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/Background.jpg",
        descriptions: [
            {
                text: [
                    'Mithilfe von Software-Updates bleibt Ihr optionales e-tron Ladesystem connect',
                    ' immer auf dem aktuellsten Stand.',
                ],
                disclaimer: ['ᶜ'],
            },
        ],
        textPos: {
            left: 60,
            top: 65,
        },
        fadePosition: "bottom",
        language: "DE",
        id: "de5",
    },
    {
        title: 'PIN Schutz',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/OverlayLoop.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/Background.jpg",
        descriptions: [
            {
                text: ['Das optionale Ladesystem kann bei Bedarf durch Vergabe einer 4-stelligen PIN vor unbefugter Nutzung geschützt werden.'],
                disclaimer: null,
            }
        ],
        textPos: {
            left: 60,
            top: 74,
        },
        fadePosition: "bottom",
        language: "DE",
        id: "de6",
    }
];

export const benefitSubPages_CH_DE: SubPageProps[] = [
    {
        title: 'Solarstrom',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/FadeOut.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/Background.jpg",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/OverlayLoop.mp4",
        descriptions: [
            {
                text: [
                    'Besitzen Sie eine eigene Photovoltaik-Anlage, können Sie den Ladeprozess Ihres e-tron Fahrzeugs so optimieren, dass dieses mit möglichst viel eigenem Solarstrom geladen wird. Mithilfe von Sonnenscheinprognosen und der gewünschten Abfahrtszeit wird ein entsprechender Ladeplan errechnet. Die gewünschte Abfahrtszeit können Sie komfortabel im Fahrzeug oder in der myAudi App',
                    ' einstellen.'
                ],
                disclaimer: ["ᵉ"],
            },
        ],
        textPos: {
            left: 60,
            top: 40,
        },
        disclaimer: [6, 18],
        fadePosition: "right",
        language: "DE",
        id: "ch_de1",
    },
    {
        title: 'Integrierte Ladeenergieerfassung',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/FadeOut.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/Background.jpg",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/OverlayLoop.mp4",
        descriptions: [
            {
                text: ['Durch die Integrierte Ladeenergieerfassung erhalten Sie eine Übersicht der Ladevorgänge zuhause. Die Dokumentation der geladenen Energie ermöglicht es, die Energiekosten für beruflich gefahrene Kilometer zu dokumentieren, um diese ggf. über den Arbeitgeber abzurechnen.'],
                disclaimer: null,
            }
        ],
        textPos: {
            left: 57,
            top: 51,
        },
        fadePosition: "right",
        language: "DE",
        id: "ch_de2",
    },
    {
        title: 'Kostenoptimiertes Laden',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/OverlayIn.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/Background.jpg",
        descriptions: [
            {
                text: [
                    'Bei der Planung des Ladeprozesses berücksichtigt das System die aktuellen und zukünftigen Preise Ihres Stromanbieters. Unter Berücksichtigung der gewünschten Abfahrtszeiten, wird das Fahrzeug bevorzugt zu Zeiten geladen, in denen für Sie die geringsten Stromkosten entstehen. Dieses gilt für den Zeitraum, währenddessen das Fahrzeug an das Ladesystem angeschlossen ist. Die gewünschte Abfahrtszeit können Sie komfortabel im Fahrzeug oder in der myAudi App',
                    ' einstellen.',
                ],
                disclaimer: ['ᵉ']
            }
        ],
        textPos: {
            left: 5,
            top: 15,
        },
        disclaimer: [5, 18],
        fadePosition: "",
        language: "DE",
        id: "ch_de3",
    },
    {
        title: 'Blackoutschutz',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz//OverlayLoop_1.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz/Background.jpg",
        descriptions: [
            {
                text: [
                    'Das optionale e-tron Ladesystem connect',
                    ' lädt Ihr Fahrzeug mit der in der Hausinfrastruktur maximal zur Verfügung stehenden Leistung, sofern ein kompatibles Home Energy Management System (HEMS) vorhanden ist. In Phasen, in denen im Haus andere elektrische Verbraucher benutzt werden, reduziert das System automatisch die Ladeleistung.'
                ],
                disclaimer: ['ᶜ']
            },
            {
                text: [
                    'Sobald die anderen Verbraucher ausgeschaltet sind, erhöht das optional erhältliche e-tron Ladesystem connect',
                    ' die Ladeleistung wieder auf die höchst mögliche Leistung. Auf diese Weise vermeidet das System Blackouts durch Überlastung der Hausanschlusssicherung – und verkürzt dennoch die Ladedauer für Ihr Elektroauto deutlich.'
                ],
                disclaimer: ['ᶜ']
            }
        ],
        textPos: {
            left: 5,
            top: 15,
        },
        disclaimer: [4, 6],
        fadePosition: "left",
        language: "DE",
        id: "ch_de4",
    },
    {
        title: 'Software Updates',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/OverlayLoop.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/Background.jpg",
        descriptions: [
            {
                text: [
                    'Mithilfe von Software-Updates bleibt Ihr optionales e-tron Ladesystem connect',
                    ' immer auf dem aktuellsten Stand.',
                ],
                disclaimer: ['ᶜ'],
            },
        ],
        textPos: {
            left: 60,
            top: 65,
        },
        fadePosition: "bottom",
        language: "DE",
        id: "ch_de5",
    },
    {
        title: 'PIN Schutz',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/OverlayLoop.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/Background.jpg",
        descriptions: [
            {
                text: ['Das optionale Ladesystem kann bei Bedarf durch Vergabe einer 4-stelligen PIN vor unbefugter Nutzung geschützt werden.'],
                disclaimer: null,
            }
        ],
        textPos: {
            left: 60,
            top: 74,
        },
        fadePosition: "bottom",
        language: "DE",
        id: "ch_de6",
    }
];

export const benefitSubPages_EN: SubPageProps[] = [
    {
        title: 'Solar power',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/FadeOut.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/Background.jpg",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/OverlayLoop.mp4",
        descriptions: [
            {
                text: [
                    'If you have your own photovoltaic system, you can optimise the charging process of your e-tron vehicle to use as much of your own solar power as possible. Taking sunshine forecasts and the desired departure time into account, a suitable charging schedule is calculated. You can conveniently set your desired departure time in the vehicle or via the myAudi app.'
                ],
                disclaimer: ["ᵉ"],
            },
        ],
        textPos: {
            left: 60,
            top: 40,
        },
        disclaimer: [6, 18],
        fadePosition: "right",
        language: "EN",
        id: "en1",
    },
    {
        title: 'Integrated power metering',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/FadeOut.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/Background.jpg",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/OverlayLoop.mp4",
        descriptions: [
            {
                text: ['Integrated power metering provides you with an overview of your charging processes at home. Documentation of the charged energy means that you can document the energy costs for kilometres you have driven for work and pass the costs on to your employer, if applicable.'],
                disclaimer: null,
            }
        ],
        textPos: {
            left: 57,
            top: 51,
        },
        fadePosition: "right",
        language: "EN",
        id: "en2",
    },
    {
        title: 'Cost-optimised charging',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/EN/OverlayIn.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/Background.jpg",
        descriptions: [
            {
                text: [
                    'When scheduling the charging process, the system considers the current and future tariffs of your electricity supplier. Taking into account your desired departure times, the system gives preference to charging at times that will keep your electricity costs to a minimum. This applies to the period of time during which the vehicle is connected to the charging system. You can conveniently set your desired departure time in the vehicle or via the myAudi app',
                    '.',
                ],
                disclaimer: ['ᵉ']
            }
        ],
        textPos: {
            left: 5,
            top: 15,
        },
        disclaimer: [5, 18],
        fadePosition: "",
        language: "EN",
        id: "en3",
    },
    {
        title: 'Blackout protection',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz//OverlayLoop_1.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz/Background.jpg",
        descriptions: [
            {
                text: [
                    'The optional e-tron charging system connect',
                    ' charges your vehicle with the maximum power available in your home infrastructure, provided that a compatible home energy management system (HEMS) is available. At times when other electrical appliance are being used in the home, the system will automatically reduce the power being drawn for charging.'
                ],
                disclaimer: ['ᶜ']
            },
            {
                text: [
                    'Once the other consumers are switched off, the optionally available e-tron charging system connect',
                    ' increases the charging power back to the maximum capacity. This allows the system to prevent blackouts caused by overloading the main fuse, while still significantly keeping charging times for your electric car as short as possible.'
                ],
                disclaimer: ['ᶜ']
            }
        ],
        textPos: {
            left: 5,
            top: 15,
        },
        disclaimer: [4, 6],
        fadePosition: "left",
        language: "EN",
        id: "en4",
    },
    {
        title: 'Software updates',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/OverlayLoop.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/Background.jpg",
        descriptions: [
            {
                text: [
                    'Software updates keep your optional e-tron charging system connect',
                    ' up to date at all times.',
                ],
                disclaimer: ['ᶜ'],
            },
        ],
        textPos: {
            left: 60,
            top: 65,
        },
        fadePosition: "bottom",
        language: "EN",
        id: "en5",
    },
    {
        title: 'PIN protection',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/OverlayLoop.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/Background.jpg",
        descriptions: [
            {
                text: ['If required, the optional charging system can be protected against unauthorised use with a 4-digit PIN.'],
                disclaimer: null,
            }
        ],
        textPos: {
            left: 60,
            top: 74,
        },
        fadePosition: "bottom",
        language: "EN",
        id: "en6",
    }
];

export const benefitSubPages_CH_IT: SubPageProps[] = [
    {
        title: 'Energia solare',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/FadeOut.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/Background.jpg",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/OverlayLoop.mp4",
        descriptions: [
            {
                text: [
                    'Se è proprietario di un impianto fotovoltaico, il processo di ricarica del suo veicolo e-tron può essere ottimizzato al fine di utilizzare la maggiore quantità di energia solare possibile. Affidandosi alle previsioni relative all’irradiazione solare e impostando l’orario di partenza desiderato, viene calcolato un adeguato piano di ricarica. È possibile impostare comodamente l’orario di partenza desiderato nel veicolo o nell’app myAudi',
                    '.'
                ],
                disclaimer: ["ᵉ"],
            },
        ],
        textPos: {
            left: 60,
            top: 40,
        },
        disclaimer: [6, 18],
        fadePosition: "right",
        language: "IT",
        id: "ch_it1",
    },
    {
        title: 'Registrazione dell’energia di ricarica integrata',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/CH_IT/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/FadeOut.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/Background.jpg",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/CH_IT/OverlayLoop.mp4",
        descriptions: [
            {
                text: ['Grazie alla registrazione dell’energia di ricarica integrata riceverà a casa una sintesi delle ricariche effettuate. La documentazione dell’energia di ricarica consente di registrare i costi energetici dei chilometri percorsi per lavoro, per conteggiarli eventualmente con il datore di lavoro.'],
                disclaimer: null,
            }
        ],
        textPos: {
            left: 57,
            top: 51,
        },
        fadePosition: "right",
        language: "IT",
        id: "ch_it2",
    },
    {
        title: 'Ricarica con costi ottimizzati',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/CH_IT/OverlayIn.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/Background.jpg",
        descriptions: [
            {
                text: [
                    'Nel pianificare il processo di ricarica, il sistema considera i prezzi attuali e futuri del suo fornitore di elettricità. Tenendo in considerazione i tempi di partenza desiderati, il veicolo viene ricaricato preferibilmente in orari che comportino i costi energetici minori per lei. Questo vale per il periodo durante il quale l’auto è collegata al sistema di ricarica. È possibile impostare comodamente l’orario di partenza desiderato nel veicolo o nell’app myAudi',
                    '.',
                ],
                disclaimer: ['ᵉ']
            }
        ],
        textPos: {
            left: 5,
            top: 15,
        },
        disclaimer: [5, 18],
        fadePosition: "",
        language: "IT",
        id: "ch_it3",
    },
    {
        title: 'Protezione dai blackout',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz//OverlayLoop_1.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz/Background.jpg",
        descriptions: [
            {
                text: [
                    'Il sistema di ricarica e-tron connec',
                    ' opzionale ricarica il suo veicolo con la potenza massima disponibile nell’infrastruttura domestica, a condizione che sia presente un Home Energy Management System (HEMS) compatibile. Quando in casa vengono impiegati altri dispositivi elettrici, il sistema riduce automaticamente la potenza di ricarica.'
                ],
                disclaimer: ['ᶜ']
            },
            {
                text: [
                    'Non appena tali apparecchi vengono spenti, il sistema di ricarica e-tron connec',
                    ' incrementa nuovamente la potenza riportandola a quella massima disponibile. In tal modo il sistema previene i blackout dovuti a un sovraccarico della protezione del collegamento domestico – pur riducendo notevolmente i tempi di ricarica dell’auto elettrica.'
                ],
                disclaimer: ['ᶜ']
            }
        ],
        textPos: {
            left: 5,
            top: 15,
        },
        disclaimer: [4, 6],
        fadePosition: "left",
        language: "IT",
        id: "ch_it4",
    },
    {
        title: 'Update del software',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/OverlayLoop.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/Background.jpg",
        descriptions: [
            {
                text: [
                    'Grazie alla possibilità di effettuare gli update del software, il suo sistema di ricarica e-tron',
                    ' opzionale è sempre perfettamente aggiornato.',
                ],
                disclaimer: ['ᶜ'],
            },
        ],
        textPos: {
            left: 60,
            top: 65,
        },
        fadePosition: "bottom",
        language: "IT",
        id: "ch_it5",
    },
    {
        title: 'Protezione con PIN',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/CH_IT/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/CH_IT/OverlayLoop.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/Background.jpg",
        descriptions: [
            {
                text: ['Se necessario, il sistema di ricarica può essere protetto dagli utilizzi non autorizzati mediante l’inserimento di un PIN a quattro cifre.'],
                disclaimer: null,
            }
        ],
        textPos: {
            left: 57,
            top: 66,
        },
        fadePosition: "bottom",
        language: "IT",
        id: "ch_it6",
    }
];

export const benefitSubPages_CH_FR: SubPageProps[] = [
    {
        title: 'Électricité solaire',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/FadeOut.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/Background.jpg",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/OverlayLoop.mp4",
        descriptions: [
            {
                text: [
                    'Si vous disposez de votre propre installation photovoltaïque, vous pouvez optimiser le processus de recharge de votre e-tron de sorte à utiliser au maximum l’énergie solaire produite. Un plan de recharge est établi à partir des prévisions d’ensoleillement et de l’heure de départ souhaitée. Vous pouvez régler aisément l’heure de démarrage souhaitée, depuis le véhicule ou l’application myAudi',
                    '.'
                ],
                disclaimer: ["ᵉ"],
            },
        ],
        textPos: {
            left: 60,
            top: 40,
        },
        disclaimer: [6, 18],
        fadePosition: "right",
        language: "FR",
        id: "ch_fr1",
    },
    {
        title: 'Comptabilisation intégrée de l’énergie chargée',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/CH_FR/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/FadeOut.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/Background.jpg",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/CH_FR/OverlayLoop.mp4",
        descriptions: [
            {
                text: ['La comptabilisation intégrée de l’énergie chargée vous permet d’obtenir un aperçu des procédures de recharge à domicile. La consignation de l’énergie chargée permet de documenter le coût énergétique des kilomètres parcourus à titre professionnel pour pouvoir ensuite, le cas échéant, les facturer à l’employeur.'],
                disclaimer: null,
            }
        ],
        textPos: {
            left: 57,
            top: 51,
        },
        fadePosition: "right",
        language: "FR",
        id: "ch_fr2",
    },
    {
        title: 'Recharge économique',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/CH_FR/OverlayIn.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/Background.jpg",
        descriptions: [
            {
                text: [
                    'Lors de la planification du processus de recharge, le système tient compte des prix actuels et futurs de votre fournisseur d’électricité. La recharge du véhicule s’effectue de préférence aux heures où l’électricité est la moins chère, en prenant en compte les heures de démarrage souhaitées. Cela vaut tant que le véhicule est raccordé au système de recharge. Vous pouvez régler aisément l’heure de démarrage souhaitée, depuis le véhicule ou l’application myAudi',
                    '.',
                ],
                disclaimer: ['ᵉ']
            }
        ],
        textPos: {
            left: 5,
            top: 15,
        },
        disclaimer: [5, 18],
        fadePosition: "",
        language: "FR",
        id: "ch_fr3",
    },
    {
        title: 'Protection contre les coupures d’électricité',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz//OverlayLoop_1.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz/Background.jpg",
        descriptions: [
            {
                text: [
                    'Le système de recharge e-tron connect en option',
                    ' recharge votre véhicule en employant la puissance maximale disponible de votre infrastructure domestique, sous réserve que vous disposiez d’un système de gestion de l’énergie domestique. Lorsque d’autres consommateurs électriques sont en cours d’utilisation au sein de votre foyer, le système réduit automatiquement la puissance de charge.'
                ],
                disclaimer: ['ᶜ']
            },
            {
                text: [
                    'Dès que les autres consommateurs sont éteints, le système de recharge e-tron connect en option',
                    ' augmente la puissance de charge au maximum. Cela évite les coupures de courant dues à une surcharge des fusibles de l’installation domestique, tout en réduisant nettement le temps de recharge de votre voiture électrique.'
                ],
                disclaimer: ['ᶜ']
            }
        ],
        textPos: {
            left: 5,
            top: 15,
        },
        disclaimer: [4, 6],
        fadePosition: "left",
        language: "FR",
        id: "ch_fr4",
    },
    {
        title: 'Mises à jour logicielles',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/OverlayLoop.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/Background.jpg",
        descriptions: [
            {
                text: [
                    'Des mises à jour logicielles permettent de maintenir votre système de recharge e-tron connect en option',
                    ' à jour.',
                ],
                disclaimer: ['ᶜ'],
            },
        ],
        textPos: {
            left: 60,
            top: 65,
        },
        fadePosition: "bottom",
        language: "FR",
        id: "ch_fr5",
    },
    {
        title: 'Protection par code PIN',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/CH_FR/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/CH_FR/OverlayLoop.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/Background.jpg",
        descriptions: [
            {
                text: ['Au besoin, le système de recharge en option peut être protégé contre toute utilisation abusive par un code PIN à quatre chiffres.'],
                disclaimer: null,
            }
        ],
        textPos: {
            left: 57,
            top: 66,
        },
        fadePosition: "bottom",
        language: "FR",
        id: "ch_fr6",
    }
];

export const benefitSubPages_BE_FR: SubPageProps[] = [
    {
        title: 'Électricité photovoltaïque',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/FadeOut.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/Background.jpg",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/OverlayLoop.mp4",
        descriptions: [
            {
                text: [
                    'Si votre domicile est équipé d’une installation photovoltaïque, vous pouvez configurer la recharge de votre voiture e-tron de façon à ce qu’elle utilise une quantité maximale de courant d’origine photovoltaïque. Le système s’appuie sur les prévisions d’ensoleillement et l’heure de départ souhaitée pour calculer un plan de recharge optimisé. L’heure de départ souhaitée se définit de manière très simple à bord de la voiture ou sur l’application myAudi',
                    ' .'
                ],
                disclaimer: ["⁵"],
            },
        ],
        textPos: {
            left: 60,
            top: 40,
        },
        disclaimer: [6, 18],
        fadePosition: "right",
        language: "FR",
        id: "be_fr1",
    },
    {
        title: 'Enregistrement de la consommation',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/BE_FR/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/FadeOut.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/Background.jpg",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/BE_FR/OverlayLoop.mp4",
        descriptions: [
            {
                text: [
                    'L’enregistrement de la consommation permet d’avoir une vue d’ensemble des opérations de recharge au domicile. Cette fonction permet de calculer le coût de l’électricité consommée par la voiture dans le cadre de l’activité professionnelle, pour le présenter éventuellement à un employeur.'
                ],
                disclaimer: null,
            }
        ],
        textPos: {
            left: 57,
            top: 51,
        },
        fadePosition: "right",
        language: "FR",
        id: "be_fr2",
    },
    {
        title: 'Charge économique',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/BE_FR/OverlayIn.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/Background.jpg",
        descriptions: [
            {
                text: [
                    'Pour planifier le processus de recharge, le système tient compte des tarifs actuels et futurs de votre fournisseur d’électricité. En fonction de l’heure de départ souhaitée, la recharge s’effectuera à des moments où les prix sont les plus bas, à condition que la voiture soit alors raccordée au système de recharge. L’heure de départ souhaitée se définit de manière très simple à bord de la voiture ou sur l’application myAudi'
                ],
                disclaimer: ['⁵']
            }
        ],
        textPos: {
            left: 5,
            top: 15,
        },
        disclaimer: [5, 18],
        fadePosition: "",
        language: "FR",
        id: "be_fr3",
    },
    {
        title: 'Protection contre les surcharges',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz//OverlayLoop_1.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz/Background.jpg",
        descriptions: [
            {
                text: [
                    'Le système de recharge e-tron connect',
                    ' (disponible en option) recharge votre voiture avec la puissance qui est la plus disponible dans l’infrastructure domestique, à condition qu’un système de gestion de l’énergie pour l’habitation (HEMS) compatible soit installé. Lorsque d’autres appareils électriques sont utilisés, il réduit automatiquement la puissance de recharge.'
                ],
                disclaimer: ['³']
            },
            {
                text: [
                    'Dès qu’ils ne sont plus sous tension, il l’augmente à nouveau au niveau le plus haut possible. Le système de recharge e-tron connect',
                    ' permet ainsi d’éviter les coupures de courant dues à la surcharge du fusible principal, tout en réduisant nettement la durée de recharge de votre voiture électrique.'
                ],
                disclaimer: ['³']
            }
        ],
        textPos: {
            left: 5,
            top: 15,
        },
        disclaimer: [4, 6],
        fadePosition: "left",
        language: "FR",
        id: "be_fr4",
    },
    {
        title: 'Mises à jour logicielles',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/OverlayLoop.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/Background.jpg",
        descriptions: [
            {
                text: [
                    'Grâce aux mises à jour logicielles, votre système de recharge e-tron connect',
                    '  (disponible en option) reste toujours performant.',
                ],
                disclaimer: ['³'],
            },
        ],
        textPos: {
            left: 60,
            top: 65,
        },
        fadePosition: "bottom",
        language: "FR",
        id: "be_fr5",
    },
    {
        title: 'Protection par code PIN',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/BE_FR/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/BE_FR/OverlayLoop.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/Background.jpg",
        descriptions: [
            {
                text: ['Le système de recharge (disponible en option) peut au besoin être protégé des utilisations frauduleuses par l’attribution d’un code PIN à quatre chiffres.'],
                disclaimer: null,
            }
        ],
        textPos: {
            left: 55,
            top: 65,
        },
        fadePosition: "bottom",
        language: "FR",
        id: "be_fr6",
    }
];

export const benefitSubPages_BE_NL: SubPageProps[] = [
    {
        title: 'Zonne-energie',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/FadeOut.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/Background.jpg",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/2_SolarstromNutzung/OverlayLoop.mp4",
        descriptions: [
            {
                text: [
                    'Als u een eigen fotovoltaïsch systeem heeft, kunt u het laadproces van uw Audi e-tron optimaliseren, zodat dit met zoveel mogelijk zonne-energie wordt geladen. Met behulp van zonneschijnvoorspellingen en de gewenste vertrektijd wordt een bijbehorend laadplan berekend. De gewenste vertrektijd kunt u gemakkelijk instellen in de wagen of in de myAudi-app.'
                ],
                disclaimer: ["⁵"],
            },
        ],
        textPos: {
            left: 60,
            top: 40,
        },
        disclaimer: [6, 18],
        fadePosition: "right",
        language: "NL",
        id: "be_nl1",
    },
    {
        title: 'Geïntegreerde laadenergieregistratie',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/BE_NL/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/FadeOut.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/Background.jpg",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/6_IntegrierteLadeerfassung/BE_NL/OverlayLoop.mp4",
        descriptions: [
            {
                text: ['De geïntegreerde laadenergieregistratie verschaft u een overzicht van de laadprocessen in huis. Door het documenteren van de geladen energie kunt u bijvoorbeeld de energiekosten voor beroepsmatig afgelegde kilometers documenteren en aan uw werkgever bezorgen.'],
                disclaimer: null,
            }
        ],
        textPos: {
            left: 57,
            top: 51,
        },
        fadePosition: "right",
        language: "NL",
        id: "be_nl2",
    },
    {
        title: 'Kostengeoptimaliseerd laden',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/BE_NL/OverlayIn.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/5_KosteneffizientesLaden/Background.jpg",
        descriptions: [
            {
                text: [
                    'Bij de planning van het laadproces houdt het systeem rekening met de actuele en toekomstige prijzen van uw stroomleverancier. Rekening houdend met de gewenste vertrektijden wordt het voertuig bij voorkeur geladen tijdens perioden van dag waarop de stroom het goedkoopst is. Dat geldt zolang de wagen is aangesloten op het laadsysteem. De gewenste vertrektijd kunt u gemakkelijk instellen in de wagen of in de myAudi-app.',
                ],
                disclaimer: ['⁵']
            }
        ],
        textPos: {
            left: 5,
            top: 15,
        },
        disclaimer: [5, 18],
        fadePosition: "",
        language: "NL",
        id: "be_nl3",
    },
    {
        title: 'Bescherming tegen stroomonderbreking',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz//OverlayLoop_1.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/1_Blackoutschutz/Background.jpg",
        descriptions: [
            {
                text: [
                    'Het optionele e-tron laadsysteem connect',
                    '  laadt uw wagen op met het maximale vermogen van de woninginfrastructuur, op voorwaarde dat een compatibel Home-Energy-Management-systeem (HEMS) beschikbaar is. Tijdens fasen waarin in de woning andere elektrische toestellen worden gebruikt, vermindert het systeem automatisch het laadvermogen.'
                ],
                disclaimer: ['³']
            },
            {
                text: [
                    'Zodra de andere toestellen uitgeschakeld zijn, verhoogt het optioneel verkrijgbare e-tron-laadsysteem connect',
                    ' het laadvermogen weer tot de hoogst mogelijke waarde. Op deze manier vermijdt het systeem stroomuitval door overbelasting van de hoofdzekering – en verkort het toch de laadtijd van uw elektrische wagen aanzienlijk.'
                ],
                disclaimer: ['³']
            }
        ],
        textPos: {
            left: 5,
            top: 15,
        },
        disclaimer: [4, 6],
        fadePosition: "left",
        language: "NL",
        id: "be_nl4",
    },
    {
        title: 'Software-updates',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/OverlayLoop.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/4_SoftwareUpdates/Background.jpg",
        descriptions: [
            {
                text: [
                    'Met behulp van software-updates blijft uw optionele e-tron-laadsysteem connect',
                    ' altijd up-to-date.',
                ],
                disclaimer: ['³'],
            },
        ],
        textPos: {
            left: 60,
            top: 65,
        },
        fadePosition: "bottom",
        language: "NL",
        id: "be_nl5",
    },
    {
        title: 'Beveiliging met pincode',
        introVideoUrl: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/BE_NL/FadeIn.mp4",
        outroVideoUrl: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/FadeOut.mp4",
        videoLoopUrl: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/BE_NL/OverlayLoop.mp4",
        backgroundImage: process.env.PUBLIC_URL + "/benefitContent/3_PINSchutz/Background.jpg",
        descriptions: [
            {
                text: ['Indien nodig kunt u het optionele laadsysteem beveiligen tegen onbevoegd gebruik door het toekennen van een 4-cijferige pincode.'],
                disclaimer: null,
            }
        ],
        textPos: {
            left: 54,
            top: 74,
        },
        fadePosition: "bottom",
        language: "FR",
        id: "be_nl6",
    }
];

export interface DisclaimerProps {
    number: number;
    descr: string;
}

export const benefitDisclaimer: DisclaimerProps[] = [
    {
        number: 4, //done
        descr: '⁴ 22 kW AC Laden erfordert die fahrzeugseitige Sonderausstattung eines On-board-Ladegeräts bis 22 kW (AC); optional verfügbar für den Audi e-tron 55 bzw. Audi e-tron Sportback 55; über den genauen Einsatztermin im e-tron 50 bzw. Audi e-tron Sportback 50 und e-tron S bzw. Audi e-tron Sportback S informieren Sie sich bitte bei Ihrem Audi Partner.'
    },
    {
        number: 5, // ????
        descr: '⁵ Die Funktion setzt voraus, dass der individuell abgeschlossene Stromvertrag des Kunden mit dem Energieversorger unterschiedliche Strompreise, z.B. Tag- oder Nachttarife beinhaltet. Die Information über die unterschiedlichen Strompreise muss durch den Kunden in seinem myAudi Account oder einem kompatiblem Home Energy Management System (HEMS) hinterlegt werden.'
    },
    {
        number: 6, /// done
        descr: '⁶ Die Funktion setzt das Vorhandensein eines kompatiblen Home Energy Management Systems (HEMS) und einer Photovoltaik-Anlage voraus. Dieses wird nicht durch die AUDI AG angeboten und ist nicht Bestandteil des Lieferumfangs. Um eine volle Kompatibilität gewährleisten zu können arbeitet die AUDI AG mit den zwei führenden Anbietern von Home Energy Management Systemen Hager Group und SMA Solar Technology AG zusammen.'
    },
    {
        number: 18, // done
        descr: '¹⁸ Erfordert die Registrierung und den Abschluss eines Hauptnutzervertrages bei myAudi sowie die Hauptnutzernominierung im Fahrzeug MMI. Die Verifizierung und Anmeldung als Hauptnutzer stellt sicher, dass Sie die Fahrzeugsteuerung (beispielsweise das Ver- und Entriegeln per App) nutzen können. Die Nominierung des Hauptnutzers im Fahrzeug MMI, d.h. die Verknüpfung von Ihrem myAudi Account und Ihrem Audi, ist erforderlich, um die Abfahrtszeit in der App einzustellen und an Ihren Audi zu übermitteln.'
    },
]