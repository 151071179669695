import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import style from './benefitSubPageComponent.module.scss';
import IconButton from '@material-ui/core/IconButton';
import { DescriptionSubPageProps } from '../../../datastructure/benefitData';

export interface DisclaimerDescrProps {
    descr: string;
    number: number;
}

export interface DisclaimerProps {
    disclaimerText: DisclaimerDescrProps[];
    position: {
        left: number,
        top: number
    },
}

export interface BenefitSubPageProps {
    introVideoUrl: string;
    outroVideoUrl: string;
    videoLoopUrl?: string;
    videoOverlay?: string;
    backgroundImage?: string
    title: string;
    descriptions: DescriptionSubPageProps[];
    textPos: {
        left: number,
        top: number
    },
    onClose: () => void;
    openDisclaimer: (open: boolean) => void;
    fadePosition: string;
    language: "DE" | "EN" | "FR" | "IT" | "CH" | "fr-BE" | "nl-BE";
}

function BenefitSubPage({
    introVideoUrl,
    outroVideoUrl,
    videoLoopUrl,
    videoOverlay,
    backgroundImage,
    title,
    descriptions,
    textPos,
    onClose,
    openDisclaimer,
    fadePosition,
    language
}: BenefitSubPageProps) {
    const introOutroVideoRef = useRef<HTMLVideoElement>(null);
    const [currVideo, setCurrVideo] = useState<"intro" | "outro">("intro")
    const videoLoopRef = useRef<HTMLVideoElement>(null)
    const [displayContent, setDisplayContent] = useState<"intro" | "outro" | "background">("intro");
    const [blackOutSystem, setBlackOutSystem] = useState<string | null>(null);
    const overLoadTexts = {
        "DE": "Gefahr einer Überlastung!",
        "EN": "Risk of overloading!",
        "IT": "Pericolo di un sovraccarico!",
        "FR": "Risque de surcharge!",
        "CH": "Gefahr einer Überlastung!",
        "fr-BE": "Surcharge: danger!",
        "nl-BE": "Overbelasting: gevaar!",
    };
    const regulationTexts = {
        "DE": "Ladeleistung wird reguliert...",
        "EN": "Charging capacity being regulated...",
        "IT": "Regolazione della potenza di ricarica...",
        "FR": "Puissance de charge en cours de régulation...",
        "CH": "Ladeleistung wird reguliert...",
        "fr-BE": "Réglage de la puissance de recharge en cours...",
        "nl-BE": "Aanpassing laadvermogen bezig...",
    };


    let blackOutTitle: string = blackOutSystem === null
        ? (overLoadTexts[language])
        : (regulationTexts[language]);
    const [playLoop2, setPlayLoop2] = useState<boolean>(false);
    const videoBlackoutIn = useRef<HTMLVideoElement | null>(null);
    const [fixBackground, setFixBackground] = useState<string>("");

    let fixedTitle: any = title;

    useEffect(() => {
        const introOutroVideo = introOutroVideoRef.current;

        if (introOutroVideo) {
            if (currVideo === "intro") {
                introOutroVideo.src = introVideoUrl;

                introOutroVideo.onended = () => {
                    setDisplayContent("background");
                }

            } else if (currVideo === "outro") {
                introOutroVideo.src = outroVideoUrl;

                introOutroVideo.onended = () => {
                    onClose();
                }
            }

            introOutroVideo.oncanplaythrough = () => {
                introOutroVideo.play();
            }

            introOutroVideo.onplay = () => {
                if (currVideo === "outro") {
                    setTimeout(() => {
                        setFixBackground("");
                    }, 500)
                } else if (currVideo === "intro") {
                    setTimeout(() => {
                        setFixBackground(backgroundImage ?? "");
                    }, 1000)
                }
            }

        }

    }, [introOutroVideoRef, currVideo, outroVideoUrl, introVideoUrl, backgroundImage])

    useEffect(() => {
        const videoLoop = videoLoopRef.current;

        if ((displayContent === "intro" || displayContent === "outro") && videoLoop) {
            videoLoop.pause();
        } else if (displayContent === "background" && videoLoop) {
            videoLoop.play();
        }
    }, [displayContent])

    switch (title) {
        /// SOLAR
        case "Solarstrom":
            fixedTitle = <span>Solarstrom<span className={style.disclaimerNumber} onClick={() => openDisclaimer(true)}>ᵈ</span></span>;
            break;
        case "Solar power":
            fixedTitle = <span>Solar power<span className={style.disclaimerNumber} onClick={() => openDisclaimer(true)}>ᵈ</span></span>;
            break;
        case "Energia solare":
            fixedTitle = <span>Energia solare<span className={style.disclaimerNumber} onClick={() => openDisclaimer(true)}>ᵈ</span></span>;
            break;
        case "Électricité solaire":
            fixedTitle = <span>Électricité solaire<span className={style.disclaimerNumber} onClick={() => openDisclaimer(true)}>ᵈ</span></span>;
            break;
        case "Électricité photovoltaïque":
            fixedTitle = <span>Électricité photovoltaïque<span className={style.disclaimerNumber} onClick={() => openDisclaimer(true)}>ᵈ</span></span>;
            break;
        case "Protection contre les surcharges":
            fixedTitle = <span>Protection contre les surcharges<span className={style.disclaimerNumber} onClick={() => openDisclaimer(true)}>⁴</span></span>;
            break;
        case "Zonne-energie":
            fixedTitle = <span>
                Zonne-energie
                <span className={style.disclaimerNumber}
                    style={{
                        position: 'absolute',
                        right: '-20px',
                        top: '-15px'
                    }}
                    onClick={() => openDisclaimer(true)}>⁴</span>
            </span>;
            break;
        //// BLACKOUT
        case "Blackoutschutz":
            fixedTitle = <span>Blackoutschutz<span className={style.disclaimerNumber} onClick={() => openDisclaimer(true)}>ᵈ</span></span>;
            break;
        case "Blackout protection":
            fixedTitle = <span>Blackout protection<span className={style.disclaimerNumber} onClick={() => openDisclaimer(true)}>ᵈ</span></span>;
            break;
        case "Protezione dai blackout":
            fixedTitle = <span>Protezione dai blackout<span className={style.disclaimerNumber} onClick={() => openDisclaimer(true)}>ᵈ</span></span>;
            break;
        case "Protection contre les coupures d’électricité":
            fixedTitle = <span>Protection contre les coupures d’électricité<span className={style.disclaimerNumber} onClick={() => openDisclaimer(true)}>ᵈ</span></span>;
            break;
        case "Bescherming tegen stroomonderbreking":
            fixedTitle = <span>
                Bescherming tegen stroomonderbreking
                <span className={style.disclaimerNumber}
                    style={{
                        display: 'inline',
                        position: 'relative',
                        top: '-15px',
                        fontSize: '30px',
                    }}
                    onClick={() => openDisclaimer(true)}>ᵈ</span>
            </span>;
            break;
        /// KOSTEMOPTIMIERT
        case "Kostenoptimiertes Laden":
            fixedTitle = <span>Kostenoptimiertes Laden<span className={style.disclaimerNumber} onClick={() => openDisclaimer(true)}>ᵇ</span></span>;
            break;
        case "Cost-optimised charging":
            fixedTitle = <span>Cost-optimised charging<span className={style.disclaimerNumber} onClick={() => openDisclaimer(true)}>ᵇ</span></span>;
            break;
        case "Ricarica con costi ottimizzati":
            fixedTitle = <span>Ricarica con costi ottimizzati<span className={style.disclaimerNumber} onClick={() => openDisclaimer(true)}>ᵇ</span></span>;
            break;
        case "Recharge économique":
            fixedTitle = <span>Recharge économique<span className={style.disclaimerNumber} onClick={() => openDisclaimer(true)}>ᵇ</span></span>;
            break;
        case "Charge économique":
            fixedTitle = <span>Charge économique<span className={style.disclaimerNumber} onClick={() => openDisclaimer(true)}>²</span></span>;
            break;
        case "Kostengeoptimaliseerd laden":
            fixedTitle = <span>Kostengeoptimaliseerd laden<span className={style.disclaimerNumber} onClick={() => openDisclaimer(true)}>²</span></span>
    }

    const handleBlackOutSytems = (value: string) => {
        if (blackOutSystem === value) {
            setBlackOutSystem(null);

            if (
                videoBlackoutIn && videoBlackoutIn.current &&
                (title === "Blackoutschutz" || title === "Blackout protection" || title === "Protezione dai blackout" || title === "Protection contre les coupures d’électricité")
            )
                setPlayLoop2(false);
        } else {
            if (videoBlackoutIn && videoBlackoutIn.current &&
                (title === "Blackoutschutz" || title === "Blackout protection" || title === "Protezione dai blackout" || title === "Protection contre les coupures d’électricité")
            ) {
                videoBlackoutIn.current.currentTime = 0;
                videoBlackoutIn.current.play()
            }
            setBlackOutSystem(value);
        }
    }

    return (
        <div className={style.benefitSubPage} style={{ backgroundImage: `url(${fixBackground})` }}>


            {((displayContent === "intro") || (displayContent === "outro")) &&
                <div className={style.videoContainer} >
                    <video autoPlay muted playsInline ref={introOutroVideoRef} />
                </div>
            }

            <div
                className={style.background}
                draggable={false}
                style={{
                    opacity: displayContent === "outro" ? 0 : 1,
                    position: displayContent === "intro" ? 'relative' : 'absolute',
                }}
            >
                {
                    videoLoopUrl ?
                        <video
                            autoPlay
                            muted
                            playsInline
                            className={style.videoloop}
                            src={videoLoopUrl}
                            loop
                            ref={videoLoopRef}
                        />
                        : backgroundImage &&
                        <div className={style.background_imageOverlay}>
                            <img draggable={false} src={backgroundImage} alt={backgroundImage} />
                            {
                                videoOverlay &&
                                <video autoPlay muted playsInline controls={false} src={videoOverlay} loop style={{ opacity: displayContent === "background" ? 1 : 0, }} />
                            }
                        </div>
                }
            </div>

            {
                (
                    title === "Blackoutschutz"
                    || title === "Blackout protection"
                    || title === "Protezione dai blackout"
                    || title === "Protection contre les coupures d’électricité"
                    || title === "Protection contre les surcharges"
                    || title === "Bescherming tegen stroomonderbreking"
                )
                && displayContent === "background" &&
                <div
                    className={style.blackoutVideoContainer}
                    style={{
                        visibility: (
                            title === "Blackoutschutz"
                            || title === "Blackout protection"
                            || title === "Protezione dai blackout"
                            || title === "Protection contre les coupures d’électricité"
                            || title === "Protection contre les surcharges"
                            || title === "Bescherming tegen stroomonderbreking"
                        )
                            && displayContent === "background" ? 'visible' : 'hidden',
                        opacity: (
                            title === "Blackoutschutz"
                            || title === "Blackout protection"
                            || title === "Protezione dai blackout"
                            || title === "Protection contre les coupures d’électricité"
                            || title === "Protection contre les surcharges"
                            || title === "Bescherming tegen stroomonderbreking"
                        )
                            && displayContent === "background" ? 1 : 0,
                    }}
                >
                    <video
                        autoPlay
                        muted
                        playsInline
                        src={"benefitContent/1_Blackoutschutz/OverlayIn_2.mp4"}
                        style={{ display: (blackOutSystem === "hager" || blackOutSystem === "sma") && !playLoop2 ? 'block' : 'none' }}
                        onEnded={() => setPlayLoop2(true)}
                        ref={videoBlackoutIn}
                    />
                    <video
                        autoPlay
                        muted
                        playsInline
                        src={"benefitContent/1_Blackoutschutz/OverlayLoop_2.mp4"}
                        style={{ display: (blackOutSystem === "hager" || blackOutSystem === "sma") && playLoop2 ? 'block' : 'none' }}
                        loop
                    />
                </div>
            }

            <div className={style.content} style={{ opacity: displayContent === "background" || displayContent === "intro" ? 1 : 0 }}>
                <div style={{ pointerEvents: displayContent === "background" || displayContent === "intro" ? 'inherit' : 'none', }} >
                    <div className={style.textContainer} style={{
                        left: textPos.left + "%",
                        top: textPos.top + "%",
                        zIndex: 2,
                        opacity: displayContent === "background" || displayContent === "intro" ? 1 : 0,
                    }}>
                        <h4>{
                            (language === "DE" || language === "CH")
                                ? "Die Vorteile im Detail"
                                : language === "EN"
                                    ? "The benefits in detail"
                                    : language === "IT"
                                        ? "I vantaggi in sintesi"
                                        : language === "FR"
                                        && "Les avantages en détail"
                        }</h4>
                        <h3>{fixedTitle}</h3>
                        {
                            descriptions.map((descr) => (
                                <p key={descr.text[0]}>
                                    {
                                        descr.text.map((txt, txtIndex) => (
                                            <span key={txt}>
                                                <span >{txt}</span>
                                                {descr.disclaimer &&
                                                    <span style={{ cursor: 'pointer' }} onClick={() => openDisclaimer(true)}>{descr.disclaimer[txtIndex] && descr.disclaimer[txtIndex]}</span>
                                                }
                                            </span>
                                        ))
                                    }
                                </p>
                            ))
                        }

                    </div>


                    {
                        (
                            title === "Blackoutschutz"
                            || title === "Blackout protection"
                            || title === "Protezione dai blackout"
                            || title === "Protection contre les surcharges"
                            || title === "Protection contre les coupures d’électricité"
                            || title === "Bescherming tegen stroomonderbreking"
                        ) &&
                        <div className={style.blackOutButton}
                            style={{
                                opacity: displayContent === "background" ? 1 : 0,
                                pointerEvents: displayContent === "background" ? 'all' : 'none'
                            }}>
                            <div className={style.blackOutButton_circle}>
                                <div className={style.blackOutButton_content}>
                                    <p >{blackOutTitle}</p>
                                    <img src={blackOutSystem === "hager" ?
                                        "./images/benefit/hager_device@2x.jpg" :
                                        blackOutSystem === "sma" ?
                                            "./images/benefit/sma_device@2x.jpg" :
                                            "./ico/Elektro-Blitz.svg"
                                    } alt="" />


                                    <p>
                                        {blackOutSystem === null ?
                                            (language === "DE" || language === "CH")
                                                ? "Bitte wählen Sie ein Home Energy Management System"
                                                : language === "EN"
                                                    ? "Please select a home energy management system"
                                                    : language === "IT"
                                                        ? "Selezionare un Home Energy Management System di"
                                                        : language === "FR"
                                                            ? "Veuillez sélectionner un système de gestion de l’énergie domestique de"
                                                            : language === "nl-BE"
                                                                ? ""
                                                                : language === "fr-BE"
                                                                && ""
                                            :
                                            (language === "DE" || language === "CH")
                                                ? "Home Energy Management System von"
                                                : language === "EN"
                                                    ? "Home energy management system from"
                                                    : language === "IT"
                                                        ? "Home Energy Management System di"
                                                        : language === "FR"
                                                            ? "Système de gestion de l’énergie domestique de"
                                                            : language === "nl-BE"
                                                                ? ""
                                                                : language === "fr-BE"
                                                                && ""
                                        }
                                    </p>

                                </div>
                                <div className={style.blackOutButton_buttons} >
                                    {/* <IconButton
                                        disableRipple
                                        onClick={() => { handleBlackOutSytems("hager") }}
                                        style={{ opacity: blackOutSystem === "hager" ? 1 : 0.5 }}
                                        className={style.blackOutButton_button}
                                    >
                                        <img src={"./images/benefit/Image 25@2x.jpg"} alt="" />
                                        {blackOutSystem === null && <img className={style.buttonMarker} src={"./ico/expandLess.png"} alt="indicator" />}
                                    </IconButton> */}
                                    <IconButton
                                        disableRipple
                                        onClick={() => { handleBlackOutSytems("sma") }}
                                        style={{ opacity: blackOutSystem === "sma" ? 1 : 0.5 }}
                                        className={style.blackOutButton_button}
                                    >
                                        <img src={"./images/benefit/SMA_ST_Logo_RGB@2x.jpg"} alt="" />
                                        {blackOutSystem === null && <img className={style.buttonMarker} src={"./ico/expandLess.png"} alt="indicator" />}
                                    </IconButton>
                                </div>
                                {blackOutSystem !== null &&
                                    <span>
                                        <a
                                            href={
                                                blackOutSystem === "hager" ?
                                                    "https://www.audi.de/dam/nemo/e-mobility/pdf/hems-intelligent-laden.pdf" :
                                                    language === "fr-BE" ?
                                                        "https://www.sma-benelux.com/fr.html" :
                                                        language === "nl-BE" ?
                                                            "https://www.sma-benelux.com/" :
                                                            "https://www.audi.de/dam/nemo/e-mobility/pdf/sma-e-mobilitaet.pdf"
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                            className={style.link}
                                        >
                                            {
                                                (language === "DE" || language === "CH")
                                                    ? 'Mehr Informationen >'
                                                    : language === "EN"
                                                        ? 'More information >'
                                                        : language === "IT"
                                                            ? 'Maggiori informazioni >'
                                                            : language === "FR"
                                                                ? "Plus d’informations >"
                                                                : language === "fr-BE"
                                                                    ? "Plus d’infos"
                                                                    : language === "nl-BE"
                                                                    && "Meer info"
                                            }
                                        </a>

                                    </span>
                                }
                            </div>
                        </div>
                    }

                    <div className={style.closeContainer} style={{ opacity: displayContent === "background" || displayContent === "intro" ? 1 : 0, }} >
                        <IconButton disableRipple onClick={() => {
                            setCurrVideo("outro");
                            setDisplayContent("outro");
                        }}>
                            <span>
                                <img src={process.env.PUBLIC_URL + "/ico/cancel-large.svg"} alt="close" />
                            </span>
                        </IconButton>
                    </div>
                </div>
                <div
                    className={
                        fadePosition === "left" ?
                            style.fadeLeftSide :
                            fadePosition === "right" ?
                                style.fadeRightSide :
                                fadePosition === "top" ?
                                    style.fadeTopSide :
                                    fadePosition === "bottom" ?
                                        style.fadeBottomSide :
                                        ""
                    }
                />
            </ div>


        </div >
    );
}

export default React.memo(BenefitSubPage);