import { withStyles, Theme, createStyles, Tab, Tabs } from "@material-ui/core";
import React from "react";

interface AudiTabsProps {
    value: number;
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
    className?: string,
}

export const AudiTabs = withStyles({
    indicator: {
        backgroundColor: 'white',
        transition: 'all 0.2s ease-in-out 0 ',
        height: '3px',
    },

})((props: AudiTabsProps) => <Tabs {...props} />);

export const AudiTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'none',
            color: '#fff',
            '&:focus': {
                opacity: 1,
            },
            fontFamily: [
                'AudiTypeExtended',
                'Verdana',
                'Geneva',
                'sans-serif',
            ].join(','),
            fontSize: '1.5vw',
            padding: 0,
            paddingBottom:'5px',
        },
    }),
)(Tab);