import { IconButton } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { DisclaimerDataProps } from '../../../datastructure/disclaimerData';
import style from './disclaimer.module.scss';

export interface DisclaimerProps {
    data: DisclaimerDataProps[];
    open: boolean;
    onClose: (open: boolean) => void;
}

function Disclaimer({ data, open, onClose }: DisclaimerProps) {
    const [disclaimerDisplay, setDisclaimerDisplay] = useState<boolean>(false);

    useEffect(() => {
        
        
            setTimeout(() => {
                setDisclaimerDisplay(open);
            }, 550)
        
    }, [open])

    return (
        <div className={open ? style.disclaimer : style.disclaimer_close} style={{ visibility: (disclaimerDisplay || open) ? 'visible' : 'hidden' }} >
            <div className={style.content}>
                {
                    data.map((text, index) => (
                        <p key={text.number + "_" + index}>{text.descr}</p>
                    ))
                }
            </div>
            <IconButton disableRipple className={style.closeButton} onClick={() => onClose(false)}>
                <img src="./ico/system-cancel-large.svg" alt="close" />
            </IconButton>
        </div>
    );
}

export default Disclaimer;
