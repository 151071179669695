import { DisclaimerProps } from "./benefitData";


export interface StepContentProps {
    title: string;
    icon: string;
    subSteps: Array<SubStepContentProps>;
}

export interface SubStepContentProps {
    title: string;
    disclaimer?: string;
    bulletPoints?: string[];
    finished?: boolean
    bulletDisclaimer?: BulletDisclaimerProps[];
    hints?: string[];
}

export interface BulletDisclaimerProps {
    number: string;
    position: number;
}

export const howToData_DE: Array<StepContentProps> = [
    {
        title: "Anlage myAudi Account",
        icon: process.env.PUBLIC_URL + '/ico/user-small.svg',
        subSteps: [
            {
                title: "",
                bulletPoints: [],
            },
            {
                title: "1. myAudi Registrierungsseite*",
                bulletPoints: [
                    "Sie können sich unter folgendem Link registrieren:",
                    "https://login.audi.com/registration",
                    "Aktivieren Sie nun die automatischen Software-Updates (Empfehlung)."
                ],
                hints: [
                    "Sie können die Schritte alternativ auch über ein Smartphone durchführen."
                ],
            },
            {
                title: "2. Bestätigungs-E-Mail*",
                bulletPoints: [
                    'Öffnen Sie die Ihnen zugesandte Bestätigungs-E-Mail und klicken Sie auf "Registrierung im Browser abschließen". ',
                    "Die Registrierung ist nun abgeschlossen."
                ],
                hints: [
                    "Sie können die Schritte alternativ auch über ein Smartphone durchführen."
                ],
            },
            {
                title: "3. Fahrzeuganlage in myAudi*",
                bulletPoints: [
                    'Nach abgeschlossener Registrierung und initialer Anmeldung auf myAudi erscheint eine Aufforderung zur Fahrzeuganlage.',
                    'Klicken Sie auf "Fahrzeug anlegen"'
                ],
                hints: [
                    "Sie können die Schritte alternativ auch über ein Smartphone durchführen."
                ],
            },
            {
                title: "4. Eingabe Fahrzeug-Identifizierungsnummer (FIN)*",
                bulletPoints: [
                    'Geben Sie nun die FIN Ihres Fahrzeugs ein.',
                    "Die FIN befindet sich auf der Fahrerseite in der unteren Ecke der Windschutzscheibe oder im Fahrzeugschein.",
                    'Klicken Sie danach auf "Fahrzeug anlegen"'
                ],
                hints: [
                    "Sie können die Schritte alternativ auch über ein Smartphone durchführen."
                ],
            },
            {
                title: "5. myAudi Account-Verifizierung*",
                bulletPoints: [
                    'Nach erfolgreicher Fahrzeuganlage öffnet sich eine Seite zur Account-Verifizierung.',
                    "Zunächst müssen Sie zur Verifizierung verschiedene persönliche Daten eingegeben.",
                    'Legen Sie anschließend eine beliebige 4-stellige PIN fest. Diese wird zur Autorisierung diverser Aktionen benötigt (z.B. Fahrzeug per App ver- und entriegeln).'
                ],
                hints: [
                    "Sie können die Schritte alternativ auch über ein Smartphone durchführen."
                ],
            },
            {
                title: "6. Auswahl der Verifizierungsmethode*",
                bulletPoints: [
                    'Im Nächsten Schritt können Sie über eine Drop-Down-Auswahl die gewünschte Art der Verifizierung festlegen.',
                    'Beispiel: Mobilfunk-Verifizierung Bitte geben Sie Ihre Mobilfunknummer an und klicken Sie auf "Verifizieren". Geben Sie anschließend die Ihnen per SMS zugesandte 6-stellige Nummer (mTan) in myAudi ein.'
                ],
                hints: [
                    "Sie können die Schritte alternativ auch über ein Smartphone durchführen."
                ],
            },
            {
                title: "Ihr myAudi Account ist nun bereit!",
                finished: true, 
            },
        ]
    },
    {
        title: "Konfiguration e-tron Ladesystem connect",
        icon: process.env.PUBLIC_URL + "/ico/administrator-small.svg",
        subSteps: [
            {
                title: "1. Grundeinstellungen",
                bulletPoints: [
                    "Beim ersten Einschalten des optionalen e-tron Ladesystems connect werden Sie automatisch durch verschiedene Schritte der initialen Konfiguration geführt (z.B. Auswahl Sprache; Land).*",
                    "Lesen und Bestätigen Sie die Datenschutzhinweise. Tippen** Sie dafür auf das nach unten zeigende Pfeilsymbol bis Sie am Ende des Textes angekommen sind.",
                    "Aktivieren Sie nun die automatischen Software-Updates (Empfehlung)."
                ],
                hints: [
                    "Alle Einstellungen der initialen Konfiguration können auch im Nachhinein im Einstellungsmenü konfiguriert und geändert werden.",
                    "Der Touchscreen reagiert lediglich auf Druckgesten und nicht auf Swipen.",
                ],
            },
            {
                title: "2. Verbindung mit dem häuslichen Internet*",
                bulletPoints: [
                    "Im nächsten Schritt werden Sie aufgefordert, das optionale e-tron Ladesystem connect mit Ihrem häuslichen Internet zu koppeln.",
                    "Das e-tron Ladesystem connect sucht automatisch nach WLAN Verbindungen. Wählen Sie Ihre WLAN Verbindung aus und geben Sie Ihr WLAN Passwort ein.",
                    "Alternative: Sie können das e-tron Ladesystem connect ohne weiteren Adapter in ein vorhandenes Powerline Heimnetzwerk (PLC) einbinden."
                ],
                hints: [
                    "Bitte stellen Sie sicher, dass Ihr e-tron Ladesystem connect mit dem Internet verbunden ist, um alle Funktionsumfänge nutzen zu können.",
                ],
            },
            {
                title: "3. Verbindung mit myAudi",
                bulletPoints: [
                    "Stellen Sie nun eine Verbindung zu myAudi her, indem Sie die Zugangsdaten Ihres myAudi Accounts eingeben (E-Mail-Adresse; Passwort)."
                ],
                hints: [
                    "Ihr persönlicher myAudi Account ist notwendig für die Anzeige des e-tron Ladesystems connect und die Dokumentation der geladenen Energie in myAudi, sowie für die Übertragung des Stromtarifs aus myAudi an das Ladesystem connect. Stromtarife können alternativ auch über ein kompatibles Home Energy Management System an das e-tron Ladesystem connect übertragen werden.",
                ],
            },
            {
                title: "4. Verbindung mit HEMS (optional)",
                disclaimer: "ᵈ",
                bulletPoints: [
                    'Führen Sie diesen Schritt nur durch, sofern Sie über ein kompatibles Home Energy Management System (HEMS)* verfügen. Andernfalls klicken Sie auf "Überspringen".',
                    "Bitte überprüfen Sie in der Betriebsanleitung Ihres HEMS, welche Einstellungen Sie an Ihrem HEMS vornehmen müssen, damit die Verbindung mit dem optionalen e-tron Ladesystem connect hergestellt werden kann.",
                    "Im Falle von Rückfragen wenden sie sich bitte an Ihren Elektriker."
                ],
                hints: [
                    "Um eine volle Kompatibilität gewährleisten zu können arbeitet die AUDI AG mit führenden Anbietern von Home Enery Management Systemen – ",
                ],
            },
            {
                title: "5. HEMS Verbindung abschließen (optional)",
                disclaimer: "ᵈ",
                bulletPoints: [
                    "Nachdem Sie die Einstellungen an Ihrem HEMS vorgenommen haben, wird es auf dem optionalen e-tron Ladesystem connect angezeigt.*",
                    'Klicken Sie auf Ihr HEMS und auf der nachfolgenden Seite auf "Bestätigen".',
                    "Ihre HEMS Verbindung ist nun angeschlossen.",
                    'Die HEMS Kopplung ist für die Funktionen Solarstromnutzung und Blackoutschutz notwendig.',
                    'Auch ohne HEMS können Sie den max. Ladestrom Ihres Ladesystems im Einstellungsmenü des e-tron Ladesystems connect dauerhaft begrenzen.'
                ],
                bulletDisclaimer: [
                    {
                        number: "ᵃ",
                        position: 4
                    },
                ],
                hints: [
                    "Bei Problemen mit der Verbindungsherstellung zu Ihrem HEMS kontaktieren Sie bitte Ihren Audi Partner.",
                ],
            },
            {
                title: "6. PIN-Vergabe",
                bulletPoints: [
                    "Anschließend werden Sie aufgefordert eine PIN festzulegen. Mit dieser schützen Sie das optionale e-tron Ladesystem connect vor unbefugter Nutzung.*",
                    "Sollte die PIN mehrfach falsch eingegeben werden, wird zur Entsperrung der PUK benötigt. Sie finden den PUK in einem Brief, der mit dem e-tron Ladesystem connect mitgeliefert wird."
                ],
                hints: [
                    "Bei Nichtnutzung des e-tron Ladesystems connect aktiviert sich nach kurzer Zeit die Bildschirmsperre. Klicken Sie zum Entsperren ca. zwei Sekunden auf das Schloss-Icon",
                ],
            },
            {
                title: "Ihr e-tron Ladesystem connect ist nun bereit!",
                finished: true
            },
        ]
    },
    {
        title: "Hinterlegen Ihres Stromtarifs in myAudi",
        icon: process.env.PUBLIC_URL + "/ico/documents-small.svg",
        subSteps: [
            {
                title: "1. Eingabe der Struktur Ihres Stromtarifs*",
                bulletPoints: [
                    'Öffnen Sie myAudi und wählen Sie Ihr optionales e-tron Ladesystem connect im Menüpunkt "Kabel & Geräte" aus. Sie gelangen daraufhin auf die Detailinformationsseite des e-tron Ladesystems connect.',
                    'Scrollen Sie nun nach unten zum Bereich "Kostenoptimierung" und klicken Sie auf "Stromtarifdaten hinterlegen".',
                    'Nun werden Ihnen verschiedene Fragen zur Struktur Ihres Stromtarifs gestellt. Bitte beantworten Sie diese.'
                ],
                hints: [
                    "Führen Sie die Eingabe der Stromtarifdaten am besten über einen PC/Laptop durch.",
                ],
            },
            {
                title: "2. Eingabe der Preise Ihres Stromtarifs*",
                bulletPoints: [
                    'Nun können Sie noch die Preise Ihres Stromtarifs hinterlegen. Entnehmen Sie diese Ihrem Stromvertrag.**',
                    'Sollte Ihr Stromtarif tageszeitabhängige Preisschwankungen beinhalten, können Sie diese hier zusätzlich hinzufügen.'
                ],
                hints: [
                    "Führen Sie die Eingabe der Stromtarifdaten am besten über einen PC/Laptop durch.",
                    "Bitte aktualisieren Sie Ihre Eingaben, sofern es zu Veränderungen Ihres Stromtarifs kommt.",
                ],
            },
            {
                title: "Ihr Stromtarif ist nun hinterlegt und wird zur Ladeoptimierung verwendet.*",
                finished: true,
                hints: [
                    "Die Tarifdaten aus myAudi werden nur dann zur Ladeoptimierung verwendet, wenn keine Preisinformationen aus dem HEMS System vorliegen und Sie einen Abfahrtstimer aktiviert haben.",
                ],
            },
        ]
    },
    {
        title: "Einstellungen im Fahrzeug",
        icon: process.env.PUBLIC_URL + "/ico/car-small.svg",
        subSteps: [
            {
                title: "1. Festlegen des Fahrzeug-Hauptnutzers",
                bulletPoints: [
                    'Gehen Sie im Multi-Media-Interface (MMI) Ihres Fahrzeugs in den Bereich "Hauptnutzer festlegen" ("Nutzer" > "Nutzerverwaltung" > "Hauptnutzer festlegen").',
                    'Geben Sie in der oberen Zeile die E-Mail-Adresse ein, mit der Sie sich auf myAudi registriert haben.',
                    'Schließen Sie die Hauptnutzer-Festlegung, durch Eingabe Ihres Fahrzeugcodes in der unteren Zeile ab (zu finden am Schlüsselanhänger Ihres Fahrzeugschlüssels: siehe links).'
                ]
            },
            {
                title: "2. Verbindung des Fahrzeugs mit myAudi",
                bulletPoints: [
                    'Verbinden Sie nun Ihr Fahrzeug mit myAudi. Gehen Sie dafür im MMI Ihres Fahrzeugs in den Bereich "myAudi Login" ("Nutzer" > "myAudi Login").',
                    'Geben Sie hier Ihre myAudi Zugangsdaten ein (obere Zeile: E-Mail-Adresse; untere Zeile: Passwort).'
                ],
            },
            {
                title: "3. Abfahrtstimer konfigurieren",
                bulletPoints: [
                    'Voraussetzung für die Optimierung des Ladeprozesses ist ein aktivierter Abfahrtstimer.*',
                    'Den Abfahrtstimer können Sie im Menüpunkt "Laden" konfigurieren ("Fahrzeug" > "Laden & Effizienz" > "Laden"). Klicken Sie auf einen der vorkonfigurierten Abfahrtstimer und individualisieren Sie diesen nach Ihren Bedürfnissen.'
                ],
                hints: [
                    "Ohne einen aktivierten Abfahrtstimer wird Ihr Fahrzeug sofort und schnellstmöglich mit der maximal zur Verfügung stehenden Leistung aufgeladenᵃ (keine Optimierung des Ladeprozesses). Die Funktion Blackoutschutz ist unabhängig von einem aktivierten Abfahrtstimer; sie setzt eine Kopplung des optionalen e-tron Ladesystems connect mit einem kompatiblen HEMSᵈ voraus",
                ],
            },
            {
                title: "4. Abfahrtstimer aktivieren",
                bulletPoints: [
                    'Wenn Sie die Zündung Ihres Fahrzeugs ausschalten, erscheint ein Fenster, über das Sie einen Abfahrtstimer aktivieren können. Klicken Sie in diesem auf "Laden".',
                    'Ihnen werden nun Ihre Abfahrtstimer angezeigt. Wählen Sie den von Ihnen gewünschten Abfahrtstimer aus und aktivieren Sie ihn durch einen Klick auf das Uhr-Symbol über der Abfahrtstimer-Einstellung.',
                    'Wiederholen Sie diesen Vorgang jedes Mal, wenn Sie den Abfahrtstimer nutzen wollen.*'
                ],
                hints: [
                    "Ein aktivierter Abfahrtstimer erlischt automatisch nach Abstecken des Ladekabels.",
                ],
            },
            {
                title: "Ihr Ladevorgang wird nun automatisch entsprechend Ihrer Einstellungen durchgeführt.",
                finished: true,
            },
        ]
    },
    {
        title: "Ladeübersicht in myAudi",
        icon: process.env.PUBLIC_URL + "/ico/polls-small.svg",
        subSteps: [
            {
                title: "1. Übersicht des aktuellen Ladeverlaufs*",
                bulletPoints: [
                    'Öffnen Sie myAudi und wählen Sie Ihr optionales e-tron Ladesystem connect im Menüpunkt "Kabel & Geräte" aus. Sie gelangen daraufhin auf die Detailinformationsseite des e-tron Ladesystems connect.',
                    'Während eines aktiven Ladevorgangs wird Ihnen dort Ihr Echtzeit-Ladeverlauf angezeigt (sollten Sie aktuell nicht laden, wird der vergangene Ladeverlauf angezeigt).'
                ],
                hints: [
                    "Sie können die Schritte alternativ auch über ein Smartphone durchführen.",
                ],
            },
            {
                title: "2. Übersicht der Ladehistorie/Ladestatistik*",
                bulletPoints: [
                    'Wenn Sie auf der Detailinformationsseite nach unten scrollen sehen Sie Ihre Ladehistorie.',
                    'Dort wird Ihnen eine Übersicht des aktuellen und des vergangenen Monats angezeigt. Für weitere Details zu den Ladevorgängen klicken Sie auf "Zur Ladehistorie".'
                ],
                hints: [
                    "Sie können die Schritte alternativ auch über ein Smartphone durchführen.",
                ],
            },
            {
                title: "3. Export der Ladehistorie/Ladestatistik als PDF*",
                bulletPoints: [
                    'Im Bereich "Zur Ladehistorie" befinden sich drei Auswahlmöglichkeiten: "Lademenge"; "Ladedauer"; "Liste".',
                    'Unter "Liste" können Sie die Ladehistorie exportieren. Klicken Sie dazu auf "Auswahl exportieren".',
                    'Die Ladehistorie wird daraufhin als PDF angezeigt und kann abgespeichert werden.'
                ],
                hints: [
                    "Der Export der Ladehistorie/Ladestatistik kann nur über das myAudi Portal und nicht über die App durchgeführt werden.",
                ],
            },
            {
                title: "Erstbetriebnahme erfolgreich abgeschlossen.",
                finished: true,
            },
        ]
    }
];

export const howToData_CH_DE: Array<StepContentProps> = [
    {
        title: "Anlage myAudi Account",
        icon: process.env.PUBLIC_URL + '/ico/user-small.svg',
        subSteps: [
            {
                title: "",
                bulletPoints: [],
            },
            {
                title: "1. myAudi Registrierungsseite*",
                bulletPoints: [
                    "Sie können sich unter folgendem Link registrieren:",
                    "https://login.audi.com/registration",
                    "Aktivieren Sie nun die automatischen Software-Updates (Empfehlung)."
                ],
                hints: [
                    "Sie können die Schritte alternativ auch über ein Smartphone durchführen."
                ],
            },
            {
                title: "2. Bestätigungs-E-Mail*",
                bulletPoints: [
                    'Öffnen Sie die Ihnen zugesandte Bestätigungs-E-Mail und klicken Sie auf "Registrierung im Browser abschließen". ',
                    "Die Registrierung ist nun abgeschlossen."
                ],
                hints: [
                    "Sie können die Schritte alternativ auch über ein Smartphone durchführen."
                ],
            },
            {
                title: "3. Fahrzeuganlage in myAudi*",
                bulletPoints: [
                    'Nach abgeschlossener Registrierung und initialer Anmeldung auf myAudi erscheint eine Aufforderung zur Fahrzeuganlage.',
                    'Klicken Sie auf "Fahrzeug anlegen"'
                ],
                hints: [
                    "Sie können die Schritte alternativ auch über ein Smartphone durchführen."
                ],
            },
            {
                title: "4. Eingabe Fahrzeug-Identifizierungsnummer (FIN)*",
                bulletPoints: [
                    'Geben Sie nun die FIN Ihres Fahrzeugs ein.',
                    "Die FIN befindet sich auf der Fahrerseite in der unteren Ecke der Windschutzscheibe oder im Fahrzeugschein.",
                    'Klicken Sie danach auf "Fahrzeug anlegen"'
                ],
                hints: [
                    "Sie können die Schritte alternativ auch über ein Smartphone durchführen."
                ],
            },
            {
                title: "5. myAudi Account-Verifizierung*",
                bulletPoints: [
                    'Nach erfolgreicher Fahrzeuganlage öffnet sich eine Seite zur Account-Verifizierung.',
                    "Zunächst müssen Sie zur Verifizierung verschiedene persönliche Daten eingegeben.",
                    'Legen Sie anschließend eine beliebige 4-stellige PIN fest. Diese wird zur Autorisierung diverser Aktionen benötigt (z.B. Fahrzeug per App ver- und entriegeln).'
                ],
                hints: [
                    "Sie können die Schritte alternativ auch über ein Smartphone durchführen."
                ],
            },
            {
                title: "6. Auswahl der Verifizierungsmethode*",
                bulletPoints: [
                    'Im Nächsten Schritt können Sie über eine Drop-Down-Auswahl die gewünschte Art der Verifizierung festlegen.',
                    'Beispiel: Mobilfunk-Verifizierung Bitte geben Sie Ihre Mobilfunknummer an und klicken Sie auf "Verifizieren". Geben Sie anschließend die Ihnen per SMS zugesandte 6-stellige Nummer (mTan) in myAudi ein.'
                ],
                hints: [
                    "Sie können die Schritte alternativ auch über ein Smartphone durchführen."
                ],
            },
            {
                title: "Ihr myAudi Account ist nun bereit!",
                finished: true, 
            },
        ]
    },
    {
        title: "Konfiguration e-tron Ladesystem connect",
        icon: process.env.PUBLIC_URL + "/ico/administrator-small.svg",
        subSteps: [
            {
                title: "1. Grundeinstellungen",
                bulletPoints: [
                    "Beim ersten Einschalten des optionalen e-tron Ladesystems connect werden Sie automatisch durch verschiedene Schritte der initialen Konfiguration geführt (z.B. Auswahl Sprache; Land).*",
                    "Lesen und Bestätigen Sie die Datenschutzhinweise. Tippen** Sie dafür auf das nach unten zeigende Pfeilsymbol bis Sie am Ende des Textes angekommen sind.",
                    "Aktivieren Sie nun die automatischen Software-Updates (Empfehlung)."
                ],
                hints: [
                    "Alle Einstellungen der initialen Konfiguration können auch im Nachhinein im Einstellungsmenü konfiguriert und geändert werden.",
                    "Der Touchscreen reagiert lediglich auf Druckgesten und nicht auf Swipen.",
                ],
            },
            {
                title: "2. Verbindung mit dem häuslichen Internet*",
                bulletPoints: [
                    "Im nächsten Schritt werden Sie aufgefordert, das optionale e-tron Ladesystem connect mit Ihrem häuslichen Internet zu koppeln.",
                    "Das e-tron Ladesystem connect sucht automatisch nach WLAN Verbindungen. Wählen Sie Ihre WLAN Verbindung aus und geben Sie Ihr WLAN Passwort ein.",
                    "Alternative: Sie können das e-tron Ladesystem connect ohne weiteren Adapter in ein vorhandenes Powerline Heimnetzwerk (PLC) einbinden."
                ],
                hints: [
                    "Bitte stellen Sie sicher, dass Ihr e-tron Ladesystem connect mit dem Internet verbunden ist, um alle Funktionsumfänge nutzen zu können.",
                ],
            },
            {
                title: "3. Verbindung mit myAudi",
                bulletPoints: [
                    "Stellen Sie nun eine Verbindung zu myAudi her, indem Sie die Zugangsdaten Ihres myAudi Accounts eingeben (E-Mail-Adresse; Passwort)."
                ],
                hints: [
                    "Ihr persönlicher myAudi Account ist notwendig für die Anzeige des e-tron Ladesystems connect und die Dokumentation der geladenen Energie in myAudi, sowie für die Übertragung des Stromtarifs aus myAudi an das Ladesystem connect. Stromtarife können alternativ auch über ein kompatibles Home Energy Management System an das e-tron Ladesystem connect übertragen werden.",
                ],
            },
            {
                title: "4. Verbindung mit HEMS (optional)",
                disclaimer: "ᵈ",
                bulletPoints: [
                    'Führen Sie diesen Schritt nur durch, sofern Sie über ein kompatibles Home Energy Management System (HEMS)* verfügen. Andernfalls klicken Sie auf "Überspringen".',
                    "Bitte überprüfen Sie in der Betriebsanleitung Ihres HEMS, welche Einstellungen Sie an Ihrem HEMS vornehmen müssen, damit die Verbindung mit dem optionalen e-tron Ladesystem connect hergestellt werden kann.",
                    "Im Falle von Rückfragen wenden sie sich bitte an Ihren Elektriker."
                ],
                hints: [
                    "Um eine volle Kompatibilität gewährleisten zu können arbeitet die AUDI AG mit führenden Anbietern von Home Enery Management Systemen – ",
                ],
            },
            {
                title: "5. HEMS Verbindung abschließen (optional)",
                disclaimer: "ᵈ",
                bulletPoints: [
                    "Nachdem Sie die Einstellungen an Ihrem HEMS vorgenommen haben, wird es auf dem optionalen e-tron Ladesystem connect angezeigt.*",
                    'Klicken Sie auf Ihr HEMS und auf der nachfolgenden Seite auf "Bestätigen".',
                    "Ihre HEMS Verbindung ist nun angeschlossen.",
                    'Die HEMS Kopplung ist für die Funktionen Solarstromnutzung und Blackoutschutz notwendig.',
                    'Auch ohne HEMS können Sie den max. Ladestrom Ihres Ladesystems im Einstellungsmenü des e-tron Ladesystems connect dauerhaft begrenzen.'
                ],
                bulletDisclaimer: [
                    {
                        number: "ᵃ",
                        position: 4
                    },
                ],
                hints: [
                    "Bei Problemen mit der Verbindungsherstellung zu Ihrem HEMS kontaktieren Sie bitte Ihren Audi Partner.",
                ],
            },
            {
                title: "6. PIN-Vergabe",
                bulletPoints: [
                    "Anschließend werden Sie aufgefordert eine PIN festzulegen. Mit dieser schützen Sie das optionale e-tron Ladesystem connect vor unbefugter Nutzung.*",
                    "Sollte die PIN mehrfach falsch eingegeben werden, wird zur Entsperrung der PUK benötigt. Sie finden den PUK in einem Brief, der mit dem e-tron Ladesystem connect mitgeliefert wird."
                ],
                hints: [
                    "Bei Nichtnutzung des e-tron Ladesystems connect aktiviert sich nach kurzer Zeit die Bildschirmsperre. Klicken Sie zum Entsperren ca. zwei Sekunden auf das Schloss-Icon",
                ],
            },
            {
                title: "Ihr e-tron Ladesystem connect ist nun bereit!",
                finished: true
            },
        ]
    },
    {
        title: "Hinterlegen Ihres Stromtarifs in myAudi",
        icon: process.env.PUBLIC_URL + "/ico/documents-small.svg",
        subSteps: [
            {
                title: "1. Eingabe der Struktur Ihres Stromtarifs*",
                bulletPoints: [
                    'Öffnen Sie myAudi und wählen Sie Ihr optionales e-tron Ladesystem connect im Menüpunkt "Kabel & Geräte" aus. Sie gelangen daraufhin auf die Detailinformationsseite des e-tron Ladesystems connect.',
                    'Scrollen Sie nun nach unten zum Bereich "Kostenoptimierung" und klicken Sie auf "Stromtarifdaten hinterlegen".',
                    'Nun werden Ihnen verschiedene Fragen zur Struktur Ihres Stromtarifs gestellt. Bitte beantworten Sie diese.'
                ],
                hints: [
                    "Führen Sie die Eingabe der Stromtarifdaten am besten über einen PC/Laptop durch.",
                ],
            },
            {
                title: "2. Eingabe der Preise Ihres Stromtarifs*",
                bulletPoints: [
                    'Nun können Sie noch die Preise Ihres Stromtarifs hinterlegen. Entnehmen Sie diese Ihrem Stromvertrag.**',
                    'Sollte Ihr Stromtarif tageszeitabhängige Preisschwankungen beinhalten, können Sie diese hier zusätzlich hinzufügen.'
                ],
                hints: [
                    "Führen Sie die Eingabe der Stromtarifdaten am besten über einen PC/Laptop durch.",
                    "Bitte aktualisieren Sie Ihre Eingaben, sofern es zu Veränderungen Ihres Stromtarifs kommt.",
                ],
            },
            {
                title: "Ihr Stromtarif ist nun hinterlegt und wird zur Ladeoptimierung verwendet.*",
                finished: true,
                hints: [
                    "Die Tarifdaten aus myAudi werden nur dann zur Ladeoptimierung verwendet, wenn keine Preisinformationen aus dem HEMS System vorliegen und Sie einen Abfahrtstimer aktiviert haben.",
                ],
            },
        ]
    },
    {
        title: "Einstellungen im Fahrzeug",
        icon: process.env.PUBLIC_URL + "/ico/car-small.svg",
        subSteps: [
            {
                title: "1. Festlegen des Fahrzeug-Hauptnutzers",
                bulletPoints: [
                    'Gehen Sie im Multi-Media-Interface (MMI) Ihres Fahrzeugs in den Bereich "Hauptnutzer festlegen" ("Nutzer" > "Nutzerverwaltung" > "Hauptnutzer festlegen").',
                    'Geben Sie in der oberen Zeile die E-Mail-Adresse ein, mit der Sie sich auf myAudi registriert haben.',
                    'Schließen Sie die Hauptnutzer-Festlegung, durch Eingabe Ihres Fahrzeugcodes in der unteren Zeile ab (zu finden am Schlüsselanhänger Ihres Fahrzeugschlüssels: siehe links).'
                ]
            },
            {
                title: "2. Verbindung des Fahrzeugs mit myAudi",
                bulletPoints: [
                    'Verbinden Sie nun Ihr Fahrzeug mit myAudi. Gehen Sie dafür im MMI Ihres Fahrzeugs in den Bereich "myAudi Login" ("Nutzer" > "myAudi Login").',
                    'Geben Sie hier Ihre myAudi Zugangsdaten ein (obere Zeile: E-Mail-Adresse; untere Zeile: Passwort).'
                ],
            },
            {
                title: "3. Abfahrtstimer konfigurieren",
                bulletPoints: [
                    'Voraussetzung für die Optimierung des Ladeprozesses ist ein aktivierter Abfahrtstimer.*',
                    'Den Abfahrtstimer können Sie im Menüpunkt "Laden" konfigurieren ("Fahrzeug" > "Laden & Effizienz" > "Laden"). Klicken Sie auf einen der vorkonfigurierten Abfahrtstimer und individualisieren Sie diesen nach Ihren Bedürfnissen.'
                ],
                hints: [
                    "Ohne einen aktivierten Abfahrtstimer wird Ihr Fahrzeug sofort und schnellstmöglich mit der maximal zur Verfügung stehenden Leistung aufgeladenᵃ (keine Optimierung des Ladeprozesses). Die Funktion Blackoutschutz ist unabhängig von einem aktivierten Abfahrtstimer; sie setzt eine Kopplung des optionalen e-tron Ladesystems connect mit einem kompatiblen HEMSᵈ voraus",
                ],
            },
            {
                title: "4. Abfahrtstimer aktivieren",
                bulletPoints: [
                    'Wenn Sie die Zündung Ihres Fahrzeugs ausschalten, erscheint ein Fenster, über das Sie einen Abfahrtstimer aktivieren können. Klicken Sie in diesem auf "Laden".',
                    'Ihnen werden nun Ihre Abfahrtstimer angezeigt. Wählen Sie den von Ihnen gewünschten Abfahrtstimer aus und aktivieren Sie ihn durch einen Klick auf das Uhr-Symbol über der Abfahrtstimer-Einstellung.',
                    'Wiederholen Sie diesen Vorgang jedes Mal, wenn Sie den Abfahrtstimer nutzen wollen.*'
                ],
                hints: [
                    "Ein aktivierter Abfahrtstimer erlischt automatisch nach Abstecken des Ladekabels.",
                ],
            },
            {
                title: "Ihr Ladevorgang wird nun automatisch entsprechend Ihrer Einstellungen durchgeführt.",
                finished: true,
            },
        ]
    },
    {
        title: "Ladeübersicht in myAudi",
        icon: process.env.PUBLIC_URL + "/ico/polls-small.svg",
        subSteps: [
            {
                title: "1. Übersicht des aktuellen Ladeverlaufs*",
                bulletPoints: [
                    'Öffnen Sie myAudi und wählen Sie Ihr optionales e-tron Ladesystem connect im Menüpunkt "Kabel & Geräte" aus. Sie gelangen daraufhin auf die Detailinformationsseite des e-tron Ladesystems connect.',
                    'Während eines aktiven Ladevorgangs wird Ihnen dort Ihr Echtzeit-Ladeverlauf angezeigt (sollten Sie aktuell nicht laden, wird der vergangene Ladeverlauf angezeigt).'
                ],
                hints: [
                    "Sie können die Schritte alternativ auch über ein Smartphone durchführen.",
                ],
            },
            {
                title: "2. Übersicht der Ladehistorie/Ladestatistik*",
                bulletPoints: [
                    'Wenn Sie auf der Detailinformationsseite nach unten scrollen sehen Sie Ihre Ladehistorie.',
                    'Dort wird Ihnen eine Übersicht des aktuellen und des vergangenen Monats angezeigt. Für weitere Details zu den Ladevorgängen klicken Sie auf "Zur Ladehistorie".'
                ],
                hints: [
                    "Sie können die Schritte alternativ auch über ein Smartphone durchführen.",
                ],
            },
            {
                title: "3. Export der Ladehistorie/Ladestatistik als PDF*",
                bulletPoints: [
                    'Im Bereich "Zur Ladehistorie" befinden sich drei Auswahlmöglichkeiten: "Lademenge"; "Ladedauer"; "Liste".',
                    'Unter "Liste" können Sie die Ladehistorie exportieren. Klicken Sie dazu auf "Auswahl exportieren".',
                    'Die Ladehistorie wird daraufhin als PDF angezeigt und kann abgespeichert werden.'
                ],
                hints: [
                    "Der Export der Ladehistorie/Ladestatistik kann nur über das myAudi Portal und nicht über die App durchgeführt werden.",
                ],
            },
            {
                title: "Erstbetriebnahme erfolgreich abgeschlossen.",
                finished: true,
            },
        ]
    }
];

export const howToData_EN: Array<StepContentProps> = [
    {
        title: "Create a myAudi Account",
        icon: process.env.PUBLIC_URL + '/ico/user-small.svg',
        subSteps: [
            {
                title: "",
                bulletPoints: [],
            },
            {
                title: "1. myAudi registration page*",
                bulletPoints: [
                    "You can register at the following link:",
                    "https://login.audi.com/registration",
                    "Now activate automatic software updates (recommended)."
                ],
                hints: [
                    "Alternatively, you can complete these steps on your smartphone."
                ],
            },
            {
                title: "2. Confirmation e-mail*",
                bulletPoints: [
                    'Open the confirmation e-mail you have received and click on “Complete registration in the browser”. ',
                    "Registration is now complete."
                ],
                hints: [
                    "Alternatively, you can complete these steps on your smartphone."
                ],
            },
            {
                title: "3. Add a vehicle in myAudi*",
                bulletPoints: [
                    'After you have completed registration and logged on to myAudi for the first time, you will be prompted to add a vehicle.',
                    'Click on “Add vehicle”.'
                ],
                hints: [
                    "Alternatively, you can complete these steps on your smartphone."
                ],
            },
            {
                title: "4. Enter the vehicle identification number (VIN)*",
                bulletPoints: [
                    'Now enter the VIN of your vehicle.',
                    "The VIN is located on the driver’s side in the bottom corner of the windscreen or in the vehicle registration document.",
                    'Then click on “Add vehicle”.'
                ],
                hints: [
                    "Alternatively, you can complete these steps on your smartphone."
                ],
            },
            {
                title: "5. myAudi account verification*",
                bulletPoints: [
                    'After you have successfully added a vehicle, a page opens for account verification.',
                    "Start by entering various personal details for verification purposes.",
                    'Then create a 4-digit PIN of your choice. This is required to authorise various actions (e.g. locking and unlocking your vehicle through the app).'
                ],
                hints: [
                    "Alternatively, you can complete these steps on your smartphone."
                ],
            },
            {
                title: "6. Select the verification method*",
                bulletPoints: [
                    'In the next step, you can select your preferred verification method from a drop-down list.',
                    'Example: mobile phone verification. Please enter your mobile phone number and click “Verify”. Then enter the 6-digit number (mTan) sent to you by text message in myAudi.'
                ],
                hints: [
                    "Alternatively, you can complete these steps on your smartphone."
                ],
            },
            {
                title: "Your myAudi account is now ready!",
                finished: true, 
            },
        ]
    },
    {
        title: "Configure the e-tron charging system connect",
        icon: process.env.PUBLIC_URL + "/ico/administrator-small.svg",
        subSteps: [
            {
                title: "1. Basic settings",
                bulletPoints: [
                    "When the optional e-tron charging system connect is switched on for the first time, you are automatically guided through several initial configuration steps (e.g. select language; country).*",
                    "Read and accept the Privacy Policy. Tap** on the down arrow icon until you reach the end of the text.",
                    "Now activate automatic software updates (recommended)."
                ],
                hints: [
                    "All initial configuration settings can also be configured and changed later on in the settings menu.",
                    "The touch screen only responds to taps, not to swipes.",
                ],
            },
            {
                title: "2. Connection to the home Internet*",
                bulletPoints: [
                    "In the next step, you will be asked to connect the optional e-tron charging system connect to your home Internet.",
                    "The e-tron charging system connect automatically searches for Wi-Fi connections. Select your Wi-Fi connection and enter your Wi-Fi password.",
                    "Alternative: You can integrate the e-tron charging system connect into an existing Powerline home network (PLC) without an additional adapter."
                ],
                hints: [
                    "Please make sure that your e-tron charging system connect is connected to the Internet so that you can use the full range of functions.",
                ],
            },
            {
                title: "3. Connect to myAudi*",
                bulletPoints: [
                    "Now connect to myAudi by entering the login data for your myAudi account (e-mail address; password)."
                ],
                hints: [
                    "Your personal myAudi account is necessary for displaying the e-tron charging system connect and for documenting the charged energy in myAudi as well as for transferring the electricity tariff from myAudi to the charging system connect. Alternatively, electricity tariffs can also be transferred via a compatible home energy management system to the e-tron charging system connect.",
                ],
            },
            {
                title: "4. Connect to a HEMS (optional)",
                disclaimer: "ᵈ",
                bulletPoints: [
                    'Only complete this step if you have a compatible home energy management system (HEMS)*. Otherwise, press “Skip”.',
                    "Please check the manual for your HEMS to identify the correct settings for your HEMS so that you can connect it to the optional e-tron charging system connect.",
                    "If you have any questions, please contact your electrician."
                ],
                hints: [
                    "In order to be able to guarantee full compatibility, AUDI AG works with leading providers of home energy management systems – ",
                ],
            },
            {
                title: "5. Confirm connection to your HEMS (optional)",
                disclaimer: "ᵈ",
                bulletPoints: [
                    "Once you have applied the settings for your HEMS, it is displayed on the optional e-tron charging system connect.*",
                    'Click on your HEMS and then on “Confirm” on the following page.',
                    "Your HEMS is now connected.",
                    "The HEMS link is required in order to use the solar power usage and blackout protection functions.",
                    'Even if you are not using an HEMS, you can permanently limit the maximum charging current of your charging system in the settings menu of the e-tron charging system connect.'
                ],
                bulletDisclaimer: [
                    {
                        number: "ᵃ",
                        position: 4
                    },
                ],
                hints: [
                    "If you have problems connecting to your HEMS, please contact your Audi partner.",
                ],
            },
            {
                title: "6. Set a PIN",
                bulletPoints: [
                    "You will then be prompted to set a PIN. This protects the optional e-tron charging system connect against unauthorised use.*",
                    "If the PIN is entered incorrectly several times, the PUK is required to unlock the system again. The PUK is provided in a letter delivered with the e-tron charging system connect."
                ],
                hints: [
                    "If the e-tron charging system connect is not in use, the screen locks after a short delay. To unlock the screen, press the lock icon for about two seconds.",
                ],
            },
            {
                title: "Your e-tron charging system connect is now ready!",
                finished: true
            },
        ]
    },
    {
        title: "Add your electricity tariff to myAudi",
        icon: process.env.PUBLIC_URL + "/ico/documents-small.svg",
        subSteps: [
            {
                title: "1. Enter the structure for your electricity tariff*",
                bulletPoints: [
                    'Open myAudi and select your optional e-tron charging system connect under “Cables & devices”. You will then be taken to the detailed information page for the e-tron charging system connect.',
                    'Scroll down to “Cost optimisation” and click on “Save electricity tariff data”.',
                    'You will now be asked various questions about the structure of your electricity tariff. Please answer all the questions.'
                ],
                hints: [
                    "It is best to enter the electricity tariff data on a PC/laptop.",
                ],
            },
            {
                title: "2. Enter the prices for your electricity tariff*",
                bulletPoints: [
                    'You can now add the prices for your electricity tariff. Please refer to your electricity contract for details.**',
                    'If your electricity tariff includes time-of-day price fluctuations, you can also add these here.'
                ],
                hints: [
                    "It is best to enter the electricity tariff data on a PC/laptop.",
                    "Please update your data if your electricity tariff changes.",
                ],
            },
            {
                title: "Your electricity tariff has now been added and will be used for charge optimisation.*",
                finished: true,
                hints: [
                    "The tariff data from myAudi is only used for charge optimisation if there is no price information available from the HEMS system and you have activated a departure timer.",
                ],
            },
        ]
    },
    {
        title: "Settings in the vehicle",
        icon: process.env.PUBLIC_URL + "/ico/car-small.svg",
        subSteps: [
            {
                title: "1. Set the key user of the vehicle",
                bulletPoints: [
                    'On the Multi Media Interface (MMI) of your vehicle, go to “Set key user” (“User” > “User management” > “Set key user”).',
                    'On the top line, enter the e-mail address you used to register on myAudi.',
                    'Finish setting the key user by entering your vehicle code in the bottom line (can be found on the key tag of your vehicle key: see image on left).'
                ]
            },
            {
                title: "2. Connect the vehicle to myAudi",
                bulletPoints: [
                    'Now connect your vehicle to myAudi. Go to “myAudi login” (“User” > “myAudi login”) on your vehicle MMI.',
                    'Enter your myAudi login details here (top line: e-mail address; bottom line: password).'
                ],
            },
            {
                title: "3. Configure the departure timer",
                bulletPoints: [
                    'A departure timer must be activated for the charging process to be optimised.*',
                    'You can configure the departure timer in the menu item “Charging” (“Car” > “Charging & efficiency” > “Charging”). Click on one of the preconfigured departure timers and customise it to your needs.'
                ],
                hints: [
                    "If you do not have an activated departure timer, your vehicle will be charged immediately and as quickly as possible at the maximum available powera (no optimisation of the charging process). The blackout protection function works independently of an activated departure timer; it requires the optional e-tron charging system connect to be connected with a compatible HEMS.",
                ],
            },
            {
                title: "4. Activate the departure timer",
                bulletPoints: [
                    'When you switch off the ignition of your vehicle, a window is displayed where you can activate a departure timer. Press “Charging”.',
                    'Your departure timers are then displayed. Select your desired departure timer and activate it by pressing the clock icon above the departure timer setting.',
                    'Repeat these steps every time you want to use the departure timer.*'
                ],
                hints: [
                    "An activated departure timer is automatically cancelled when the charging cable is disconnected.",
                ],
            },
            {
                title: "Charging is now carried out automatically in accordance with your settings.",
                finished: true,
            },
        ]
    },
    {
        title: "Charging overview in myAudi",
        icon: process.env.PUBLIC_URL + "/ico/polls-small.svg",
        subSteps: [
            {
                title: "1. Overview of the current charge cycle*",
                bulletPoints: [
                    'Open myAudi and select your optional e-tron charging system connect under “Cables & devices”. You will then be taken to the detailed information page for the e-tron charging system connect.',
                    'During active charging, your real-time charge cycle is displayed (if you are not currently charging, the most recent charge cycle is displayed).'
                ],
                hints: [
                    "Alternatively, you can complete these steps on your smartphone.",
                ],
            },
            {
                title: "2. Overview of charging history/charging statistics*",
                bulletPoints: [
                    'Scroll down on the detailed information page to see your charging history.',
                    'It provides you with an overview of the current month and the previous month. For further details about the charge cycles, click on “Go to charging history”.'
                ],
                hints: [
                    "Alternatively, you can complete these steps on your smartphone.",
                ],
            },
            {
                title: "3. Export the charging history/charging statistics as a PDF*",
                bulletPoints: [
                    'There are three options to choose from under “Go to charging history”. “Charge quantity”; “Charge time”; “List”.',
                    'Under “List”, you can export the charging history. Click on “Export selection”.',
                    'The charging history is then displayed as a PDF and can be saved.'
                ],
                hints: [
                    "The charging history/charging statistics can only be exported via the myAudi portal and not via the app.",
                ],
            },
            {
                title: "Initial setup completed successfully.",
                finished: true,
            },
        ]
    }
];

export const howToData_CH_IT: Array<StepContentProps> = [
    {
        title: "Creazione account myAudi",
        icon: process.env.PUBLIC_URL + '/ico/user-small.svg',
        subSteps: [
            {
                title: "",
                bulletPoints: [],
            },
            {
                title: "1. Pagina di registrazione myAudi*",
                bulletPoints: [
                    "Può registrarsi al seguente link:",
                    "https://login.audi.com/registration",
                    "Attivi ora gli update automatici del software (consigliato)."
                ],
                hints: [
                    "In alternativa può procedere anche tramite uno smartphone."
                ],
            },
            {
                title: "2. E-mail di conferma*",
                bulletPoints: [
                    'Apra l’e-mail di conferma che le è stata inviata e faccia clic su «Terminare la registrazione nel browser». ',
                    "La registrazione è completata"
                ],
                hints: [
                    "In alternativa può procedere anche tramite uno smartphone."
                ],
            },
            {
                title: "3. Creazione veicolo in myAudi*",
                bulletPoints: [
                    'Dopo aver completato la registrazione e l’accesso iniziale a myAudi, appare una richiesta di creazione del veicolo.',
                    'Faccia clic su «Creare veicolo».'
                ],
                hints: [
                    "In alternativa può procedere anche tramite uno smartphone."
                ],
            },
            {
                title: "4. Inserimento numero di identificazione del veicolo (VIN)*",
                bulletPoints: [
                    'Inserisca il VIN del suo veicolo.',
                    "Il VIN si trova sul veicolo nell’angolo inferiore del parabrezza o nel libretto di circolazione.",
                    'Faccia clic su «Creare veicolo».'
                ],
                hints: [
                    "In alternativa può procedere anche tramite uno smartphone."
                ],
            },
            {
                title: "5. Verifica account myAudi Account*",
                bulletPoints: [
                    'Conclusa la creazione del veicolo si apre una pagina per la verifica dell’account.',
                    "Per la verifica deve prima inserire diversi dati personali.",
                    'Poi imposti un PIN a 4 cifre a scelta, che sarà usato per l’autorizzazione.'
                ],
                hints: [
                    "In alternativa può procedere anche tramite uno smartphone."
                ],
            },
            {
                title: "6. Selezione del metodo di verifica*",
                bulletPoints: [
                    'Nel passaggio successivo può impostare il metodo di verifica desiderato mediante una selezione dal menu a discesa.',
                    'Esempio: Verifica del telefono cellulare. Inserisca il suo numero di cellulare e faccia clic su «Verificare». Inserisca quindi il numero a 6 cifre che ha ricevuto per SMS (mTan) in myAudi.'
                ],
                hints: [
                    "In alternativa può procedere anche tramite uno smartphone."
                ],
            },
            {
                title: "Il suo account myAudi ora è pronto!",
                finished: true, 
            },
        ]
    },
    {
        title: "Configurazione sistema di ricarica e-tron connect",
        icon: process.env.PUBLIC_URL + "/ico/administrator-small.svg",
        subSteps: [
            {
                title: "1. Impostazioni di base",
                bulletPoints: [
                    "Alla prima attivazione del sistema di ricarica e-tron connect opzionale il sistema la guiderà automaticamente attraverso i vari passaggi della configurazione iniziale (ad es. selezione lingua, paese).*",
                    "Legga e confermi l’informativa sulla protezione dei dati. Per farlo, tocchi** sul simbolo della freccia che punta verso il basso fino a raggiungere la fine del testo.",
                    "Attivi ora gli update automatici del software (consigliato)."
                ],
                hints: [
                    "Tutte le impostazioni della configurazione iniziale possono essere definite e modificate anche successivamente nel menu di impostazione.",
                    "Il touch screen reagisce solo a gesti di pressione e non a sfioramenti.",
                ],
            },
            {
                title: "2. Connessione a Internet domestico*",
                bulletPoints: [
                    "Nel passaggio successivo le verrà richiesto di connettere il sistema di ricarica e-tron connect opzionale al suo Internet domestico.",
                    "Il sistema di ricarica e-tron connect ricerca automaticamente le connessioni WLAN. Selezioni la sua connessione WLAN e inserisca la sua password WLAN.",
                    "Alternativa: Può collegare il sistema di ricarica e-tron connect senza altri adattatori ad una rete domestica Powerline (PLC) esistente."
                ],
                hints: [
                    "Si accerti che il suo sistema di ricarica e-tron connect sia connesso ad Internet per poter utilizzare tutte le funzionalità.",
                ],
            },
            {
                title: "3. Connessione a myAudi*",
                bulletPoints: [
                    "Stabilisca una connessione con myAudi, inserendo i dati di accesso del suo account myAudi (indirizzo e-mail, password)."
                ],
                hints: [
                    "Il suo account myAudi personale è richiesto per visualizzare il sistema di ricarica e-tron connect e la documentazione dell’energia ricaricata in myAudi, nonché per la trasmissione della tariffa elettrica da myAudi nel sistema di ricarica connect. In alternativa le tariffe elettriche possono essere trasmesse al sistema di ricarica e-tron connect anche tramite un Home Energy Management System compatibile.",
                ],
            },
            {
                title: "4. Connessione con HEMS (opzionale)",
                disclaimer: "ᵈ",
                bulletPoints: [
                    'Esegua questo passaggio solo se dispone di un Home Energy Management System (HEMS) * compatibile. In caso contrario faccia clic su «Saltare».',
                    "Verifichi nelle Istruzioni d’uso del suo HEMS, quali impostazioni deve effettuare nel suo HEMS per poter stabilire la connessione con il sistema di ricarica e-tron connect opzionale.",
                    "Per eventuali domande si rivolga al suo elettricista."
                ],
                hints: [
                    "Al fine di garantire una piena compatibilità, AUDI AG collabora con leader nella fornitura di Home Energy Management System: ",
                ],
            },
            {
                title: "5. Completare la connessione HEMS (opzionale)",
                disclaimer: "ᵈ",
                bulletPoints: [
                    "Dopo aver effettuato le impostazioni sul suo HEMS, queste saranno visualizzate sul sistema di ricarica e-tron connect opzionale.*",
                    'Faccia clic sul suo HEMS e nella pagina successiva su «Confermare».',
                    "La sua connessione HEMS è stabilita correttamente.",
                    "La connessione HEMS è necessaria per le funzioni di utilizzo della corrente solare e la protezione dai blackout.",
                    'Anche senza HEMS può limitare in modo permanente la corrente di ricarica massima del suo sistema di ricarica nel menu di impostazione del sistema di ricarica e-tron connect.'
                ],
                bulletDisclaimer: [
                    {
                        number: "ᵃ",
                        position: 4
                    },
                ],
                hints: [
                    "In caso di problemi nella connessione con il suo HEMS, contatti il suo partner Audi.",
                ],
            },
            {
                title: "6. Assegnazione PIN",
                bulletPoints: [
                    "Successivamente le viene richiesto di impostare un PIN, con il quale proteggere il sistema di ricarica e-tron connect opzionale da utilizzi non autorizzati.*",
                    "Se il PIN viene inserito più volte in modo errato, per lo sblocco è richiesto il PUK. Il PUK è contenuto in una lettera consegnata assieme al sistema di ricarica e-tron connect."
                ],
                hints: [
                    "In caso di inutilizzo del sistema di ricarica e-tron connect, dopo un breve periodo si attiva il blocco dello schermo. Per sbloccare, fare clic per ca. due secondi sull’icona del lucchetto.",
                ],
            },
            {
                title: "Ora il suo sistema di ricarica e-tron connect è pronto!",
                finished: true
            },
        ]
    },
    {
        title: "Memorizzare la sua tariffa elettrica in myAudi",
        icon: process.env.PUBLIC_URL + "/ico/documents-small.svg",
        subSteps: [
            {
                title: "1. Inserimento della struttura della sua tariffa elettrica*",
                bulletPoints: [
                    'Apra myAudi e selezioni il suo sistema di ricarica e-tron connect opzionale alla voce di menu «Cavi e dispositivi». Passi quindi alla pagina delle informazioni dettagliate del sistema di ricarica e-tron connect.',
                    'Scorra verso il basso fino all’area «Ottimizzazione dei costi» e faccia clic su «Memorizzare i dati della tariffa elettrica».',
                    'Ora dovrà rispondere a diverse domande relative alla struttura della sua tariffa elettrica. Risponda alle domande.'
                ],
                hints: [
                    "Per eseguire al meglio l’inserimento dei dati relativi alla tariffa elettrica, utilizzi un PC/Laptop.",
                ],
            },
            {
                title: "2. Inserimento dei prezzi della sua tariffa elettrica*",
                bulletPoints: [
                    'Ora può memorizzare i prezzi della sua tariffa elettrica. Sono riportati sul contratto per la fornitura di energia.**',
                    'Nel caso che la sua tariffa elettrica dovesse prevedere oscillazioni di prezzo su base oraria, può aggiungerle anche in questo punto.'
                ],
                hints: [
                    "Per eseguire al meglio l’inserimento dei dati relativi alla tariffa elettrica, utilizzi un PC/Laptop.",
                    "Aggiorni i suoi inserimenti, se modificano la sua tariffa elettrica.",
                ],
            },
            {
                title: "La sua tariffa elettrica ora è memorizzata e sarà utilizzata per ottimizzare la ricarica.*",
                finished: true,
                hints: [
                    "Se non sono disponibili informazioni sul prezzo fornite dal sistema HEMS e se lei non ha attivato un timer per la partenza, i dati relativi alla tariffa forniti da myAudi saranno utilizzati solo ai fini dell’ottimizzazione della ricarica.",
                ],
            },
        ]
    },
    {
        title: "Impostazioni nel veicolo",
        icon: process.env.PUBLIC_URL + "/ico/car-small.svg",
        subSteps: [
            {
                title: "1. Impostazione dell’utente principale del veicolo",
                bulletPoints: [
                    'Nell’interfaccia Multi Media (MMI) del suo veicolo vada nell’area «Impostare utente principale» («Utente» > «Gestione utenti» > «Impostare utente principale»).',
                    'Nella riga in alto inserisca l’indirizzo e-mail, con il quale ha effettuato la registrazione su myAudi.',
                    'Completi l’impostazione dell’utente principale, inserendo il codice del suo veicolo nella riga inferiore (si trova sul cartellino allegato alla chiave del suo veicolo: vedere a sinistra).'
                ]
            },
            {
                title: "2. Connessione tra veicolo e myAudi",
                bulletPoints: [
                    'Connetta il suo veicolo a myAudi. Per eseguire questa operazione passi nell’MMI del suo veicolo nell’area «Login myAudi» («Utente» > «Login myAudi»).',
                    'Inserire qui i suoi dati di accesso myAudi (riga superiore: indirizzo e-mail; riga inferiore: password).'
                ],
            },
            {
                title: "3. Configurare il timer per la partenza",
                bulletPoints: [
                    'L’attivazione del timer per la partenza è il prerequisito per ottimizzare il processo di ricarica.*',
                    'Può configurare il timer per la partenza nella voce menu «Ricarica» («Veicolo» > «Ricarica ed efficienza» > «Ricarica»). Faccia clic su uno dei timer per la partenza preconfigurati e li personalizzi secondo le sue esigenze.'
                ],
                hints: [
                    "Se il timer per la partenza non viene attivato, il suo veicolo viene ricaricato subito e nel minor tempo possibile con la potenza massima disponibilea (nessuna ottimizzazione del processo di ricarica). La funzione di protezione dai blackout è indipendente dall’attivazione di un timer per la partenza; presuppone una connessione tra il sistema di ricarica e-tron connect opzionale e un HEMSd compatibile.",
                ],
            },
            {
                title: "4. Attivare timer per la partenza",
                bulletPoints: [
                    'Se disinserisce l’accensione del suo veicolo, appare una finestra, mediante la quale può attivare un timer per la partenza. Faccia clic qui su «Ricaricare».',
                    'Ora sono visualizzati i suoi timer per la partenza. Selezioni il timer per la partenza che desidera e lo attivi facendo clic sul simbolo dell’orologio mediante l’impostazione del timer per la partenza.',
                    'Ripeta ogni volta questa procedura, se vuole utilizzare il timer per la partenza.*'
                ],
                hints: [
                    "Il timer per la partenza attivato si spegne automaticamente dopo il distacco del cavo di ricarica.",
                ],
            },
            {
                title: "Ora la procedura di ricarica viene eseguita automaticamente sulla base delle sue impostazioni.",
                finished: true,
            },
        ]
    },
    {
        title: "Panoramica della ricarica in myAudi",
        icon: process.env.PUBLIC_URL + "/ico/polls-small.svg",
        subSteps: [
            {
                title: "1. Panoramica della procedura di ricarica attuale*",
                bulletPoints: [
                    'Apra myAudi e selezioni il suo sistema di ricarica e-tron connect opzionale alla voce di menu «Cavi e dispositivi». Passi quindi alla pagina delle informazioni dettagliate del sistema di ricarica e-tron connect.',
                    'Durante una procedura di ricarica attiva viene visualizzata la sua procedura di ricarica in tempo reale (se attualmente non sta eseguendo la ricarica, viene visualizzata la cronologia delle ricariche passate).'
                ],
                hints: [
                    "In alternativa può procedere anche tramite uno smartphone.",
                ],
            },
            {
                title: "2. Panoramica della cronologia delle ricariche/statistica delle ricariche*",
                bulletPoints: [
                    'Se nella pagina delle informazioni dettagliate scorre verso il basso, vedrà la cronologia delle sue ricariche.',
                    'Viene visualizzata una panoramica del mese in corso e di quello precedente. Per altri dettagli relativi alle ricariche faccia clic su «Alla cronologia delle ricariche».'
                ],
                hints: [
                    "In alternativa può procedere anche tramite uno smartphone.",
                ],
            },
            {
                title: "3. Esportazione della cronologia ricariche/statistica delle ricariche in formato PDF*",
                bulletPoints: [
                    'L’area «Alla cronologia delle ricariche» offre tre possibilità di scelta: «Quantità ricarica»; «Durata ricarica»; «Elenco».',
                    'In «Elenco» è possibile esportare la cronologia delle ricariche. Faccia clic su «Esportare selezione».',
                    'Successivamente la cronologia delle ricariche viene visualizzata come PDF e può essere memorizzata.'
                ],
                hints: [
                    "L’esportazione della cronologia delle ricariche/statistica delle ricariche può essere eseguita solo mediante il portale myAudi e non tramite l’app.",
                ],
            },
            {
                title: "Prima messa in funzione completata con successo.",
                finished: true,
            },
        ]
    }
];

export const howToData_CH_FR: Array<StepContentProps> = [
    {
        title: "Création d’un compte myAudi",
        icon: process.env.PUBLIC_URL + '/ico/user-small.svg',
        subSteps: [
            {
                title: "",
                bulletPoints: [],
            },
            {
                title: "1. Page d’inscription myAudi*",
                bulletPoints: [
                    "Vous pouvez vous inscrire en utilisant le lien suivant:",
                    "https://login.audi.com/registration",
                    "Activer maintenant les mises à jour logicielles automatiques (recommandation)."
                ],
                hints: [
                    "Vous pouvez également effectuer les différentes étapes avec un smartphone."
                ],
            },
            {
                title: "2. E-Mail de confirmation*",
                bulletPoints: [
                    'Ouvrez l’e-mail de confirmation que vous avez reçu et cliquez sur «Clore l’inscription sur le navigateur». ',
                    "L’inscription est maintenant terminée."
                ],
                hints: [
                    "Vous pouvez également effectuer les différentes étapes avec un smartphone."
                ],
            },
            {
                title: "3. Enregistrement du véhicule dans myAudi*",
                bulletPoints: [
                    'Après l’inscription et la première connexion à myAudi, vous êtes invité/e à enregistrer votre véhicule.',
                    'Cliquez sur «Enregistrer le véhicule».'
                ],
                hints: [
                    "Vous pouvez également effectuer les différentes étapes avec un smartphone."
                ],
            },
            {
                title: "4. Saisie du numéro d’identification de véhicule (NIV)*",
                bulletPoints: [
                    'Saisissez maintenant le NIV de votre véhicule.',
                    "Le NIV se trouve côté conducteur, au bas du pare-brise ou dans le permis de circulation.",
                    'Cliquez ensuite sur «Enregistrer le véhicule».'
                ],
                hints: [
                    "Vous pouvez également effectuer les différentes étapes avec un smartphone."
                ],
            },
            {
                title: "5. Vérification du compte myAudi*",
                bulletPoints: [
                    'Après l’enregistrement du véhicule, une page de vérification du compte s’affiche.',
                    "Vous devez d’abord saisir différentes données personnelles pour la vérification.",
                    'Créez ensuite un code PIN à quatre chiffres. Celui-ci servira d’autorisation.'
                ],
                hints: [
                    "Vous pouvez également effectuer les différentes étapes avec un smartphone."
                ],
            },
            {
                title: "6. Sélection du mode de vérification*",
                bulletPoints: [
                    'Vous pouvez ensuite sélectionner le type de vérification que vous souhaitez dans un menu déroulant.',
                    'Exemple: vérification téléphone portable: saisissez votre numéro de téléphone portable et cliquez sur «Vérifier». Saisissez ensuite sur myAudi le numéro à 6 chiffres (mTan) que vous avez reçu par SMS.'
                ],
                hints: [
                    "Vous pouvez également effectuer les différentes étapes avec un smartphone."
                ],
            },
            {
                title: "Votre compte myAudi est maintenant prêt!",
                finished: true, 
            },
        ]
    },
    {
        title: "Configuration du système de recharge e-tron connect",
        icon: process.env.PUBLIC_URL + "/ico/administrator-small.svg",
        subSteps: [
            {
                title: "1. Paramètres de base",
                bulletPoints: [
                    "Lors de la première activation du système de recharge e-tron connect en option, vous êtes automatiquement guidé/e à travers les différentes étapes de la configuration initiale (p. ex. sélection langue, pays).*",
                    "Lisez et confirmez les indications relatives à la protection des données. Pour ce faire, appuyez** sur le symbole de la flèche dirigée vers le bas jusqu’à la fin du texte.",
                    "Activez maintenant les mises à jour logicielles automatiques (recommandation)."
                ],
                hints: [
                    "Tous les paramètres de la configuration initiale peuvent être configurés et modifiés ultérieurement dans le menu de configuration.",
                    "L’écran tactile réagit uniquement aux pressions et non aux glissements.",
                ],
            },
            {
                title: "2. Connexion au réseau Internet domestique*",
                bulletPoints: [
                    "Vous êtes ensuite invité/e à coupler le système de recharge e-tron connect en option à votre réseau Internet domestique.",
                    "Le système de recharge e-tron connect recherche automatiquement des connexions wi-fi. Sélectionnez votre connexion wi-fi et entrez votre mot de passe wi-fi.",
                    "Alternative: vous pouvez intégrer le système de recharge e-tron connect, sans adaptateur supplémentaire, dans un réseau domestique Powerline (PLC) existant."
                ],
                hints: [
                    "Veillez à ce que votre système de recharge e-tron connect soit connecté à Internet pour pouvoir utiliser l’ensemble des fonctions.",
                ],
            },
            {
                title: "3. Connexion avec myAudi*",
                bulletPoints: [
                    "Établissez maintenant une connexion à myAudi en saisissant les données d’accès de votre compte myAudi (adresse e-mail, mot de passe)."
                ],
                hints: [
                    "Votre compte personnel myAudi est nécessaire pour l’affichage du système de recharge e-tron connect et la documentation de l’énergie chargée dans myAudi, ainsi que pour la transmission des tarifs d’électricité de myAudi au système de recharge connect. Les tarifs d’électricité peuvent également être transmis au système de recharge e-tron connect via un système de gestion de l’énergie domestique compatible.",
                ],
            },
            {
                title: "4. Connexion avec système de gestion de l’énergie domestique (en option)",
                disclaimer: "ᵈ",
                bulletPoints: [
                    'Suivez cette procédure uniquement si vous disposez d’un système de gestion de l’énergie domestique compatible*. Sinon, cliquez sur «Ignorer».',
                    "Veuillez consulter le mode d’emploi de votre système de gestion de l’énergie domestique pour savoir quels réglages vous devez effectuer pour vous connecter au système de recharge e-tron en option.",
                    "Si vous avez des questions, veuillez contacter votre électricien."
                ],
                hints: [
                    "Afin de garantir une compatibilité totale, AUDI AG collabore avec des fournisseurs phares de systèmes de gestion de l’énergie domestique, dont ",
                ],
            },
            {
                title: "5. Clore la connexion avec le système de gestion de l’énergie domestique (en option)",
                disclaimer: "ᵈ",
                bulletPoints: [
                    "Une fois les réglages effectués sur votre système de gestion de l’énergie domestique, il s’affiche sur le système de recharge e-tron connect en option.*",
                    'Cliquez sur votre système de gestion de l’énergie domestique et, sur la page suivante, sur «Confirmer».',
                    "La connexion à votre système de gestion de l’énergie domestique est maintenant établie.",
                    "L’ajout du système de gestion de l’énergie domestique est nécessaire pour les fonctions Utilisation de l’énergie solaire et Protection contre les coupures d’électricité.",
                    'Même sans système de gestion de l’énergie domestique, vous pouvez limiter de façon permanente le courant de charge maximal de votre système de recharge dans le menu de configuration du système de recharge e-tron connect.'
                ],
                bulletDisclaimer: [
                    {
                        number: "ᵃ",
                        position: 4
                    },
                ],
                hints: [
                    "Si vous rencontrez des problèmes de connexion à votre système de gestion de l’énergie domestique, veuillez contacter votre partenaire Audi.",
                ],
            },
            {
                title: "6. Attribution d’un code PIN",
                bulletPoints: [
                    "Il vous est alors demandé de définir un code PIN. Celui-ci vous permet de protéger le système de recharge e-tron connect contre toute utilisation abusive.*",
                    "Si le code PIN saisi est incorrect à plusieurs reprises, le code PUK est nécessaire pour le déverrouillage. Vous trouverez le code PUK dans une lettre fournie avec le système de recharge e-tron connect."
                ],
                hints: [
                    "Si le système de recharge e-tron connect n’est pas utilisé, le verrouillage de l’écran s’active après un court laps de temps. Pour déverrouiller l’écran, cliquez pendant environ deux secondes sur le cadenas.",
                ],
            },
            {
                title: "Votre système de recharge e-tron connect est maintenant prêt!",
                finished: true
            },
        ]
    },
    {
        title: "Enregistrement de vos tarifs d’électricité dans myAudi",
        icon: process.env.PUBLIC_URL + "/ico/documents-small.svg",
        subSteps: [
            {
                title: "1. Saisie du système de vos tarifs d’électricité*",
                bulletPoints: [
                    'Ouvrez myAudi et sélectionnez votre système de recharge e-tron connect en option dans la rubrique «Câbles et appareils». Vous accédez alors à la page d’informations détaillées du système de recharge e-tron connect.',
                    'Faites maintenant défiler la page jusqu’à la rubrique «Optimisation des coûts» et cliquez sur «Enregistrer les tarifs d’électricité».',
                    'Différentes questions vous sont posées sur vos tarifs d’électricité. Veuillez répondre à celles-ci.'
                ],
                hints: [
                    "Effectuez la saisie des tarifs d’électricité de préférence via un PC / ordinateur portable.",
                ],
            },
            {
                title: "2. Saisie de vos tarifs d’électricité*",
                bulletPoints: [
                    'Vous pouvez maintenant enregistrer vos tarifs d’électricité. Vous trouverez ceux-ci dans votre contrat d’électricité.**',
                    'Si votre contrat d’électricité prévoit des fluctuations de prix en fonction de tranches horaires, vous pouvez les ajouter ici.'
                ],
                hints: [
                    "Effectuez la saisie des tarifs d’électricité de préférence via un PC / ordinateur portable.",
                    "Veuillez actualiser vos entrées en cas de modification de vos tarifs d’électricité.",
                ],
            },
            {
                title: "Vos tarifs d’électricité sont maintenant enregistrés et seront utilisés pour optimiser vos recharges.*",
                finished: true,
                hints: [
                    "Les tarifs dans myAudi ne sont utilisés pour optimiser les recharges que si aucune information de prix n’est disponible dans le système de gestion de l’énergie domestique et si vous avez activé une minuterie de démarrage.",
                ],
            },
        ]
    },
    {
        title: "Réglages dans le véhicule",
        icon: process.env.PUBLIC_URL + "/ico/car-small.svg",
        subSteps: [
            {
                title: "1. Définir l’utilisateur principal du véhicule",
                bulletPoints: [
                    'Dans le système Multi Media Interface (MMI) de votre véhicule, allez à la rubrique «Définir l’utilisateur principal» («Utilisateurs» > «Administration des utilisateurs» > «Définir l’utilisateur principal»).',
                    'Dans la ligne supérieure, saisissez l’adresse e-mail avec laquelle vous vous êtes inscrit/e sur myAudi.',
                    'Complétez la définition de l’utilisateur principal en saisissant votre code véhicule (inscrit sur le porte-clés de la clé de votre véhicule: voir à gauche) dans la ligne inférieure.'
                ]
            },
            {
                title: "2. Raccordement du véhicule à myAudi",
                bulletPoints: [
                    'Raccordez maintenant votre véhicule à myAudi. Pour ce faire, dans le système MMI de votre véhicule, allez à la rubrique «Identifiant myAudi» («Utilisateurs» > «Identifiant myAudi»).',
                    'Saisissez ici vos données d’accès myAudi (ligne supérieure: adresse e-mail; ligne inférieure: mot de passe).'
                ],
            },
            {
                title: "3. Configurer la minuterie de démarrage",
                bulletPoints: [
                    'L’optimisation du processus de recharge requiert une minuterie de démarrage activée.*',
                    'Vous pouvez configurer la minuterie de démarrage dans la rubrique «Recharge» («Véhicule» > «Recharge et efficience» > «Recharge»). Cliquez sur l’une des minuteries de démarrage préconfigurées et personnalisez-la en fonction de vos besoins.'
                ],
                hints: [
                    "Sans minuterie de démarrage activée, votre véhicule est rechargé immédiatement et le plus rapidement possible avec la puissance maximale disponiblea (aucune optimisation du processus de recharge). La fonction de protection contre les coupures d’électricité ne requiert pas qu’une minuterie de démarrage soit activée, mais le couplage d’un système de recharge e-tron connect en option à un système de gestion de l’énergie domestique compatibled.",
                ],
            },
            {
                title: "4. Activer la minuterie de démarrage",
                bulletPoints: [
                    'Lorsque vous coupez le contact de votre véhicule, une fenêtre s’affiche qui vous permet d’activer une minuterie de départ. Dans celle-ci, cliquez sur «Recharge».',
                    'Vos minuteries de démarrage s’affichent maintenant. Sélectionnez la minuterie de démarrage que vous souhaitez et activez-la en cliquant sur le symbole de l’horloge situé au-dessus des paramètres de la minuterie de démarrage.',
                    'Répétez cette opération à chaque fois que vous souhaitez utiliser la minuterie de démarrage.*'
                ],
                hints: [
                    "La minuterie de départ activée s’éteint automatiquement après le débranchement du câble de recharge.",
                ],
            },
            {
                title: "La procédure de recharge s’effectue alors automatiquement selon les paramètres définis.",
                finished: true,
            },
        ]
    },
    {
        title: "Aperçu de la recharge dans myAudi",
        icon: process.env.PUBLIC_URL + "/ico/polls-small.svg",
        subSteps: [
            {
                title: "1. Aperçu de la procédure de recharge actuelle*",
                bulletPoints: [
                    'Ouvrez myAudi et sélectionnez votre système de recharge e-tron connect en option dans la rubrique «Câbles et appareils». Vous accédez alors à la page d’informations détaillées du système de recharge e-tron connect.',
                    'Pendant une procédure de recharge active, l’état d’avancement de la recharge s’affiche ici en temps réel (si vous n’êtes pas en train de recharger, la dernière recharge effectuée s’affiche).'
                ],
                hints: [
                    "Vous pouvez également effectuer les différentes étapes avec un smartphone.",
                ],
            },
            {
                title: "2. Aperçu historique / statistiques des recharges*",
                bulletPoints: [
                    'Si vous faites défiler l’écran jusqu’à la page d’informations détaillées, vous accédez à l’historique des recharges.',
                    'Un aperçu du mois en cours et du mois précédent s’affiche ici. Pour de plus amples détails, cliquez sur «Accéder à l’historique des recharges».'
                ],
                hints: [
                    "Vous pouvez également effectuer les différentes étapes avec un smartphone.",
                ],
            },
            {
                title: "3. Exportation historique / statistiques des recharges au format PDF*",
                bulletPoints: [
                    'La rubrique «Accéder à l’historique des recharges» vous propose trois options au choix: «Quantité d’énergie»; «Durée de recharge»; «Liste».',
                    'La rubrique «Liste» vous permet d’exporter l’historique des recharges. Pour ce faire, cliquez sur «Exporter la sélection».',
                    'L’historique des recharges s’affiche alors au format PDF et peut être sauvegardé.'
                ],
                hints: [
                    "L’exportation de l’historique et des statistiques de recharge peut être alors effectuée via le portail myAudi et non pas dans l’application.",
                ],
            },
            {
                title: "Mise en service initiale terminée.",
                finished: true,
            },
        ]
    }
];

export const howToData_BE_FR: Array<StepContentProps> = [
    {
        title: "Création d’un compte myAudi",
        icon: process.env.PUBLIC_URL + '/ico/user-small.svg',
        subSteps: [
            {
                title: "",
                bulletPoints: [],
            },
            {
                title: "1. Page d’enregistrement myAudi*",
                bulletPoints: [
                    "Vous pouvez vous enregistrer en cliquant sur le lien suivant:",
                    "https://login.audi.com/registration",
                    "Activez maintenant les mises à jour logicielles automatiques (recommandé)."
                ],
                hints: [
                    "Vous pouvez également effectuer cette opération depuis un smartphone."
                ],
            },
            {
                title: "2. E-mail de confirmation*",
                bulletPoints: [
                    'Ouvrez l’e-mail de confirmation qui vous a été envoyé et cliquez sur «Finaliser l’enregistrement dans le navigateur».  ',
                    "L’enregistrement est maintenant terminé"
                ],
                hints: [
                    "Vous pouvez également effectuer cette opération depuis un smartphone.",
                ],
            },
            {
                title: "3. Créer une voiture dans myAudi*",
                bulletPoints: [
                    'Une fois l’enregistrement effectué et après vous être connecté pour la première fois à myAudi, vous pouvez créer une voiture.',
                    'Cliquez sur «Créer une voiture»'
                ],
                hints: [
                    "Vous pouvez également effectuer cette opération depuis un smartphone."
                ],
            },
            {
                title: "4. Saisie du VIN de la voiture*",
                bulletPoints: [
                    'Saisissez maintenant le VIN de votre voiture.',
                    "Le VIN se trouve sur le pare-brise, dans l’angle inférieur côté conducteur, ou sur le certificat d’immatriculation.",
                    'Cliquez ensuite sur «Créer une voiture»'
                ],
                hints: [
                    "Vous pouvez également effectuer cette opération depuis un smartphone."
                ],
            },
            {
                title: "5. Vérification du compte myAudi*",
                bulletPoints: [
                    'Une fois la voiture créée, une page s’ouvre pour la vérification du compte.',
                    "Vous devez tout d’abord saisir des données personnelles pour la vérification.",
                    'Définissez ensuite un code PIN à quatre chiffres. Ce code servira d’autorisation.'
                ],
                hints: [
                    "Vous pouvez également effectuer cette opération depuis un smartphone."
                ],
            },
            {
                title: "6. Choix de la méthode de vérification*",
                bulletPoints: [
                    'L’étape suivante vous permet de définir le type de vérification souhaité à l’aide d’un menu déroulant.',
                    'Exemple: Vérification par téléphone mobile Veuillez saisir votre numéro de GSM et cliquez sur «Vérifier». Saisissez ensuite dans myAudi le code à 6 caractères qui vous a été envoyé par SMS.'
                ],
                hints: [
                    "Vous pouvez également effectuer cette opération depuis un smartphone."
                ],
            },
            {
                title: "Votre compte myAudi est prêt à être utilisé!",
                finished: true, 
            },
        ]
    },
    {
        title: "Configuration du système de charge e-tron connect",
        icon: process.env.PUBLIC_URL + "/ico/administrator-small.svg",
        subSteps: [
            {
                title: "1. Réglages de base",
                bulletPoints: [
                    "Lors de la première mise sous tension du système de recharge e-tron connect (disponible en option), vous êtes automatiquement guidé dans les différentes opérations de la configuration initiale (ex. : choix de la langue, du pays).*",
                    "Lisez puis acceptez la note d’information sur la protection de la vie privée. Appuyez** pour cela sur la flèche dirigée vers le bas jusqu’à ce que vous atteigniez la fin du texte.",
                    "Activez maintenant les mises à jour logicielles automatiques (recommandé)."
                ],
                hints: [
                    "Tous les réglages de la configuration initiale peuvent être ultérieurement modifiés dans le menu Réglages.",
                    "L’écran tactile réagit uniquement aux pressions et non aux balayages.",
                ],
            },
            {
                title: "2. Connexion à l’accès à Internet du domicile",
                bulletPoints: [
                    "À l’étape suivante, vous devez connecter le système de recharge e-tron connect (disponible en option) à l’accès à Internet de votre habitation.",
                    "Le système de recharge e-tron connect recherche automatiquement les connexions sans fil. Sélectionnez votre connexion sans fil et saisissez votre mot de passe Wi-Fi.",
                    "Vous pouvez également connecter directement (sans ajout d’adaptateur) le système de recharge e-tron connect à un réseau électrique domestique existant (courant porteur en ligne)."
                ],
                hints: [
                    "Assurez-vous que votre système de recharge e-tron connect est connecté à Internet afin de pouvoir utiliser toutes les fonctionnalités.",
                ],
            },
            {
                title: "3. Connexion à myAudi*",
                bulletPoints: [
                    "Établissez maintenant une connexion à myAudi en saisissant les données d’accès de votre compte myAudi (adresse e-mail, mot de passe)."
                ],
                hints: [
                    "Votre compte myAudi personnel permet d’afficher le système de recharge e-tron connect et les données des recharges effectuées dans myAudi et de transférer le tarif d’électricité de myAudi vers le système de recharge. Le tarif d’électricité peut également être transféré vers le système de recharge e-tron connect par l’intermédiaire d’un système de gestion de l’énergie pour l’habitation compatible.",
                ],
            },
            {
                title: "4. Connexion avec un système de gestion de l’énergie pour l’habitation (en option)",
                disclaimer: "⁴",
                bulletPoints: [
                    'N’effectuez cette opération que si vous disposez d’un système de gestion de l’énergie pour l’habitation (HEMS)* compatible. Sinon, cliquez sur «Ignorer».',
                    "Veuillez consulter le mode d’emploi de votre HEMS pour savoir quels réglages effectuer sur le HEMS afin d’établir la connexion au système de charge e-tron connect (disponible en option).",
                    "En cas de questions, veuillez vous adresser à votre électricien."
                ],
                hints: [
                    "Afin de pouvoir garantir une compatibilité totale, AUDI AG a établi une collaboration avec un des principaux fournisseurs de ce type de systèmes, ",
                ],
            },
            {
                title: "5. Finaliser la connexion avec un système de gestion de l’énergie pour l’habitation (en option)",
                disclaimer: "⁴",
                bulletPoints: [
                    "Une fois les réglages de votre système de gestion de l’énergie pour l’habitation (HEMS) effectués, celui-ci s’affiche sur le système de recharge e-tron connect (disponible en option).*",
                    'Cliquez sur votre HEMS et, à la page suivante, sur «Confirmer».',
                    "La connexion à votre HEMS est maintenant finalisée.",
                    'Les fonctions d’utilisation du courant photovoltaïque et de protection contre les surcharges nécessitent la connexion à un HEMS.',
                    'Si vous ne disposez pas d’un HEMS, il est tout de même possible de limiter durablement le courant de recharge maximal de votre système dans le menu Réglages du système de recharge e-tron connect'
                ],
                bulletDisclaimer: [
                    {
                        number: "¹",
                        position: 4
                    },
                ],
                hints: [
                    "En cas de problèmes de connexion avec votre système de gestion de l’énergie pour l’habitation (HEMS), veuillez contacter votre distributeur Audi.",
                ],
            },
            {
                title: "6. Attribution d’un code PIN",
                bulletPoints: [
                    "Pour terminer, vous devez définir un code PIN. Celui-ci vous permet de protéger le système de recharge e-tron connect (disponible en option) des utilisations frauduleuses.*",
                    "Si vous saisissez plusieurs fois un code PIN erroné, le code PUK est nécessaire pour le déverrouillage. Vous trouverez le code PUK dans un courrier fourni avec le système de recharge e-tron connect."
                ],
                hints: [
                    "Si le système de recharge e-tron connect n’est pas utilisé pendant un certain temps, l’écran se verrouille. Cliquez sur l’icône Serrure pendant environ deux secondes pour déverrouiller l’écran.",
                ],
            },
            {
                title: "Votre système de recharge e-tron connect est maintenant prêt à fonctionner !",
                finished: true
            },
        ]
    },
    {
        title: "Créer votre tarif d’électricité dans myAudi",
        icon: process.env.PUBLIC_URL + "/ico/documents-small.svg",
        subSteps: [
            {
                title: "1. Saisie de la structure de votre tarif d’électricité*",
                bulletPoints: [
                    'Ouvrez myAudi et sélectionnez votre système de recharge e-tron connect (disponible en option) dans le menu « Câbles et appareils ». La page des informations détaillées du système de recharge e-tron connect apparaît.',
                    'Descendez jusqu’à la zone «Optimisation des coûts» et cliquez sur «Créer les données du tarif d’électricité».',
                    'Des questions relatives à la structure de votre tarif d’électricité vous sont posées. Veuillez y répondre.'
                ],
                hints: [
                    "Effectuez de préférence la saisie des données du tarif d’électricité avec un ordinateur.",
                ],
            },
            {
                title: "2. Saisie des prix de votre tarif d’électricité*",
                bulletPoints: [
                    'Vous pouvez maintenant créer les prix de votre tarif d’électricité. Ces prix se trouvent sur votre contrat de fourniture d’électricité.**',
                    'Si votre tarif d’électricité comporte des variations dans la journée, vous pouvez les ajouter à ce niveau.'
                ],
                hints: [
                    "Effectuez de préférence la saisie des données du tarif d’électricité avec un ordinateur.",
                    "Mettez ces données à jour en cas de modifications de votre tarif d’électricité.",
                ],
            },
            {
                title: "Votre tarif d’électricité est maintenant créé et est utilisé pour l’optimisation de la recharge.*",
                finished: true,
                hints: [
                    "Les données tarifaires figurant dans myAudi sont utilisées pour optimiser la recharge uniquement si le système de gestion de l’énergie pour l’habitation ne comporte aucune information de prix et si vous avez activé une horloge pour les heures de départ.",
                ],
            },
        ]
    },
    {
        title: "Réglages dans la voiture",
        icon: process.env.PUBLIC_URL + "/ico/car-small.svg",
        subSteps: [
            {
                title: "1. Définir l’utilisateur principal de la voiture",
                bulletPoints: [
                    'Sur le MMI de votre voiture, accédez à la zone «Définir l’utilisateur principal» («Utilisateurs» > «Gestion des utilisateurs» > «Définir l’utilisateur principal»).',
                    'Sur la ligne du haut, saisissez l’adresse e-mail avec laquelle vous vous êtes enregistré dans myAudi.',
                    'Finalisez la définition de l’utilisateur principal en saisissant le code de votre voiture dans la ligne du bas (se trouve sur le porte-clé de votre clé de contact: voir à gauche).'
                ]
            },
            {
                title: "2. Connexion de la voiture à myAudi",
                bulletPoints: [
                    'Connectez maintenant votre voiture à myAudi: dans le MMI de votre voiture, accédez à la zone «Identifiant myAudi» («Utilisateurs» > «Identifiant myAudi»).',
                    'Saisissez à ce niveau vos données d’accès myAudi (ligne du haut: adresse e-mail; ligne du bas: mot de passe).'
                ],
            },
            {
                title: "3. Configurer l’horloge pour les heures de départ",
                bulletPoints: [
                    'L’optimisation du processus de recharge nécessite une horloge activée.*',
                    'Vous pouvez configurer l’horloge dans le menu «Recharge» («Voiture» > «Recharge et efficacité» > «Recharge»). Cliquez sur l’une des horloges prédéfinies et personnalisez-la selon vos besoins.'
                ],
                hints: [
                    "Si l’horloge n’est pas activée, votre voiture se rechargera immédiatement et le plus rapidement possible avec la puissance qui est la plus disponible (pas d’optimisation du processus de recharge). La fonction de protection contre les surcharges ne dépend pas d’une horloge activée; elle nécessite en revanche la connexion du système de recharge e-tron connect (disponible en option) à un système de gestion de l’énergie pour l’habitation (HEMS⁴) compatible.",
                ],
            },
            {
                title: "4. Activer l’horloge",
                bulletPoints: [
                    'Lorsque vous coupez le contact de votre voiture, une fenêtre permettant d’activer une horloge apparaît. Dans cette fenêtre, cliquez sur «Recharge».',
                    'Vos horloges s’affichent. Sélectionnez l’horloge souhaitée et activez-la en cliquant sur le symbole Horloge dans le réglage de l’horloge.',
                    'Répétez cette opération à chaque fois que vous souhaitez utiliser l’horloge.*'
                ],
                hints: [
                    "Une horloge activée s’éteint automatiquement lorsque le câble de recharge est débranché.",
                ],
            },
            {
                title: "Votre processus de recharge va maintenant s’effectuer selon vos réglages.",
                finished: true,
            },
        ]
    },
    {
        title: "Vue d’ensemble de la charge dans myAudi",
        icon: process.env.PUBLIC_URL + "/ico/polls-small.svg",
        subSteps: [
            {
                title: "1. Vue d’ensemble du processus de recharge en cours*",
                bulletPoints: [
                    'Ouvrez myAudi et sélectionnez votre système de recharge e-tron connect (disponible en option) dans le menu «Câbles et appareils». La page des informations détaillées du système de recharge e-tron connect apparaît.',
                    'Lors d’une recharge, le processus s’affiche en temps réel à ce niveau (si aucune recharge n’est en cours, c’est la recharge précédente qui s’affiche).'
                ],
                hints: [
                    "Vous pouvez également effectuer cette opération depuis un smartphone.",
                ],
            },
            {
                title: "2. Vue d’ensemble de l’historique et des statistiques de recharge*",
                bulletPoints: [
                    'Au bas de la page des informations détaillées se trouve votre historique de recharge.',
                    'Une vue d’ensemble du mois en cours et du mois passé s’affiche à ce niveau. Cliquez sur «Consulter l’historique de recharge» pour en savoir plus sur les processus de recharge.'
                ],
                hints: [
                    "Vous pouvez également effectuer cette opération depuis un smartphone.",
                ],
            },
            {
                title: "3. Exporter l’historique et les statistiques de recharge au format PDF*",
                bulletPoints: [
                    'La zone «Consulter l’historique de recharge» propose trois options: «Quantité chargée», «Durée de recharge», «Liste».',
                    'La zone «Liste» permet d’exporter l’historique de recharge. Pour cela, cliquez sur «Exporter la sélection».',
                    'L’historique de recharge s’affiche alors au format PDF et peut être enregistré.'
                ],
                hints: [
                    "L’historique et les statistiques de recharge ne peuvent être exportés que sur le portail myAudi, et non sur l’application.",
                ],
            },
            {
                title: "La première mise en service a été effectuée avec succès.",
                finished: true,
            },
        ]
    }
];

export const howToData_BE_NL: Array<StepContentProps> = [
    {
        title: "Eerste ingebruikname en gebruik",
        icon: process.env.PUBLIC_URL + '/ico/user-small.svg',
        subSteps: [
            {
                title: "",
                bulletPoints: [],
            },
            {
                title: "1. myAudi registratiepagina*",
                bulletPoints: [
                    "Aanmelden kan via de volgende link:",
                    "https://login.audi.com/registration",
                    "Activeer nu de automatische software-updates (aanbeveling)."
                ],
                hints: [
                    "U kunt de stappen ook met een smartphone uitvoeren."
                ],
            },
            {
                title: "2. Bevestigingsmail*",
                bulletPoints: [
                    'Open de bevestigingsmail die naar u is verzonden en klik op "Registratie in browser voltooien". ',
                    "De registratie is nu voltooid"
                ],
                hints: [
                    "U kunt de stappen ook met een smartphone uitvoeren.",
                ],
            },
            {
                title: "3. Voertuigsysteem in myAudi *",
                bulletPoints: [
                    'Na het voltooien van de registratie en nadat u voor het eerst hebt aangemeld op myAudi, wordt u verzocht om een voertuig toe te voegen.',
                    'Klik op "Voertuig aanmaken"'
                ],
                hints: [
                    "U kunt de stappen ook met een smartphone uitvoeren."
                ],
            },
            {
                title: "4. Invoer voertuigidentificatienummer (VIN)*",
                bulletPoints: [
                    'Voer nu het VIN (chassisnummer) van uw wagen in.',
                    "Het VIN of chassisnummer bevindt zich aan de bestuurderszijde in de benedenhoek van de voorruit of op het kentekenbewijs.",
                    'Klik vervolgens op "Voertuig aanmaken"'
                ],
                hints: [
                    "U kunt de stappen ook met een smartphone uitvoeren."
                ],
            },
            {
                title: "5. myAudi-accountverificatie*",
                bulletPoints: [
                    'Na het succesvol aanmaken van het voertuig wordt een accountverificatiepagina geopend.',
                    "Allereerst dient u ter verificatie verschillende persoonsgegevens in te vullen.",
                    'Stel vervolgens een 4-cijferige pincode in. Dit wordt de autorisatie.'
                ],
                hints: [
                    "U kunt de stappen ook met een smartphone uitvoeren."
                ],
            },
            {
                title: "6. Selectie van de verificatiemethode*",
                bulletPoints: [
                    'In de volgende stap kunt u een vervolgkeuzelijst gebruiken om het gewenste type verificatie op te geven.',
                    'Voorbeeld: Verificatie mobiele telefoon Voer uw mobiele telefoonnummer in en klik op "Verifiëren". Voer vervolgens het 6-cijferige nummer (mTan) dat u per sms hebt ontvangen in bij myAudi.'
                ],
                hints: [
                    "U kunt de stappen ook met een smartphone uitvoeren."
                ],
            },
            {
                title: "Uw myAudi-account is nu klaar!",
                finished: true, 
            },
        ]
    },
    {
        title: "Configuratie e-tron-laadsysteem connect",
        icon: process.env.PUBLIC_URL + "/ico/administrator-small.svg",
        subSteps: [
            {
                title: "1. Basisinstellingen",
                bulletPoints: [
                    "Wanneer u het optionele e-tron laadsysteem connect voor het eerst inschakelt, wordt u automatisch door verschillende stappen van de initiële configuratie geleid (bijv. selectie taal; land).*",
                    "Lees en bevestig de informatie over gegevensbescherming. Tik** hiervoor op de pijl naar beneden tot u het einde van de tekst hebt bereikt.",
                    "Activeer nu de automatische software-updates (aanbeveling)."
                ],
                hints: [
                    "Alle instellingen van de initiële configuratie kunt u ook achteraf configureren en wijzigen in het instellingenmenu.",
                    "Het touchscreen reageert alleen op drukgebaren en niet op swipen.",
                ],
            },
            {
                title: "2. Verbinding met thuisinternet*",
                bulletPoints: [
                    "In de volgende stap wordt u gevraagd om het optionele e-tron laadsysteem met uw thuisinternet te koppelen.",
                    "Het e-tron connect laadsysteem zoekt automatisch naar wifi-verbindingen. Selecteer uw wifi-verbinding en voer uw wifi-wachtwoord in.",
                    "Alternatief: U kunt het e-tron laadsysteem zonder extra adapter op een bestaand powerline thuisnetwerk (PLC) aansluiten."
                ],
                hints: [
                    "Zorg ervoor dat uw e-tron laadsysteem connect met internet is verbonden om alle functies te kunnen gebruiken.",
                ],
            },
            {
                title: "3. Verbinding met myAudi*",
                bulletPoints: [
                    'Maak nu verbinding met myAudi door de toegangsgegevens van uw myAudi-account (e-mailadres; wachtwoord) in te voeren.'
                ],
                hints: [
                    "U hebt uw persoonlijke myAudi-account nodig om het e-tron connect-laadsysteem weer te geven, om de in myAudi geladen energie te documenteren en om het elektriciteitstarief van myAudi naar het connect-laadsysteem over te dragen. Als alternatief kunt u elektriciteitstarieven ook overdragen naar het e-tron connect-laadsysteem via een compatibel Home Energy Management-systeem.",
                ],
            },
            {
                title: "4. Verbinding met HEMS (optioneel)",
                disclaimer: "⁴",
                bulletPoints: [
                    'Voer deze stap alleen uit als u een compatibel Home-Energy-Management-systeem (HEMS)* hebt. Klik anders op "Overslaan".',
                    "Controleer in de gebruiksaanwijzing van uw HEMS welke instellingen u moet uitvoeren op uw HEMS om de verbinding met het optionele e-tron laadsysteem connect tot stand te brengen.",
                    "Neem bij vragen contact op met uw elektricien."
                ],
                hints: [
                    "Voor het aanbieden van een volledige compatibiliteit werkt AUDI AG samen met een van de toonaangevende leveranciers van Home-Energy-Management-systemen, ",
                ],
            },
            {
                title: "5. HEMS-verbinding voltooien (optioneel)",
                disclaimer: "⁴",
                bulletPoints: [
                    "Na het uitvoeren van de instellingen op uw HEMS wordt deze weergegeven op het optionele e-tron-laadsysteem connect.*",
                    'Klik op uw HEMS en op de volgende pagina op "Bevestigen".',
                    "Uw HEMS-verbinding is nu aangesloten.",
                    'De HEMS-koppeling is nodig voor het gebruik van zonne-energie en de bescherming tegen stroomuitval.',
                    'Ook zonder HEMS kunt u de maximale laadstroom van uw laadsysteem permanent begrenzen in het instellingenmenu van het e-tron laadsysteem connect.'
                ],
                bulletDisclaimer: [
                    {
                        number: "¹",
                        position: 4
                    },
                ],
                hints: [
                    "Neem contact op met uw Audi partner als u problemen hebt bij het tot stand brengen van een verbinding met uw HEMS.",
                ],
            },
            {
                title: "6. Toewijzing pincode",
                bulletPoints: [
                    "U wordt dan gevraagd om een pincode in te stellen. Hiermee beschermt u het optionele e-tron connect laadsysteem tegen onbevoegd gebruik.*",
                    "Als u de pincode meerdere keren verkeerd invoert, hebt u de PUK nodig om deze te deblokkeren. U vindt de PUK in een brief die bij het e-tron connect laadsysteem wordt geleverd."
                ],
                hints: [
                    "Als het e-tron connect laadsysteem niet wordt gebruikt, wordt na korte tijd de schermvergrendeling geactiveerd. Klik ongeveer twee seconden op het slotpictogram om te ontgrendelen.",
                ],
            },
            {
                title: "Uw e-tron connect laadsysteem is nu klaar!",
                finished: true
            },
        ]
    },
    {
        title: "Uw elektriciteitstarief in myAudi opslaan",
        icon: process.env.PUBLIC_URL + "/ico/documents-small.svg",
        subSteps: [
            {
                title: "1. De structuur van uw elektriciteitstarief invoeren*",
                bulletPoints: [
                    'Open myAudi en selecteer uw optionele e-tron laadsysteem connect in het menu-item "Kabels & Apparaten". U komt dan op de gedetailleerde informatiepagina van het e-tron connect laadsysteem.',
                    'Scroll nu naar beneden naar het gedeelte "Kostenoptimalisatie" en klik op "Elektriciteitstariefgegevens opslaan".',
                    'U krijgt nu verschillende vragen over de structuur van uw elektriciteitstarief. Beantwoord deze vragen.'
                ],
                hints: [
                    "U kunt de elektriciteitstariefgegevens het best invoeren met behulp van een pc/laptop.",
                ],
            },
            {
                title: "2. Invoer van de prijs van uw elektriciteitstarief*",
                bulletPoints: [
                    'Nu kunt u de prijzen van uw elektriciteitstarief opslaan. Deze vindt u in uw elektriciteitscontract.**',
                    'Als uw elektriciteitstarief van het tijdstip van de dag afhankelijke prijsschommelingen bevat, kunt u deze hier ook toevoegen.'
                ],
                hints: [
                    "U kunt de elektriciteitstariefgegevens het best invoeren met behulp van een pc/laptop.",
                    "Werk de gegevens bij als uw elektriciteitstarief verandert.",
                ],
            },
            {
                title: "Uw elektriciteitstarief is nu opgeslagen en wordt gebruikt om het laden te optimaliseren.*",
                finished: true,
                hints: [
                    "De tariefgegevens uit myAudi worden alleen gebruikt om het laden te optimaliseren als er geen prijsinformatie uit het HEMS-systeem beschikbaar is en u een vertrektimer hebt geactiveerd.",
                ],
            },
        ]
    },
    {
        title: "Instellingen in het voertuig",
        icon: process.env.PUBLIC_URL + "/ico/car-small.svg",
        subSteps: [
            {
                title: "1. Definieer de hoofdgebruiker van het voertuig",
                bulletPoints: [
                    'Ga in de multimedia-interface (MMI) van uw voertuig naar het gedeelte "Hoofdgebruiker definiëren" ("Gebruiker"> "Gebruikersbeheer"> "Hoofdgebruiker definiëren").',
                    'Voer in de bovenste regel het e-mailadres in waarmee u zich op myAudi hebt geregistreerd.',
                    'Stel de hoofdgebruiker in door uw voertuigcode in de onderste regel in te voeren (te vinden op de sleutelhanger van uw contactsleutel: zie links).'
                ]
            },
            {
                title: "2. Verbinding van het voertuig met myAudi",
                bulletPoints: [
                    'Verbind nu uw voertuig met myAudi. Ga hiervoor naar het gedeelte "myAudi Login" in de MMI van uw voertuig ("Gebruiker"> "myAudi Login").',
                    'Voer hier uw myAudi-toegangsgegevens in (bovenste regel: e-mailadres; onderste regel: wachtwoord).'
                ],
            },
            {
                title: "3. Vertrektimer configureren",
                bulletPoints: [
                    'Een geactiveerde vertrektimer is een voorwaarde om het laadproces te optimaliseren.*',
                    'U kunt de vertrektimer configureren in het menu-item "Laden" ("Voertuig"> "Laden & efficiëntie"> "Laden"). Klik op een van de vooraf geconfigureerde vertrektimers en pas deze naar behoefte aan.'
                ],
                hints: [
                    "Zonder geactiveerde vertrektimer wordt uw voertuig direct en zo snel mogelijk geladen met het maximaal beschikbare vermogen (geen optimalisatie van het laadproces). De beschermingsfunctie tegen stroomuitval is onafhankelijk van een geactiveerde vertrektimer; hiervoor moet het optionele e-tron-laadsysteem met een compatibele HEMSd zijn gekoppeld.",
                ],
            },
            {
                title: "4. Vertrektimer activeren",
                bulletPoints: [
                    'Wanneer u het contact van uw voertuig uitzet, verschijnt er een venster waarin u een vertrektimer kunt activeren. Klik daarin op "Laden".',
                    'U ziet nu uw vertrektimers. Selecteer de gewenste vertrektimer en activeer deze door op het kloksymbool boven de instelling van de vertrektimer te klikken.',
                    'Herhaal dit proces elke keer dat u de vertrektimer wilt gebruiken.'
                ],
                hints: [
                    "Een geactiveerde vertrektimer gaat automatisch uit wanneer de laadkabel wordt losgekoppeld.",
                ],
            },
            {
                title: "Uw laadproces wordt nu automatisch uitgevoerd volgens uw instellingen.",
                finished: true,
            },
        ]
    },
    {
        title: "Laadoverzicht in myAudi",
        icon: process.env.PUBLIC_URL + "/ico/polls-small.svg",
        subSteps: [
            {
                title: "1. Overzicht van het actuele laadverloop*",
                bulletPoints: [
                    'Open myAudi en selecteer uw optionele e-tron laadsysteem connect in het menu-item "Kabels & Apparaten". U komt dan op de gedetailleerde informatiepagina van het e-tron connect laadsysteem.',
                    'Tijdens een actief laadproces wordt daar uw real-time laadverloop weergegeven (als u momenteel niet laadt, wordt het laadverloop in het verleden weergegeven).'
                ],
                hints: [
                    "U kunt de stappen ook met een smartphone uitvoeren.",
                ],
            },
            {
                title: "2. Overzicht van de laadgeschiedenis/laadstatistieken*",
                bulletPoints: [
                    'Als u op de gedetailleerde informatiepagina naar beneden scrolt, ziet u uw laadgeschiedenis.',
                    'Daar ziet u een overzicht van de actuele en de vorige maand. Klik voor meer details over de laadprocessen op "Naar de laadgeschiedenis".'
                ],
                hints: [
                    "U kunt de stappen ook met een smartphone uitvoeren.",
                ],
            },
            {
                title: "3. Export van de laadgeschiedenis/laadstatistieken als PDF*",
                bulletPoints: [
                    'Er zijn drie opties in het gebied "Naar de laadgeschiedenis": "Laadhoeveelheid"; "Laadduur"; "Lijst".',
                    'U kunt de laadgeschiedenis exporteren onder "Lijst". Klik hiervoor op "Selectie exporteren".',
                    'De laadgeschiedenis wordt dan als PDF weergegeven en kan worden opgeslagen.'
                ],
                hints: [
                    "U kunt de laadgeschiedenis/laadstatistieken alleen exporteren via het myAudi-portaal en niet via de app.",
                ],
            },
            {
                title: "Eerste ingebruikname met succes voltooid.",
                finished: true,
            },
        ]
    }
];

export const disclaimerHowTo: DisclaimerProps[] = [
    {
        number: 1,
        descr: '¹Die Funktion setzt voraus, dass Ihr individuell abgeschlossener Stromvertrag mit dem Energieversorger unterschiedliche Strompreise, z.B. Tag- oder Nachttarife beinhaltet. Die Information über die unterschiedlichen Strompreise müssen Sie in Ihrem myAudi Account oder einem kompatiblem Home Energy Management System (HEMS) hinterlegen. Bitte stellen Sie sicher, dass Ihr Strompreis korrekt eingegeben ist und bei Änderungen eine rechtzeitige Anpassung erfolgt. Bei Schnellladen (DC Laden) gibt es keine intelligenten Ladefunktionen.'
    },
    {
        number: 2,
        descr: '²Die Funktionen Solarstromnutzung und Blackoutschutz setzen die Kopplung mit einem kompatiblen Home Energy Management System voraus. Dieses wird nicht durch die AUDI AG angeboten und ist nicht Bestandteil des Lieferumfangs. Um eine volle Kompatibilität gewährleisten zu können arbeitet die AUDI AG mit den zwei führenden Anbietern von Home Energy Management Systemen - Hager Group und SMA Solar Technology AG - zusammen. Mindestanforderung für die Kommunikation zwischen dem optionalen e-tron Ladesystem connect und dem HEMS ist, dass das HEMS Kommunikation nach dem EEBus-Protokoll unterstützt. Auch wenn das EEBus-Protokoll unterstützt wird, kann die AUDI AG nur für die ausgewählten Home Energy Management Systeme der Kooperationspartner volle Kompatibilität mit dem optionalen e-tron Ladesystem connect garantieren. Bitte kontaktieren Sie ggf. Ihren HEMS Anbieter.'
    },
    {
        number: 3,
        descr: '³Die Ladeleistung ist abhängig von der Art des verwendeten Netzanschlusssteckers, der landesspezifischen Spannung, der Anzahl zur Verfügung stehender Phasen des Stromnetzes und der kundenspezifischen Hausinstallation. Sie kann daher individuell geringer ausfallen. Die Ladedauer der Batterie kann in Abhängigkeit von verschiedenen Faktoren wie z.B. der Umgebungstemperatur, der Verwendung anderer landesspezifischer Stecker und der Nutzung der Vorkonditionierungsfunktion (z.B. einer ferngesteuerten Klimatisierung des Fahrzeugs) variieren. Bei der Verwendung von Haushaltssteckern ist die Ladeleistung durch das e-tron Ladesystem begrenzt.',
    },
    {
        number: 4,
        descr: '⁴Ihr persönlicher myAudi Account ist notwendig für die Anzeige des optionalen e-tron Ladesystems connect und die Dokumentation der geladenen Energie in myAudi, sowie für die Übertragung des Stromtarifs aus myAudi an das Ladesystem connect. Stromtarife können alternativ auch über ein kompatibles Home Energy Management System an das optionale e-tron Ladesystem connect übertragen werden.'
    },
    {
        number: 5,
        descr: '⁵Bei Problemen mit der Verbindungsherstellung zu Ihrem HEMS kontaktieren Sie bitte Ihren Audi Partner.'
    },
    {
        number: 0,
        descr: 'DAT-Hinweis: \nWeitere Informationen zum offiziellen Kraftstoffverbrauch und den offiziellen spezifischen CO2-Emissionen neuer Personenkraftwagen können dem "Leitfaden über den Kraftstoffverbrauch, die CO2-Emissionen und den Stromverbrauch neuer Personenkraftwagen" entnommen werden, der an allen Verkaufsstellen und bei der DAT Deutsche Automobil Treuhand GmbH, Hellmuth-Hirth-Str. 1, D-73760 Ostfildern oder unter www.dat.de unentgeltlich erhältlich ist.'
    }
];

export const bgImages = [
    {
        id: 1,
        url: process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/Background.jpg",
    },
    {
        id: 2,
        url: process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/Background.jpg",
    },
    {
        id: 3,
        url: process.env.PUBLIC_URL + "/howToContent/3_EingabeStromtarif/Background.jpg",
    },
    {
        id: 4,
        url: process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/Background.jpg"
    },
    {
        id: 5,
        url: process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/Background.jpg",
    },
];

export const bgVideos = [
    process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/OverlayIn.mp4",
    process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/FadeIn.mp4",
    process.env.PUBLIC_URL + "/howToContent/3_EingabeStromtarif/OverlayIn.mp4",
    process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/FadeIn.mp4",
    process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/OverlayIn.mp4",
];

export const subImages_DE = [
    [
        "",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/Screens/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/Screens/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/Screens/Screen_2.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/Screens/Screen_3.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/Screens/Screen_4.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/Screens/Screen_5.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/Screens/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/Screens/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/Screens/Screen_2.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/Screens/Screen_3.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/Screens/Screen_4.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/Screens/Screen_5.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/3_EingabeStromtarif/Screens/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/3_EingabeStromtarif/Screens/Screen_1.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/Screens/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/Screens/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/Screens/Screen_2.png",
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/Screens/Screen_3.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/Screens/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/Screens/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/Screens/Screen_2.png",
        ""
    ]
];

export const subImages_EN = [
    [
        "",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/Screens/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/Screens/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/Screens/Screen_2.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/Screens/Screen_3.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/Screens/Screen_4.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/Screens/Screen_5.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/Screens/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/Screens/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/Screens/Screen_2.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/Screens/Screen_3.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/Screens/Screen_4.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/Screens/Screen_5.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/3_EingabeStromtarif/Screens/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/3_EingabeStromtarif/Screens/Screen_1.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/Screens/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/Screens/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/Screens/Screen_2.png",
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/Screens/Screen_3.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/Screens/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/Screens/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/Screens/Screen_2.png",
        ""
    ]
];

export const subImages_CH_DE = [
    [
        "",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/CH_DE/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/Screens/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/Screens/Screen_2.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/Screens/Screen_3.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/Screens/Screen_4.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/CH_DE/Screen_5.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/Screens/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/Screens/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/Screens/Screen_2.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/Screens/Screen_3.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/Screens/Screen_4.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/Screens/Screen_5.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/3_EingabeStromtarif/Screens/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/3_EingabeStromtarif/Screens/Screen_1.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/Screens/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/Screens/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/Screens/Screen_2.png",
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/Screens/Screen_3.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/Screens/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/Screens/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/Screens/Screen_2.png",
        ""
    ]
];

export const subImages_CH_IT = [
    [
        "",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/CH_IT/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/CH_IT/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/CH_IT/Screen_2.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/CH_IT/Screen_3.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/CH_IT/Screen_4.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/CH_IT/Screen_5.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/CH_IT/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/CH_IT/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/CH_IT/Screen_2.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/CH_IT/Screen_3.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/CH_IT/Screen_4.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/CH_IT/Screen_5.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/3_EingabeStromtarif/CH_IT/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/3_EingabeStromtarif/CH_IT/Screen_1.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/CH_IT/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/CH_IT/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/CH_IT/Screen_2.png",
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/CH_IT/Screen_3.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/CH_IT/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/CH_IT/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/CH_IT/Screen_2.png",
        ""
    ]
];

export const subImages_CH_FR = [
    [
        "",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/CH_FR/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/CH_FR/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/CH_FR/Screen_2.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/CH_FR/Screen_3.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/CH_FR/Screen_4.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/CH_FR/Screen_5.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/CH_FR/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/CH_FR/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/CH_FR/Screen_2.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/CH_FR/Screen_3.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/CH_FR/Screen_4.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/CH_FR/Screen_5.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/3_EingabeStromtarif/CH_FR/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/3_EingabeStromtarif/CH_FR/Screen_1.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/CH_FR/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/CH_FR/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/CH_FR/Screen_2.png",
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/CH_FR/Screen_3.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/CH_FR/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/CH_FR/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/CH_FR/Screen_2.png",
        ""
    ]
];

export const subImages_BE_FR = [
    [
        "",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/BE_FR/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/BE_FR/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/BE_FR/Screen_2.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/BE_FR/Screen_3.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/BE_FR/Screen_4.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/BE_FR/Screen_5.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/BE_FR/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/BE_FR/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/BE_FR/Screen_2.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/BE_FR/Screen_3.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/BE_FR/Screen_4.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/BE_FR/Screen_5.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/3_EingabeStromtarif/BE_FR/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/3_EingabeStromtarif/BE_FR/Screen_1.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/BE_FR/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/BE_FR/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/BE_FR/Screen_2.png",
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/BE_FR/Screen_3.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/BE_FR/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/BE_FR/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/BE_FR/Screen_2.png",
        ""
    ]
];

export const subImages_BE_NL = [
    [
        "",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/BE_NL/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/BE_NL/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/BE_NL/Screen_2.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/BE_NL/Screen_3.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/BE_NL/Screen_4.png",
        process.env.PUBLIC_URL + "/howToContent/1_AnlageMyAudi/BE_NL/Screen_5.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/BE_NL/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/BE_NL/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/BE_NL/Screen_2.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/BE_NL/Screen_3.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/BE_NL/Screen_4.png",
        process.env.PUBLIC_URL + "/howToContent/2_KonfigurationLadesystem/BE_NL/Screen_5.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/3_EingabeStromtarif/BE_NL/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/3_EingabeStromtarif/BE_NL/Screen_1.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/BE_NL/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/BE_NL/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/BE_NL/Screen_2.png",
        process.env.PUBLIC_URL + "/howToContent/4_EinstellungenFahrzeug/BE_NL/Screen_3.png",
    ],
    [
        process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/BE_NL/Screen_0.png",
        process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/BE_NL/Screen_1.png",
        process.env.PUBLIC_URL + "/howToContent/5_Ladeuebersicht/BE_NL/Screen_2.png",
        ""
    ]
];