export interface DisclaimerDataProps {
    number: number;
    descr: string;
}

export const disclaimerData_DE: DisclaimerDataProps[] = [
    {
        number: 1, 
        descr: 'ᵃ Die Ladeleistung ist abhängig von der Art des verwendeten Netzanschlusssteckers, der landesspezifischen Spannung und der Anzahl zur Verfügung stehender Phasen des Stromnetzes und der kundenspezifischen Hausinstallation. Sie kann daher individuell geringer ausfallen. Das Netzanschlusskabel mit Industriestecker und die Bedieneinheit des Ladesystems sind aufeinander abgestimmt und ermöglichen eine bestimmte Ladeleistung. Ein späterer Wechsel des Netzanschlusskabels zu einem anderen Industriestecker ist zwar technisch möglich, führt aber gegebenenfalls zu einer geringeren Ladeleistung.'
    },
    {
        number: 2,
        descr: 'ᵇ Die Funktion setzt voraus, dass der individuell abgeschlossene Stromvertrag des Kunden mit dem Energieversorger unterschiedliche Strompreise, z.B. Tag- oder Nachttarife beinhaltet. Die Information über die unterschiedlichen Strompreise muss durch den Kunden in seinem myAudi Account oder einem kompatiblem Home Energy Management System (HEMS) hinterlegt werden.'
    },
    {
        number: 3,
        descr: 'ᶜ 22 kW AC Laden erfordert die fahrzeugseitige Sonderausstattung eines On-board-Ladegeräts bis 22 kW (AC); optional verfügbar für den Audi e-tron 55 bzw. Audi e-tron Sportback 55; über den genauen Einsatztermin im e-tron 50 bzw. Audi e-tron Sportback 50, Audi e-tron S, Audi e-tron Sportback S sowie Audi e-tron GT und Audi RS e-tron GT informieren Sie sich bitte bei Ihrem Audi Partner.',
    },
    {
        number: 4,
        descr: 'ᵈ Um eine volle Kompatibilität gewährleisten zu können arbeitet die AUDI AG mit führenden Anbietern von Home Enery Management Systemen – SMA Solar Technology AG – zusammen. '
    },
    {
        number: 5,
        descr: 'ᵉ Erfordert die Registrierung und den Abschluss eines Hauptnutzervertrages bei myAudi sowie die Hauptnutzernominierung im Fahrzeug MMI. Die Verifizierung und Anmeldung als Hauptnutzer stellt sicher, dass Sie die Fahrzeugsteuerung (beispielsweise das Ver- und Entriegeln per App) nutzen können. Die Nominierung des Hauptnutzers im Fahrzeug MMI, d.h. die Verknüpfung von Ihrem myAudi Account und Ihrem Audi, ist erforderlich, um die Abfahrtszeit in der App einzustellen und an Ihren Audi zu übermitteln.'
    },
    {
        number: 0,
        descr: 'DAT-Hinweis: \nWeitere Informationen zum offiziellen Kraftstoffverbrauch und den offiziellen spezifischen CO2-Emissionen neuer Personenkraftwagen können dem "Leitfaden über den Kraftstoffverbrauch, die CO2-Emissionen und den Stromverbrauch neuer Personenkraftwagen" entnommen werden, der an allen Verkaufsstellen und bei der DAT Deutsche Automobil Treuhand GmbH, Hellmuth-Hirth-Str. 1, D-73760 Ostfildern oder unter www.dat.de unentgeltlich erhältlich ist.'
    }
];

export const disclaimerData_CH_DE: DisclaimerDataProps[] = [
    {
        number: 1, 
        descr: 'ᵃ Die Ladeleistung ist abhängig von der Art des verwendeten Netzanschlusssteckers, der landesspezifischen Spannung und der Anzahl zur Verfügung stehender Phasen des Stromnetzes und der kundenspezifischen Hausinstallation. Sie kann daher individuell geringer ausfallen. Das Netzanschlusskabel mit Industriestecker und die Bedieneinheit des Ladesystems sind aufeinander abgestimmt und ermöglichen eine bestimmte Ladeleistung. Ein späterer Wechsel des Netzanschlusskabels zu einem anderen Industriestecker ist zwar technisch möglich, führt aber gegebenenfalls zu einer geringeren Ladeleistung.'
    },
    {
        number: 2,
        descr: 'ᵇ Die Funktion setzt voraus, dass der individuell abgeschlossene Stromvertrag des Kunden mit dem Energieversorger unterschiedliche Strompreise, z.B. Tag- oder Nachttarife beinhaltet. Die Information über die unterschiedlichen Strompreise muss durch den Kunden in seinem myAudi Account oder einem kompatiblem Home Energy Management System (HEMS) hinterlegt werden.'
    },
    {
        number: 3,
        descr: 'ᶜ 22 kW AC Laden erfordert die fahrzeugseitige, optional verfügbare Sonderausstattung eines On-board-Ladegeräts bis 22 kW (AC); optional verfügbar für den Audi e-tron 55 bzw. Audi e-tron Sportback 55.',
    },
    {
        number: 4,
        descr: 'ᵈ Um eine volle Kompatibilität gewährleisten zu können arbeitet die AUDI AG mit führenden Anbietern von Home Enery Management Systemen – SMA Solar Technology AG – zusammen. '
    },
    {
        number: 5,
        descr: 'ᵉ Erfordert die Registrierung und den Abschluss eines Hauptnutzervertrages bei myAudi sowie die Hauptnutzernominierung im Fahrzeug MMI. Die Verifizierung und Anmeldung als Hauptnutzer stellt sicher, dass Sie die Fahrzeugsteuerung (beispielsweise das Ver- und Entriegeln per App) nutzen können. Die Nominierung des Hauptnutzers im Fahrzeug MMI, d.h. die Verknüpfung von Ihrem myAudi Account und Ihrem Audi, ist erforderlich, um die Abfahrtszeit in der App einzustellen und an Ihren Audi zu übermitteln.'
    },
];

export const disclaimerData_EN: DisclaimerDataProps[] = [
    {
        number: 1, 
        descr: 'ᵃ Charging capacity depends on the type of mains connector used, the country’s voltage and the number of available phases of the power network as well as the customer’s own domestic installation. It may therefore be lower in some cases. The mains connecting cable with industrial connector and the operating unit of the charging system are matched to each other and enable a certain charging capacity. Although it is technically feasible to switch the mains connecting cable to a different industrial connector later on, this could result in a lower charging capacity.'
    },
    {
        number: 2,
        descr: 'ᵇ The function requires that the customer’s individually concluded electricity contract with the energy provider includes variable electricity prices, e.g. daytime and night rates. The customer must add the information about the different electricity prices to their myAudi account or a compatible home energy management system (HEMS).'
    },
    {
        number: 3,
        descr: 'ᶜ For 22 kW AC charging it is necessary to order an optional vehicle on-board charger up to 22 kW (AC); optionally available for the Audi e-tron 55 and Audi e-tron Sportback 55; for information on when this item will be available for the e-tron 50, Audi e-tron Sportback 50, Audi e-tron S, Audi e-tron Sportback S, Audi e-tron GT and Audi RS e-tron GT, please contact your Audi partner.',
    },
    {
        number: 4,
        descr: 'ᵈ In order to be able to guarantee full compatibility, AUDI AG works with leading providers of home energy management systems – SMA Solar Technology.'
    },
    {
        number: 5,
        descr: 'ᵉ Requires the registration and conclusion of a key user agreement with myAudi as well as key user nomination in the vehicle’s MMI. Verification and registration as the key user ensures that you can use the remote services (for example, locking and unlocking by app). Nomination of the key user in the vehicle’s MMI, i.e. linking your myAudi account and your Audi, is necessary in order to set the departure time in the app and transmit it to your Audi.'
    },
    {
        number: 0,
        descr: 'DAT information: \nFurther information on official fuel consumption figures and the official specific CO2 emissions of new passenger cars can be found in the “Guide on the fuel economy, CO2 emission and power consumption of new passenger car models”, which is available free of charge at all sales dealerships and from DAT Deutsche Automobil Treuhand GmbH, Hellmuth-Hirth-Straße 1, D-73760 Ostfildern, Germany and at www.dat.de.'
    }
];

export const disclaimerData_CH_IT: DisclaimerDataProps[] = [
    {
        number: 1, 
        descr: 'ᵃ La potenza di ricarica dipende dal tipo di connettore di collegamento alla rete utilizzato, dalla tensione specifica del paese, dal numero delle fasi a disposizione della rete elettrica e dall’impianto domestico specifico del cliente. La potenza di ricarica individuale può quindi risultare inferiore. Il cavo di collegamento alla rete con connettore industriale e l’unità di comando del sistema di ricarica sono abbinati fra di loro e consentono una determinata potenza di ricarica. Cambiare il cavo di collegamento alla rete con un altro connettore industriale in un secondo momento è tecnicamente possibile, ma può comportare una minore potenza di ricarica.'
    },
    {
        number: 2,
        descr: 'ᵇ La funzione presuppone che il contratto per la fornitura di energia elettrica stipulato dal singolo cliente con l’azienda fornitrice di energia preveda prezzi dell’energia elettrica differenti, ad es. tariffe diurne o notturne. Le informazioni relative ai prezzi differenti per l’energia elettrica devono essere memorizzate dal cliente nel proprio account myAudi o in un Home Energy Management System (HEMS) compatibile.'
    },
    {
        number: 3,
        descr: 'ᶜ Una ricarica in CA da 22 kW richiede l’equipaggiamento speciale, opzionale sul lato veicolo di un caricabatterie On-board fino a 22 kW (CA); opzionale per Audi e-tron 55 e Audi e-tron Sportback 55.',
    },
    {
        number: 4,
        descr: 'ᵈ Al fine di garantire una piena compatibilità, AUDI AG collabora con leader nella fornitura di Home Energy Management System: SMA Solar Technology AG.'
    },
    {
        number: 5,
        descr: 'ᵉ Richiede la registrazione e la stipula di un contratto di utente principale con myAudi, nonché la nomina di utente principale nella MMI del veicolo. La verifica e la registrazione come utente principale le assicura la possibilità di utilizzare il controllo del veicolo (ad esempio l’apertura e la chiusura tramite app). La nomina dell’utente principale nell’MMI del veicolo, ovvero la connessione tra il suo account myAudi e la sua Audi, è necessaria per impostare l’orario di partenza nell’app e trasmetterlo alla sua Audi.'
    },
];

export const disclaimerData_CH_FR: DisclaimerDataProps[] = [
    {
        number: 1, 
        descr: 'ᵃ La puissance de charge dépend du type de prise de raccordement au réseau utilisé, de la tension spécifique au pays, du nombre de phases du réseau électrique disponibles et de l’installation domestique du client. En conséquence, elle peut être plus faible dans certaines circonstances. Le câble de raccordement au réseau avec prise industrielle et l’unité de commande du système de recharge sont compatibles et permettent d’obtenir une puissance de recharge précise. Un changement ultérieur du câble de raccordement au réseau avec une autre prise industrielle est certes techniquement possible, mais peut entraîner une réduction de la puissance de charge.'
    },
    {
        number: 2,
        descr: 'ᵇ La fonction nécessite que le contrat d’électricité conclu individuellement par le client avec le fournisseur d’énergie prévoie différents prix de l’électricité, par exemple des tarifs de jour et de nuit. Le client doit enregistrer les informations sur les différents prix de l’électricité dans son compte myAudi ou un système de gestion de l’énergie domestique compatible.'
    },
    {
        number: 3,
        descr: 'ᶜ La recharge CA 22 kW requiert que le véhicule soit équipé du chargeur embarqué jusqu’à 22 kW (CA); disponible en option pour l’Audi e-tron 55 et l’Audi e-tron Sportback 55.',
    },
    {
        number: 4,
        descr: 'ᵈ Afin de garantir une compatibilité totale, AUDI AG collabore avec des fournisseurs phares de systèmes de gestion de l’énergie domestique, dont SMA Solar Technology.'
    },
    {
        number: 5,
        descr: 'ᵉ Nécessite l’inscription et la conclusion d’un contrat d’utilisateur principal sur myAudi et la nomination de l’utilisateur principal dans le système MMI du véhicule. La vérification et l’inscription en tant qu’utilisateur principal vous permettent d’utiliser les fonctions de pilotage du véhicule (p. ex. le verrouillage et déverrouillage par le biais d’une application). La nomination de l’utilisateur principal dans le système MMI du véhicule, c’est-à-dire la liaison entre votre compte myAudi et votre Audi, est indispensable pour régler l’heure de démarrage dans l’application et la transmettre à votre Audi.'
    },
];

export const disclaimerData_BE_FR: DisclaimerDataProps[] = [
    {
        number: 1, 
        descr: '¹ La puissance de recharge dépend du type de connecteur secteur utilisé, de la tension du pays, du nombre de phases à disposition sur le réseau et de l’installation domestique. Elle peut donc être plus faible dans certains cas. Le câble de raccordement au secteur avec connecteur industriel et l’unité de commande du système de recharge sont adaptés l’un à l’autre et permettent une certaine puissance de recharge. L’installation d’un connecteur industriel différent sur le câble de raccordement au secteur est techniquement possible, mais se traduira par une puissance de recharge moindre.'
    },
    {
        number: 2,
        descr: '² Pour bénéficier de cette fonction, le client doit avoir conclu un contrat avec le fournisseur d’électricité qui comporte différents tarifs, par exemple des tarifs de jour et de nuit. Les informations relatives aux différents tarifs doivent être enregistrées par le client dans son compte myAudi ou dans un système de gestion de l’énergie pour l’habitation (HEMS) compatible.'
    },
    {
        number: 3,
        descr: '³ Une recharge à 22 kW (CA) nécessite, côté véhicule, le chargeur embarqué optionnel dont la puissance peut aller jusqu’à 22 kW (CA) ; disponible en option pour l’Audi e-tron 55 ou l’Audi e-tron Sportback 55 ; pour connaître la date de disponibilité précise sur l’Audi e-tron 50 ou l’Audi e-tron Sportback 50, l’Audi e-tron S, l’Audi e-tron Sportback S ainsi que l’Audi e-tron GT et l’Audi RS e-tron GT, veuillez vous renseigner auprès de votre distributeur Audi.',
    },
    {
        number: 4,
        descr: '⁴ Afin de pouvoir garantir une compatibilité totale, AUDI AG a établi une collaboration avec un des principaux fournisseurs de ce type de systèmes, SMA Solar Technology AG.'
    },
    {
        number: 5,
        descr: '⁵ Nécessite l’enregistrement et la signature d’un contrat utilisateur principal dans myAudi, ainsi que la désignation d’un utilisateur principal dans le système MMI de la voiture. La vérification et la connexion en tant qu’utilisateur principal garantissent que vous puissiez utiliser la commande du véhicule (par exemple le verrouillage et le déverrouillage depuis l’application). La désignation d’un utilisateur principal dans le système MMI de la voiture, qui correspond à l’association de votre compte myAudi à votre Audi, est nécessaire à la définition de l’heure de départ dans l’application et à sa communication à votre Audi.'
    },
    {
        number: 0,
        descr: "Mention légale de la DAT: \nLes valeurs d’émission de CO₂ et de consommation sont données à titre indicatif. Il est possible que les valeurs spécifiées pour une configuration spécifique d'un véhicule diffèrent de celles figurant sur le certificat de conformité lors de la livraison du véhicule. De plus, cette éventuelle différence est susceptible d’influencer les montants dus en vertu de la législation applicable (sociale, fiscale, etc.). Contactez votre distributeur pour toute information relative à la fiscalité de votre véhicule.",
    }
];

export const disclaimerData_BE_NL: DisclaimerDataProps[] = [
    {
        number: 1, 
        descr: '¹ Het laadvermogen is afhankelijk van het gebruikte type van netstekker, de landspecifieke spanning en het aantal beschikbare fasen van het elektriciteitsnet en de klantspecifieke woninginstallatie. Het kan daardoor in bepaalde gevallen lager uitvallen. Het netaansluitsnoer met industriële stekker en de bedieningseenheid van het laadsysteem zijn op elkaar afgestemd en maken een bepaald laadvermogen mogelijk. Hoewel het technisch mogelijk is de stekker van het netaansluitsnoer later te vervangen door een andere industriële stekker, kan dat soms resulteren in een geringer laadvermogen.'
    },
    {
        number: 2,
        descr: '² De functie veronderstelt dat het door de klant met de energieleverancier afgesloten elektriciteitscontract verschillende elektriciteitsprijzen inhoudt, bijvoorbeeld dag- of nachttarieven. De informatie over de verschillende elektriciteitsprijzen moet door de klant in zijn myAudi-account of een compatibel Home-Energy-Management-systeem (HEMS) worden ingevoerd.'
    },
    {
        number: 3,
        descr: '³ Voor 22 kW AC-laden moet het voertuig zijn uitgerust met een optionele on-board-lader tot 22 kW (AC); optioneel verkrijgbaar voor de Audi e-tron 55 of Audi e-tron Sportback 55; neem contact op met uw Audi verdeler voor informatie over de precieze ingebruiknametermijn in de Audi e-tron 50 of Audi e-tron Sportback 50, Audi e-tron S, Audi e-tron Sportback S en Audi e-tron GT en Audi RS e-tron GT.',
    },
    {
        number: 4,
        descr: '⁴ Voor het aanbieden van een volledige compatibiliteit werkt AUDI AG samen met een van de toonaangevende leveranciers van Home-Energy-Management-systemen, SMA Solar Technology AG.'
    },
    {
        number: 5,
        descr: '⁵ Vereist het registreren en het afsluiten van een hoofdgebruikerscontract bij myAudi, en het aanstellen van de hoofdgebruiker in de MMI van de wagen. De verificatie en aanmelding als hoofdgebruiker zorgt ervoor dat u de voertuigbediening (bijvoorbeeld vergrendelen en ontgrendelen via de app) kunt gebruiken. Het benoemen van de hoofdgebruiker in de MMI van de wagen, dat wil zeggen het koppelen van uw myAudi account met uw Audi, is noodzakelijk om de vertrektijd in de app in te stellen en naar uw Audi te verzenden.'
    },
    {
        number: 0,
        descr: 'DAT-aanwijzing: \nDe CO₂-emissie en verbruikswaarden worden alleen ter indicatieve titel gegeven. Het is mogelijk dat de waarden die zijn opgegeven voor een specifieke configuratie van een voertuig verschillen van de waarden die worden weergegeven op het gelijkvormigheidsattest bij aflevering van het voertuig. Bovendien kan dit eventuele verschil de krachtens de toepasselijke wetgeving (sociaal, fiscaal, enz.) verschuldigde bedragen mogelijks beïnvloeden. Contacteer uw verdeler voor alle informatie over de fiscaliteit van uw voertuig.'
    }
];