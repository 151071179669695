import { howToData_DE, howToData_EN, howToData_CH_FR, howToData_CH_IT, howToData_BE_NL, howToData_BE_FR } from "../../../datastructure/howToData";
import HowToContentViewBE_fr from "../howToContentView/howToContentViewBE_fr";
import HowToContentViewBE_nl from "../howToContentView/howToContentViewBE_nl";
import HowToContentViewEN from "../howToContentView/howToContentViewEN";
import HowToContentViewFR from "../howToContentView/howToContentViewFR";
import HowToContentViewIT from "../howToContentView/howToContentViewIT";
import HowToContentViewCH from "../howToContentView/howToContentViewCH";
import HowToContentView from "../howToContentView/howToContentViewV2";
import style from "./howToMain.module.scss";

interface HowToContentHandler {
  language: "DE" | "EN" | "FR" | "IT" | "CH" | "fr-BE" | "nl-BE";
  activeStep: number;
  activeSubStep: number;
  setActiveStep: (value: number) => void;
  setActiveSubStep: (value: number) => void;
  setScrollDirection: (direction: string) => void;
  videoIsPlaying: boolean;
  videoIsLoading: boolean;
  openDisclaimer: (active: boolean) => void;
  isDisclaimerOpen: boolean;
  handleTabs: (value: number) => void;
  getFullpageApi: (fullpageApi: any) => void;
}

const HowToContentHandler = ({
  language,
  activeStep,
  activeSubStep,
  setActiveStep,
  setActiveSubStep,
  setScrollDirection,
  videoIsPlaying,
  videoIsLoading,
  openDisclaimer,
  isDisclaimerOpen,
  handleTabs,
  getFullpageApi
}: HowToContentHandler) => {
  return (
    <>
      {
        language === "DE"
          ? (
            <div className={style.contentView} >
              <HowToContentView
                steps={howToData_DE}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                activeSubStep={activeSubStep}
                setActiveSubStep={setActiveSubStep}
                videoIsPlaying={videoIsPlaying}
                videoIsLoading={videoIsLoading}
                isDisclaimerOpen={isDisclaimerOpen}
                openDisclaimer={(open) => openDisclaimer(open)}
                setScrollDirection={(direction) => setScrollDirection(direction)}
                handleTabs={handleTabs}
                getFullpageApi={getFullpageApi}
                language={language}
              />
            </div>
          )
          : language === "CH"
            ? (
              <div className={style.contentView} >
                <HowToContentViewCH
                  steps={howToData_DE}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  activeSubStep={activeSubStep}
                  setActiveSubStep={setActiveSubStep}
                  videoIsPlaying={videoIsPlaying}
                  videoIsLoading={videoIsLoading}
                  isDisclaimerOpen={isDisclaimerOpen}
                  openDisclaimer={(open) => openDisclaimer(open)}
                  setScrollDirection={(direction) => setScrollDirection(direction)}
                  handleTabs={handleTabs}
                  getFullpageApi={getFullpageApi}
                  language={language}
                />
              </div>
            )
            : language === "EN"
              ? (
                <div className={style.contentView} >
                  <HowToContentViewEN
                    steps={howToData_EN}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    activeSubStep={activeSubStep}
                    setActiveSubStep={setActiveSubStep}
                    videoIsPlaying={videoIsPlaying}
                    videoIsLoading={videoIsLoading}
                    isDisclaimerOpen={isDisclaimerOpen}
                    openDisclaimer={(open) => openDisclaimer(open)}
                    setScrollDirection={(direction) => setScrollDirection(direction)}
                    handleTabs={handleTabs}
                    getFullpageApi={getFullpageApi}
                    language={language}
                  />
                </div>
              )
              : language === "IT"
                ? (
                  <div className={style.contentView} >
                    <HowToContentViewIT
                      steps={howToData_CH_IT}
                      activeStep={activeStep}
                      setActiveStep={setActiveStep}
                      activeSubStep={activeSubStep}
                      setActiveSubStep={setActiveSubStep}
                      videoIsPlaying={videoIsPlaying}
                      videoIsLoading={videoIsLoading}
                      isDisclaimerOpen={isDisclaimerOpen}
                      openDisclaimer={(open) => openDisclaimer(open)}
                      setScrollDirection={(direction) => setScrollDirection(direction)}
                      handleTabs={handleTabs}
                      getFullpageApi={getFullpageApi}
                      language={language}
                    />
                  </div>
                )
                : language === "fr-BE"
                  ? (
                    <div className={style.contentView}>
                      <HowToContentViewBE_fr
                        steps={howToData_BE_FR}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        activeSubStep={activeSubStep}
                        setActiveSubStep={setActiveSubStep}
                        videoIsPlaying={videoIsPlaying}
                        videoIsLoading={videoIsLoading}
                        isDisclaimerOpen={isDisclaimerOpen}
                        openDisclaimer={(open) => openDisclaimer(open)}
                        setScrollDirection={(direction) => setScrollDirection(direction)}
                        handleTabs={handleTabs}
                        getFullpageApi={getFullpageApi}
                        language={language}
                      />
                    </div>

                  )
                  : language === "nl-BE"
                    ? (
                      <div className={style.contentView} >
                        <HowToContentViewBE_nl
                          steps={howToData_BE_NL}
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                          activeSubStep={activeSubStep}
                          setActiveSubStep={setActiveSubStep}
                          videoIsPlaying={videoIsPlaying}
                          videoIsLoading={videoIsLoading}
                          isDisclaimerOpen={isDisclaimerOpen}
                          openDisclaimer={(open) => openDisclaimer(open)}
                          setScrollDirection={(direction) => setScrollDirection(direction)}
                          handleTabs={handleTabs}
                          getFullpageApi={getFullpageApi}
                          language={language}
                        />
                      </div>
                    )
                    : language === "FR" && (
                      <div className={style.contentView} >
                        <HowToContentViewFR
                          steps={howToData_CH_FR}
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                          activeSubStep={activeSubStep}
                          setActiveSubStep={setActiveSubStep}
                          videoIsPlaying={videoIsPlaying}
                          videoIsLoading={videoIsLoading}
                          isDisclaimerOpen={isDisclaimerOpen}
                          openDisclaimer={(open) => openDisclaimer(open)}
                          setScrollDirection={(direction) => setScrollDirection(direction)}
                          handleTabs={handleTabs}
                          getFullpageApi={getFullpageApi}
                          language={language}
                        />
                      </div>
                    )
      }
    </>
  )
}

export default HowToContentHandler;