import * as React from 'react';
import { useEffect, useState } from 'react';

// Hook
function useWindowSize() {
  const fullhdWidth = 1920;
  const fullhdHeight = 1080;
  const fullhdRatio = fullhdWidth / fullhdHeight; // About 1.7
  const [realImageSize, setRealImageSize] = useState({
    width: 0,
    height: 0,
  });

  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      if ((window.innerWidth / fullhdRatio) < window.innerHeight) {
        setRealImageSize({
          width: window.innerWidth,
          height: window.innerWidth / fullhdRatio,
        })
      } else {
        setRealImageSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }
    }



    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return [windowSize, realImageSize];
}

export default useWindowSize;