/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from '@material-ui/core';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { memo } from 'react';
import { StepContentProps } from '../../../datastructure/howToData';
import style from './howToStepperV2.module.scss';


interface HowToDotsProps {
    activeSubStep: number;
    step: StepContentProps;
    activeStep: number;
    language: "DE" | "EN" | "FR" | "IT" | "CH" | "fr-BE" | "nl-BE";
}

function HowToDots({ activeSubStep, step, activeStep, language }: HowToDotsProps) {
    const [prevStep, setPrevStep] = useState<number>(0);

    useEffect(() => {
        if (activeStep !== prevStep)
            setTimeout(() => {
                setPrevStep(activeStep);
            }, 250)
    }, [activeStep])

    useEffect(() => {
        setTimeout(() => {
            setPrevStep(0);
        }, 250)
    }, [language])


    return (
        <div className={style.howToDot}>
            {
                step.subSteps.map((subStep, subStepIndex) => {
                    if (activeStep === 0 && subStepIndex === 0) {
                        return null
                    } else {
                        return (
                            <div
                                key={"circle_ " + subStepIndex}
                                className={style.howToDot_cirlce}
                                style={{
                                    background: activeSubStep === subStepIndex ?
                                        (activeStep === prevStep ? 'white' : 'rgba(255,255,255, 0.25)') :
                                        'rgba(255,255,255, 0.25)'
                                }}
                            />
                        )
                    }
                })
            }
        </div>
    )
}

export interface HowToStepperProps {
    steps: Array<StepContentProps>;
    activeStep: number;
    activeSubStep: number;
    scrollTo: (step: number) => void;
    videoIsPlaying: boolean;
    language: "DE" | "EN" | "FR" | "IT" | "CH" | "fr-BE" | "nl-BE";
}


//Connector
interface HowToConnectProps {
    progress: number;
    active: boolean;
}

function HowToConnect({ progress, active }: HowToConnectProps) {
    let translate = -101 + progress;
    return (
        <div
            className={style.HowToConnect}
            style={{ height: "100%" }}
        >
            <div
                className={style.HowToConnect_progress}
                style={{
                    transform: 'translateY(' + translate + '%)',
                }}
            />
        </div>
    )
}

//Stepper
function HowToStepper({ steps, activeStep, activeSubStep, scrollTo, videoIsPlaying, language }: HowToStepperProps) {
    const handleSubSectionJump = (index: number) => {
        let section = '';
        switch (index) {
            case 0:
                section = "#MyAudiAccount";
                break;
            case 1:
                section = "#Konfiguration";
                break;
            case 2:
                section = "#Stromtarif";
                break;
            case 3:
                section = "#Einstellungen";
                break;
            case 4:
                section = "#Ladeübersicht";
                break;

        }
        window.location.hash = section;
    }

    return (
        <div >
            {
                steps.map((step, index) => {
                    let subStepsLenght = step.subSteps.length;
                    let progress: number = 100;
                    //let showCircle: boolean = true;

                    if (index === activeStep && activeStep !== undefined && activeSubStep !== undefined) {
                        progress = (100 / subStepsLenght) * (activeSubStep + 1);
                    } else if (activeStep !== undefined && index > activeStep) {
                        progress = 0
                        //showCircle = false;
                    }

                    return (
                        <div
                            className={style.stepContainer}
                            key={step.title + "_" + index}
                            style={{ height: "fit-content" }}
                        >
                            <a target="_parent">
                                <div className={style.stepContainer_title}>
                                    <Button
                                        disableRipple
                                        onClick={() => {
                                            if (!videoIsPlaying) {
                                                scrollTo(index);
                                                handleSubSectionJump(index)
                                            }
                                        }}
                                    >
                                        <div className={style.icon}>
                                            <div
                                                className={(index === activeStep) &&
                                                    ((activeStep === 0 && activeSubStep !== 0) ||
                                                        (activeStep !== 0)) ? style.icon_circle_selected : style.icon_circle}
                                            >
                                                <div className={style.icon_img}>
                                                    <img src={step.icon} alt={step.icon} />
                                                </div>
                                            </div>
                                        </div>
                                    </Button>

                                    <h4
                                        onClick={() => {
                                            if (!videoIsPlaying) {
                                                scrollTo(index);
                                                handleSubSectionJump(index)
                                            }
                                        }}
                                        className={style.titleText}
                                        style={{
                                            opacity: activeStep === 0 && activeSubStep === 0 ? 1 : 0,
                                            pointerEvents: activeStep === 0 && activeSubStep === 0 ? "inherit" : "none",
                                            visibility: activeStep === 0 && activeSubStep === 0 ? 'visible' : 'hidden',
                                        }}>
                                        {step.title}
                                    </h4>

                                </div>
                            </a>

                            <div
                                className={style.howToDotsContainer}
                                style={{
                                    maxHeight: activeStep === 0 && activeSubStep === 0 ? 0 : index === activeStep ? '20vh' : 0
                                }}>
                                <HowToDots
                                    step={step}
                                    activeSubStep={activeSubStep}
                                    activeStep={activeStep}
                                    language={language}
                                />
                            </div>

                        </div>
                    )
                })
            }

        </div>
    );
}

export default memo(HowToStepper);