import * as React from 'react';
import { useRef, useEffect } from 'react';
import useFake3D from './useFake3D';

export interface Fake3DViewerProps {
    frames: number;
    url: string;
    currDragged: (active: boolean) => void;
    deactivate3D: boolean;
    cacheImgs?: boolean;
    windowIsLoaded: boolean;
}

function Fake3DViewer({ frames, url, currDragged, deactivate3D, cacheImgs, windowIsLoaded }: Fake3DViewerProps) {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const contextRef = useRef<CanvasRenderingContext2D | null>(null);
  

    const { onStartDrag, onDrag, onDragEnd, reset, cacheImages, handleResize, }
        = useFake3D({ frames, url, canvasRef, contextRef, currDragged, deactivate3D, windowIsLoaded });

    useEffect(() => {
        const canvas = canvasRef.current;
        handleResize();
        if (canvas) {
            const context = canvas.getContext("2d");

            canvas.addEventListener('mousedown', onStartDrag);
            canvas.addEventListener('mousemove', onDrag);
            canvas.addEventListener('mouseup', onDragEnd);


            canvas.addEventListener('touchstart', onStartDrag);
            canvas.addEventListener('touchmove', onDrag);
            canvas.addEventListener('touchend', onDragEnd);

            contextRef.current = context;
            reset();

        }

        window.addEventListener('resize', handleResize);

        if (cacheImgs) {
            cacheImages();
        }
        return () => {
            window.removeEventListener('resize', handleResize);
            canvas?.removeEventListener("mousedown", onStartDrag);
            canvas?.removeEventListener("mousemove", onDrag);
            canvas?.removeEventListener("mouseup", onDragEnd);

            canvas?.removeEventListener('touchstart', onStartDrag);
            canvas?.removeEventListener('touchmove', onDrag);
            canvas?.removeEventListener('touchend', onDragEnd);


        }
    }, [frames, onDrag, onDragEnd, onStartDrag, reset, url, cacheImages, handleResize, cacheImgs]);


    return (
        <div>
            <canvas ref={canvasRef} />
        </div>
    );
}

export default React.memo(Fake3DViewer);