import React from 'react';
import { useState } from 'react';
import './App.css';
import Main from './components/main';
import InitialLoader from './components/ui/loader/initialLoader';
import Screenblocker from './components/ui/screenblocker/screenblocker';
import { BrowserRouter } from "react-router-dom";
//import { subPages } from './datastructure/benefitData';



function App() {

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [windowIsLoaded, setwindowIsLoaded] = useState<boolean>(false);


  //const [cacheBenefitVideos, setCacheBenefitVideos] = useState(false);

  /*
  const cacheVideos = () => {

    if (!cacheBenefitVideos)
      subPages.map((subPage, subIndex) => {
        let video = document.createElement('video');
        video.preload = 'auto';
        video.src = subPage.introVideoUrl;
      })

      subPages.map((subPage, subIndex) => {
        let video = document.createElement('video');
        video.preload = 'auto';
        video.src = subPage.outroVideoUrl;

        if (subIndex === subPages.length - 1) {
          video.oncanplaythrough = () => {
            setCacheBenefitVideos(true);
          }
        }

      })

      subPages.map((subPage, subIndex) => {
        let video = document.createElement('video');
        video.preload = 'auto';
        video.src = subPage.outroVideoUrl;

        if (subIndex === subPages.length - 1) {
          video.oncanplaythrough = () => {
            setCacheBenefitVideos(true);
          }
        }

      })
  }

  if (!cacheBenefitVideos) {
    //cacheVideos();
  }
 */

  let timer: number, timeoutVal: number = 1000;

  window.addEventListener('load', function () {
    window.clearTimeout(timer); // prevent errant multiple timeouts from being generated
    timer = window.setTimeout(() => {
      if (isLoading) {
        setwindowIsLoaded(true);
        setIsLoading(false);
      }
    }, timeoutVal);
  })

  function checkMobile() {
    if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true
    }
    else {
      return false
    }
  }

  let isMobile = checkMobile();


  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <div className="App">

        <Main windowIsLoaded={windowIsLoaded} />
        <Screenblocker isMobile={isMobile} />
        <InitialLoader isLoading={isLoading} />

        {/* <iframe src="http://private-charging.de" title="something" width="1000" height="800" /> */}
      </div>
    </BrowserRouter>
  );
}

export default App;
