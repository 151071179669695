import * as React from 'react';
import style from './languageSelect.module.scss';



export interface LanguageSelectProps {
    language: "DE" | "EN" | "IT" | "FR" | "CH" | "fr-BE" | "nl-BE",
    onClick: (value: "DE" | "EN" | "IT" | "FR" | "CH" | "fr-BE" | "nl-BE") => void;
}

const LanguageSelect = ({ language, onClick }: LanguageSelectProps) => {
    const userLang = navigator.language;

    const [selectedLang, setSelectedLang] = React.useState<"DE" | "EN" | "IT" | "FR" | "CH" | "fr-BE" | "nl-BE">(
        (userLang === "EN")
            ? userLang
            : "EN"
    );
    const languages: string[] = ["EN"];
    
    React.useEffect(() => {
        onClick(selectedLang as "DE" | "EN" | "IT" | "FR" | "CH" | "fr-BE" | "nl-BE");
    }, [userLang])


    return (
        <div className={style.root} style={{display: 'none'}}>
            {
                languages.map((language, languageIndex) => (
                    <>
                        <p className={style.text}
                            style={{
                                color: language === selectedLang ? 'white' : "#e5e5e5",
                                opacity: language === selectedLang ? 1 : 0.5,
                            }}
                            onClick={() => {
                                setSelectedLang(language as "DE" | "EN" | "IT" | "FR" | "CH" | "fr-BE" | "nl-BE");
                                onClick(language as "DE" | "EN" | "IT" | "FR" | "CH" | "fr-BE" | "nl-BE");
                            }}>
                            {language}
                        </p>
                        {languageIndex < languages.length - 1 &&
                            <div className={style.divider} />
                        }
                    </>
                ))
            }
        </div>
    );
}

export default LanguageSelect;