import * as React from "react";
import { useState } from "react";
import { howToData_CH_DE, howToData_DE, howToData_EN, howToData_CH_FR, howToData_CH_IT, howToData_BE_FR, howToData_BE_NL } from "../../../datastructure/howToData";
import HowToBackground from "../howToBG/howToBG";
import HowToStepperV2 from "../howToStepper/howToStepperV2";
import style from "./howToMain.module.scss";
import { useEffect, useRef } from "react";
import { IconButton } from "@material-ui/core";
import HowToContentHandler from "./howToContentHandler";

export interface HowToMainProps {
    onChangeStep: (stepIndex: number) => void;
    openDisclaimer: (open: boolean) => void;
    isDisclaimerOpen: boolean;
    reset: boolean;
    handleTabs: (value: number) => void;
    language: "DE" | "EN" | "FR" | "IT" | "CH" | "fr-BE" | "nl-BE";
}

function HowToMain({ onChangeStep, openDisclaimer, isDisclaimerOpen, reset, handleTabs, language }: HowToMainProps) {
    const [activeStep, setActiveStep] = useState<number>(0);
    const [activeSubStep, setActiveSubStep] = useState<number>(0);
    const [videoIsPlaying, setVideoIsPlaying] = useState<boolean>(false);
    const [videoIsLoading, setVideoIsLoading] = useState<boolean>(false);
    const [scrollDirection, setScrollDirection] = useState<string | null>(null);
    const [renderContent, setRenderContent] = useState<boolean>(true);
    const useApi = useRef<any>();

    let howToTitle =
        (language === "DE" || language === "CH")
            ? ["Erstinbetriebnahme", "& Nutzung"]
            : language === "EN"
                ? ["Initial setup", "& usage"]
                : language === "IT"
                    ? ["Prima messa in funzione", "e utilizzo"]
                    : language === "nl-BE"
                        ? ["Eerste ingebruikname", "en gebruik"]
                        : language === "fr-BE"
                            ? ["Première mise en", "service et utilisation"]
                            : ["Mise en service initiale", "et utilisation"];

    if (activeStep === 0 && 1 <= activeSubStep) {
        if (language === "DE") {
            howToTitle[0] = "Anlage myAudi";
            howToTitle[1] = "Account";
        } else if (language === "CH") {
            howToTitle[0] = "Anlage myAudi";
            howToTitle[1] = "Account";
        } else if (language === "FR") {
            howToTitle[0] = "Création d’un";
            howToTitle[1] = "compte myAudi";
        } else if (language === "IT") {
            howToTitle[0] = "Creazione account";
            howToTitle[1] = "myAudi";
        } else if (language === "nl-BE") {
            howToTitle[0] = "Bijlage bij";
            howToTitle[1] = "myAudi-account";
        } else if (language === "fr-BE") {
            howToTitle[0] = "Création d’un";
            howToTitle[1] = "compte myAudi";
        } else {
            howToTitle[0] = "Create a";
            howToTitle[1] = "myAudi Account";
        }
    } else if (activeStep === 1) {
        if (language === "DE") {
            howToTitle[0] = "Konfiguration e-tron";
            howToTitle[1] = "Ladesystem connect";
        } else if (language === "CH") {
            howToTitle[0] = "Konfiguration e-tron";
            howToTitle[1] = "Ladesystem connect";
        } else if (language === "FR") {
            howToTitle[0] = "Configuration du système de";
            howToTitle[1] = "recharge e-tron connect";
        } else if (language === "IT") {
            howToTitle[0] = "Configurazione sistema di";
            howToTitle[1] = "ricarica e-tron connect";
        } else if (language === "nl-BE") {
            howToTitle[0] = "Configuratie e-tron";
            howToTitle[1] = "-laadsysteem connect";
        } else if (language === "fr-BE") {
            howToTitle[0] = "Configuration du système de";
            howToTitle[1] = "charge e-tron connect";
        } else {
            howToTitle[0] = "Configure the e-tron";
            howToTitle[1] = "charging system connect";
        }
    } else if (activeStep === 2) {
        if (language === "DE") {
            howToTitle[0] = "Hinterlegen Ihres";
            howToTitle[1] = "Stromtarifs in myAudi";
        } else if (language === "CH") {
            howToTitle[0] = "Hinterlegen Ihres";
            howToTitle[1] = "Stromtarifs in myAudi";
        } else if (language === "FR") {
            howToTitle[0] = "Enregistrement de vos tarifs";
            howToTitle[1] = "d’électricité dans myAudi";
        } else if (language === "IT") {
            howToTitle[0] = "Memorizzare la sua tariffa";
            howToTitle[1] = "elettrica in myAudi";
        } else if (language === "nl-BE") {
            howToTitle[0] = "Uw elektriciteitstarief";
            howToTitle[1] = "in myAudi opslaan";
        } else if (language === "fr-BE") {
            howToTitle[0] = "Créer votre tarif d’électricité";
            howToTitle[1] = "dans myAudi";
        } else {
            howToTitle[0] = "Add your electricity";
            howToTitle[1] = "tariffs in myAudi";
        }
    } else if (activeStep === 3) {
        if (language === "DE") {
            howToTitle[0] = "Einstellungen";
            howToTitle[1] = "im Fahrzeug";
        } else if (language === "CH") {
            howToTitle[0] = "Einstellungen";
            howToTitle[1] = "im Fahrzeug";
        } else if (language === "FR") {
            howToTitle[0] = "Réglages dans";
            howToTitle[1] = "le véhicule";
        } else if (language === "IT") {
            howToTitle[0] = "Impostazioni";
            howToTitle[1] = "nel veicolo";
        } else if (language === "nl-BE") {
            howToTitle[0] = "Instellingen in";
            howToTitle[1] = "het voertuig";
        } else if (language === "fr-BE") {
            howToTitle[0] = "Réglages dans";
            howToTitle[1] = "la voiture";
        } else {
            howToTitle[0] = "Settings";
            howToTitle[1] = "in the vehicle";
        }
    } else if (activeStep === 4) {
        if (language === "DE") {
            howToTitle[0] = "Ladeübersicht";
            howToTitle[1] = "in myAudi";
        } else if (language === "CH") {
            howToTitle[0] = "Ladeübersicht";
            howToTitle[1] = "in myAudi";
        } else if (language === "FR") {
            howToTitle[0] = "Aperçu de la recharge";
            howToTitle[1] = "dans myAudi";
        } else if (language === "IT") {
            howToTitle[0] = "Panoramica della";
            howToTitle[1] = "ricarica in myAudi";
        } else if (language === "nl-BE") {
            howToTitle[0] = "Laadoverzicht";
            howToTitle[1] = "in myAudi";
        } else if (language === "fr-BE") {
            howToTitle[0] = "Vue d’ensemble de la";
            howToTitle[1] = "charge dans myAudi";
        } else {
            howToTitle[0] = "Charging overview";
            howToTitle[1] = "in myAudi";
        }
    }

    useEffect(() => {
        if (reset) {
            setActiveStep(0);
            setActiveSubStep(0);
        }
    }, [reset])

    useEffect(() => {
        let languageContainer = document.getElementById("languageContainer");
        if (languageContainer)
            if (videoIsPlaying) {
                // @ts-nocheck
                languageContainer.style.pointerEvents = "none";
            } else {
                // @ts-nocheck
                languageContainer.style.pointerEvents = "all";
            }
    }, [videoIsPlaying])

    useEffect(() => {
        setRenderContent(false);
        setTimeout(() => {
            window.location.hash = '';
            setActiveStep(0);
            setActiveSubStep(0);
            setRenderContent(true);
        }, 0);
    }, [language])

    return (
        <div className={style.howToMain}>
            <HowToBackground
                activeStep={activeStep !== undefined ? activeStep : 0}
                activeSubStep={activeSubStep !== undefined ? activeSubStep : 0}
                setVideoIsPlaying={(active) => setVideoIsPlaying(active)}
                setVideoIsLoading={(active) => setVideoIsLoading(active)}
                videoIsPlaying={videoIsPlaying}
                scrollDirection={scrollDirection}
                language={language}
            />

            <div className={style.title}>
                <h1>{howToTitle[0]}</h1>
                <h1>{howToTitle[1]}</h1>
            </div>

            <div className={style.content}>
                <div className={style.stepper}>
                    <HowToStepperV2
                        steps={
                            language === "DE"
                                ? howToData_DE
                                : language === "EN"
                                    ? howToData_EN
                                    : language === "IT"
                                        ? howToData_CH_IT
                                        : language === "FR"
                                            ? howToData_CH_FR
                                            : language === "fr-BE"
                                                ? howToData_BE_FR
                                                : language === "nl-BE"
                                                    ? howToData_BE_NL
                                                    : howToData_CH_DE

                        }
                        activeStep={activeStep}
                        scrollTo={() => { setScrollDirection("click") }}
                        activeSubStep={activeSubStep}
                        videoIsPlaying={videoIsPlaying}
                        language={language}
                    />
                </div>
                {reset && renderContent &&
                    <HowToContentHandler
                        activeStep={activeStep}
                        setActiveStep={(stepIndex) => {
                            setActiveStep(stepIndex);
                            onChangeStep(stepIndex);
                        }}
                        activeSubStep={activeSubStep}
                        setActiveSubStep={setActiveSubStep}
                        videoIsPlaying={videoIsPlaying}
                        videoIsLoading={videoIsLoading}
                        isDisclaimerOpen={isDisclaimerOpen}
                        openDisclaimer={(open) => openDisclaimer(open)}
                        setScrollDirection={(direction) => setScrollDirection(direction)}
                        handleTabs={handleTabs}
                        getFullpageApi={(fullpageApi) => useApi.current = fullpageApi}
                        language={language}
                    />
                }

            </div>
            <div className={style.stepperButtons}>
                <IconButton
                    disableRipple
                    style={{
                        visibility: activeStep === 0 && activeSubStep === 0 ? 'hidden' : 'visible',
                        opacity: activeStep === 0 && activeSubStep === 0 ? 0 : 1,
                        pointerEvents: activeStep === 0 && activeSubStep === 0 ? 'none' : 'all',
                    }}
                    className={style.stepperButton}
                    onClick={() => {
                        let fullpageApi = useApi.current
                        if (fullpageApi) {
                            fullpageApi.moveSectionUp();
                        }
                    }}><img className={style.stepperButtonIcon} src={"./ico/expandLess.png"} alt="stepUp" />
                </IconButton>
                <IconButton
                    disableRipple
                    style={{
                        visibility: activeStep === 4 && activeSubStep === 3 ? 'hidden' : 'visible',
                        opacity: activeStep === 4 && activeSubStep === 3 ? 0 : 1,
                        pointerEvents: activeStep === 4 && activeSubStep === 3 ? 'none' : 'all',
                    }}
                    className={style.stepperButton}
                    onClick={() => {
                        let fullpageApi = useApi.current
                        if (fullpageApi) {
                            fullpageApi.moveSectionDown();
                        }
                    }}><img className={style.stepperButtonIcon} style={{ transform: 'rotate(180deg)' }} src={"./ico/expandLess.png"} alt="stepDown" />
                </IconButton>
            </div>
        </div>
    );
}

export default React.memo(HowToMain);
