import { CircularProgress } from '@material-ui/core';
import * as React from 'react';
import { useStylesAudi } from './loader';
import style from './initialLoader.module.scss';

export interface InitialLoaderProps {
    isLoading: boolean;
}

function InitialLoader({isLoading}: InitialLoaderProps) {
    const classes = useStylesAudi();
    return (
        <div className={isLoading ? style.initialLoader : style.initialLoader_finished}>
            <CircularProgress color="inherit" className={classes.top} />F
        </div>
    );
}

export default InitialLoader;