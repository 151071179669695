import * as React from 'react';
import { BulletDisclaimerProps, SubStepContentProps } from '../../../datastructure/howToData';
import style from './listContent.module.scss';
import { InView } from 'react-intersection-observer';


//BulletPoints
interface BulletPointsProps {
    bulletPoints: string[] | undefined;
    title: string;
    bulletDisclaimer: BulletDisclaimerProps[] | undefined;
    openDisclaimer: (active: boolean) => void;
    handleTabs: (value: number) => void;
    activeStep: number;
    activeSubStep: number;
    language: "DE" | "EN" | "FR" | "IT" | "CH" | "fr-BE" | "nl-BE";
}


export function BulletPoints({ bulletPoints, title, bulletDisclaimer, openDisclaimer, handleTabs, activeStep, activeSubStep, language }: BulletPointsProps) {

    return (
        <ul key={title + "_UL"} className={style.bulletlist}>
            {bulletPoints ?
                bulletPoints!.map((bullet, bulletIndex) => (
                    <div key={bullet + "_link_" + bulletIndex} className={style.listElemets}>
                        {
                            bullet === 'https://login.audi.com/registration' && bulletIndex === 1 ?
                                <li className={style.listTest_link} style={{}} >
                                    <a href="https://login.audi.com/registration" target="_blank" rel="noreferrer" >
                                        https://login.audi.com/registration
                                    </a>
                                </li>
                                :
                                <li className={style.listTest} >
                                    <span>
                                        {bullet}
                                        {
                                            bulletDisclaimer?.map((bDiscl, bDisclIndex) => {
                                                if (bulletIndex === bDiscl.position) {
                                                    return (
                                                        <span style={{ cursor: 'pointer' }} onClick={() => openDisclaimer(true)} key={bDiscl.number + "_" + bDisclIndex + "_"}>
                                                            {bDiscl.number}
                                                        </span>
                                                    )
                                                }
                                            })
                                        }
                                    </span>
                                </li>
                        }
                    </ div>
                ))
                :
                <div className={style.finishContainer} key={"finish"}>
                    <div>
                        <img src={process.env.PUBLIC_URL + '/ico/system-select-large.svg'} alt="successLogo" />
                        <div>
                            <h3 style={{ margin: activeStep === 4 && activeSubStep === 3 ? 0 : 15 }}>
                                {title}
                            </h3>
                            {activeStep === 4 && activeSubStep === 3 &&
                                <p style={{ cursor: 'pointer', margin: 0, transform: 'translateY(5px)' }} onClick={() => handleTabs(0)}>
                                    {
                                        (language === "DE" || language === "CH")
                                            ? "Zurück zu den Vorteilen >"
                                            : language === "EN"
                                                ? "Back to the benefits >"
                                                : language === "IT"
                                                    ? "Tornare ai vantaggi >"
                                                    : language === "nl-BE"
                                                        ? "Terug naar de voordelen"
                                                        : (language === "fr-BE" || language === "FR")
                                                        && "Retour aux avantages >"
                                    }
                                </p>
                            }
                        </div>
                    </div>
                </div>
            }
        </ul >
    )
}


//ListContent
interface ListContentProps {
    subSteps: Array<SubStepContentProps>;
    onView: (value: number) => void;
    stepIndex: number;
    selectedStep: number;
    selectedSubStep: number;
    openDisclaimer: (active: boolean) => void;
    handleTabs: (value: number) => void;
    language: "DE" | "EN" | "IT" | "FR";
}

export function ListContent({ subSteps, onView, selectedStep, stepIndex, selectedSubStep, openDisclaimer, handleTabs, language }: ListContentProps) {
    return (
        <>
            {subSteps.map((step, subStepIndex) => {
                return (
                    <InView
                        as="div"
                        key={step.title + "stepIndex" + subStepIndex}
                        id={"step_" + stepIndex + "_substep_" + subStepIndex}
                        className={style.subStep}
                        onChange={(inView, entry) => {
                            if (inView) {
                                onView(subStepIndex)
                            }
                        }}
                        style={{ opacity: (selectedSubStep === subStepIndex) ? 1 : 0 }}
                    >
                        <div className={style.subSteps_content} >
                            {!step.finished &&
                                <h3>
                                    {step.title}
                                    {step.disclaimer &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => openDisclaimer(true)}>
                                            {step.disclaimer}
                                        </span>
                                    }
                                </h3>
                            }
                            <BulletPoints
                                bulletPoints={step.bulletPoints}
                                title={step.title}
                                bulletDisclaimer={step.bulletDisclaimer}
                                openDisclaimer={() => { openDisclaimer(true) }}
                                activeStep={selectedStep}
                                activeSubStep={selectedSubStep}
                                handleTabs={handleTabs}
                                language={language}
                            />

                        </div>

                    </InView>


                )
            })
            }
        </>
    )
}

