import * as React from 'react';
import style from './hotspots.module.scss';

export interface HotspotsProps {
    hotspots: Array<HotspotProps>;
    onClick: (title: string) => void;
}

export interface HotspotProps {
    position: [number, number];
    title: string;
    icon: any;
    onClick?: () => void;
}

function Hotspot({ position, title, icon, onClick }: HotspotProps) {
    return (
        <div className={style.hotspot} style={{ left: position[0] + '%', top: position[1] + '%' }} onClick={onClick}>
            <div className={style.hotspot_circle}>

                <img draggable={false} src={icon} alt="icon" />

            </div>
            <p>{title}</p>
        </div>
    )
}

function Hotspots({ hotspots, onClick }: HotspotsProps) {
    return (
        <div className={style.hotspots}>
            {hotspots.map((hotspot, index) => (
                <Hotspot
                    position={hotspot.position}
                    title={hotspot.title}
                    icon={hotspot.icon}
                    key={"key_" + hotspot.title}
                    onClick={() => onClick(hotspot.title)}
                />
            ))}
        </div>
    );
}

export default Hotspots;