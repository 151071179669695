import * as React from 'react';
import style from "./howToBG.module.scss";
import { useTransition, animated, } from 'react-spring'
import {
    bgImages,
    bgVideos,
    subImages_CH_DE,
    subImages_CH_FR,
    subImages_CH_IT,
    subImages_DE,
    subImages_EN,
    subImages_BE_FR,
    subImages_BE_NL,
} from '../../../datastructure/howToData';
import { useState, } from 'react';
import { useRef } from 'react';

export interface HowToBackgroundProps {
    activeStep: number;
    activeSubStep: number;
    setVideoIsPlaying: (active: boolean) => void;
    setVideoIsLoading: (active: boolean) => void;
    videoIsPlaying: boolean;
    scrollDirection: string | null;
    language: "DE" | "EN" | "FR" | "IT" | "CH" | "fr-BE" | "nl-BE";
}

function HowToBackground({ activeStep, activeSubStep, setVideoIsPlaying, videoIsPlaying, setVideoIsLoading, scrollDirection, language }: HowToBackgroundProps) {
    const [showContentImage, setShowContentImage] = useState<boolean>(false);
    const [cacheImages, setCacheImages] = useState<boolean>(false);
    const preloadContentImages = useRef<any[]>([[], [], [], [], []]);
    const preloadBGImages = useRef<any[]>([]);
    let video: any = document.getElementById("howToVideo");
    const subImages =
        language === "DE"
            ? subImages_DE
            : language === "EN"
                ? subImages_EN
                : language === "IT"
                    ? subImages_CH_IT
                    : language === "FR"
                        ? subImages_CH_FR
                        : language === "CH"
                            ? subImages_CH_DE
                            : language === "fr-BE"
                                ? subImages_BE_FR
                                : language === "nl-BE"
                                    ? subImages_BE_NL
                                    : subImages_DE;

    if (video && video.playbackRate !== 2) {
        video.playbackRate = 1.5;
    }

    let videoIndex: number | null = null;
    if (activeStep === 0 && activeSubStep === 1) {
        videoIndex = 0;
    } else if (activeStep === 1 && activeSubStep === 0) {
        videoIndex = 1;
    }
    else if (activeStep === 2 && activeSubStep === 0) {
        videoIndex = 2;
    }
    else if (activeStep === 3 && activeSubStep === 0) {
        videoIndex = 3;
    }
    else if (activeStep === 4 && activeSubStep === 0) {
        videoIndex = 4;
    }

    let multiplier = (activeSubStep - 2 <= 0) ? 0 : activeSubStep - 2;
    let positionY = "50% " + (10 * multiplier) + "%";

    const cacheAllImages = () => {
        subImages && subImages.forEach((section: any, sectionIndex: any) => {
            section && section.forEach((img: any, imgIndex: any) => {
                if (img) {
                    let imgEle = new Image();
                    imgEle.src = img;
                    imgEle.onload = () => {
                        preloadContentImages.current[sectionIndex][imgIndex] = imgEle ?? "";
                    }
                }
            })
        })

        bgImages && bgImages.forEach((bgImg, bgImgIndex) => {
            let imgEle = new Image();
            imgEle.src = bgImg.url;
            imgEle.onload = () => {
                preloadBGImages.current[bgImgIndex] = imgEle ?? "";
            }
        })
    }

    if (!cacheImages) {
        cacheAllImages();
        setCacheImages(true);
    }

    const bgtransitions = useTransition(bgImages[activeStep ?? 0], (image: any) => image.id, {
        from: { opacity: activeStep === 0 && activeSubStep === 0 ? 1 : 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        unique: false,
        config: { duration: 600 },
    })

    const contentImages = useTransition(subImages[activeStep][activeSubStep], (image: any) => image, {
        from: { opacity: (subImages[activeStep].length - 1 >= activeSubStep) ? 0 : 1 },
        enter: { opacity: (subImages[activeStep].length - 1 >= activeSubStep) ? 1 : 0 },
        leave: {
            opacity: (subImages[activeStep].length - 1 < activeSubStep) || (activeStep === 1 && activeSubStep === 3) ?
                0 : 0.5
        },
        unique: false,
        config: { duration: 200 },
    })

    React.useEffect(() => {
        const element = document.getElementById("howToBG");
        element?.classList.remove('background_img');
        void element?.innerHTML;
        element?.classList.add('background_img');
    }, [activeStep])

    return (
        <div className={style.HowToBackground}>
            <div className={style.fadeLeftSide} />
            <div className={style.fadeRightSide} />

            {bgImages[activeStep ?? 0].url &&
                bgtransitions.map((item: any, props: any, key: any) => {
                    return (
                        <animated.div
                            id={"howToBG"}
                            key={"bgtransitions_" + activeStep + "_" + item.item.url + "_" + item.item.key}
                            className={style.background_img}
                            style={{
                                ...item.item.props,
                                backgroundImage: `url(${item.item.url})`,
                                transform: `scale(${(activeStep === 0 &&
                                    (activeSubStep - 2 <= 0)) ? 1 : (activeSubStep) / 20 + 1
                                    })`,
                                backgroundPosition: positionY,
                            }}
                        />
                    )
                })
            }

            {
                showContentImage && contentImages.map((item: any, props: any, key: any) => {
                    if (item.item)
                        return (
                            <animated.div
                                key={"_content_" + item.item}
                                className={style.imageContainer}
                                style={{
                                    ...props,
                                }}
                            >
                                <img
                                    draggable={false}
                                    src={item.item}
                                    alt={item}
                                />
                            </animated.div>
                        )
                })
            }
            {videoIndex !== null && (scrollDirection === "down" || scrollDirection === "click") &&
                <div
                    className={style.videoContainer + ' ' + (activeStep !== 0 ? style.videoContainerScaleIn : (videoIsPlaying && style.videoContainerFadeIn))}
                >
                    <video
                        autoPlay
                        muted
                        playsInline
                        src={bgVideos[videoIndex]}
                        id="howToVideo"
                        style={{
                            objectPosition: positionY,
                            transform: `scale(${(activeStep === 0 &&
                                (activeSubStep - 2 <= 0)) ? 1 : (activeSubStep) / 20 + 1
                                })`,
                        }}
                        onEnded={() => {
                            setShowContentImage(true);
                            setVideoIsPlaying(false);
                        }}
                        onLoadStart={() => { setShowContentImage(false); setVideoIsLoading(true) }}
                        onCanPlayThrough={() => setVideoIsLoading(false)}
                        onPlay={() => {
                            setVideoIsPlaying(true);
                        }}
                    />
                </div>
            }
        </div>
    );
}

export default HowToBackground;