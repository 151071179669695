import * as React from 'react';
import { CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import style from './loader.module.scss';

export interface LoaderProps {
  open: boolean;
}

 export const useStylesAudi = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      color: '#f50537',
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
  }),
);

function Loader({ open }: LoaderProps) {
  const classes = useStylesAudi();

  return (

    <div className={style.loader}>
      <CircularProgress color="inherit" className={classes.top} />
    </div>

  );
}

export default Loader;